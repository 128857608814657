import React, { useState } from 'react';
import { Mutation, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { mutationConfigs } from 'src/Hooks';
import { persistWithLocalStorage } from './persistLocalStorage';

function setupQueryClient() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // Any cache older than 24 hours will be dismissed
        // See https://@tanstack/react-query.tanstack.com/plugins/persist-localstorage#usage to change this behavior
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      },
    },
  });

  // We need to define mutation defaults for each mutation key in the app BEFORE calling persistWithLocaleStorage
  // or @tanstack/react-query won't be able to find MutationFn of hydrated mutations
  Object.keys(mutationConfigs(queryClient)).forEach(key => {
    queryClient.setMutationDefaults(
      [key],
      mutationConfigs(queryClient)[key as keyof typeof mutationConfigs]
    );
  });

  persistWithLocalStorage(queryClient, {
    dehydrate: {
      dehydrateQueries: false,
      shouldDehydrateMutation: (mutation: Mutation) => {
        // We want to persist paused mutation (launched while offline)
        // and mutations that failed (especially those that failed when we went back online)
        return mutation.state.isPaused || mutation.state.status === 'error';
      },
    },
  });

  return queryClient;
}

export const ReactQueryProvider = ({ children }: { children: React.ReactNode }) => {
  const [queryClient] = useState(setupQueryClient());

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
