import { useMutation, useQueryClient } from '@tanstack/react-query';
import { communicationMatrixMutationsConfig } from '.';
import { usePerimeter } from '../Perimeter';
import { MutationKeys } from '..';

export const useRemoveCommunicationMatrixContact = () => {
  const { brandCode, agencyId, companyId } = usePerimeter();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    [MutationKeys.COMMUNICATION_MATRIX_REMOVE],
    communicationMatrixMutationsConfig(queryClient)[MutationKeys.COMMUNICATION_MATRIX_REMOVE]
  );
  return {
    remove: async (contactId: string) => {
      mutation.mutate({ contactId, brandCode, agencyId, companyId: companyId ?? '' });
    },
    mutation,
  };
};
