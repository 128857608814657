import React from 'react';
import { ROLE } from 'src/Redux/Types';
import ListItemActions from '../ListItemActions';
import CompanyServices from './CompanyServices.component';
import { useFetchCompanyContacts } from 'src/Hooks/Contacts';
import { useFetchCompanyCandidates } from 'src/Hooks/Candidates';
import { useSelector } from 'react-redux';
import { getCardDisplayMode } from 'src/Redux/UserPreferences/Selectors';

const MemoCompanyServices = React.memo(CompanyServices);

const EnhancedCompanyServices = () => {
  const rawCandidates = useFetchCompanyCandidates();
  const cardDisplayMode = useSelector(getCardDisplayMode);
  const candidates = rawCandidates
    .filter(candidate => candidate.service !== undefined)
    .map(candidate => ({
      contactDetail: {
        title: `${candidate.name} ${candidate.firstName}` ?? '',
        subtitle: candidate.service?.name ?? '',
        description: candidate.qualification?.label ?? '',
        section: candidate.service?.name,
        headerRight: (
          <ListItemActions
            target={{
              role: ROLE.CANDIDATE,
              id: candidate.candidateId ?? '',
              name: `${candidate.name} ${candidate.firstName}`,
              qualification: {
                name: candidate.qualification?.label,
                id: candidate.qualification?.id,
              },
              service: { name: candidate.service?.name, id: candidate.service?.serviceId },
            }}
          />
        ),
      },
      contactRole: ROLE.CANDIDATE,
    }));
  const rawContacts = useFetchCompanyContacts();
  const contacts = rawContacts
    .map(contact =>
      (contact.services ?? []).map(service => ({
        contactDetail: {
          title: `${contact.name} ${contact.firstName}` ?? '',
          subtitle: service.name ?? '',
          description: contact.position ?? '',
          section: service?.name,
          headerRight: (
            <ListItemActions
              target={{
                role: ROLE.CONTACT,
                id: contact.contactId ?? '',
                name: `${contact.name} ${contact.firstName}`,
                position: contact.position,
              }}
            />
          ),
        },
        contactRole: ROLE.CONTACT,
      }))
    )
    .flat();

  return (
    <MemoCompanyServices
      contacts={[...candidates, ...contacts]}
      cardDisplayMode={cardDisplayMode}
    />
  );
};

export default EnhancedCompanyServices;
