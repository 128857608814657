import { Props } from './ExchangeCreationModal.types';

import ExchangeFormModal from 'src/Components/ExchangeFormModal';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROLE } from 'src/Redux/Types';

const ExchangeCreationModal = ({ modalParameters, fetchStatus, onValidate }: Props) => {
  const history = useHistory();

  const target = modalParameters?.target;
  // Redirect user to app root if he navigates directly to modal url on accident
  useEffect(() => {
    if (target === undefined) {
      history?.push('/');
    }
  }, [target, history]);

  const roleLabel = target?.role === ROLE.CANDIDATE ? `l'intérimaire` : `l'interlocuteur client`;

  return (
    <ExchangeFormModal
      title={`saisie d'un échange pour ${roleLabel}`}
      fetchStatus={fetchStatus}
      onValidate={onValidate}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      target={target!}
      placeholder="décrivez le thème, les éléments clés et les actions qui découlent de votre échange."
    />
  );
};

export default ExchangeCreationModal;
