export enum DISPLAYED_SECTION {
  INTERIM,
  CLIENT,
  SERVICE,
  AGENDA,
  DASHBOARD,
  PARAMETERS,
  APPLICATIONS,
  LISTS,
  TASKS,
}

export enum DISPLAY_MODE {
  LIST,
  GRID,
}

export enum SORT_BY {
  QUALIFICATION,
  ALPHABETICAL,
}

interface UserPreferencesState {
  displaySection: DISPLAYED_SECTION;
  cardDisplayMode: DISPLAY_MODE;
  sortBy: SORT_BY;
  onMissionOnlyFilter: boolean;
}

export default UserPreferencesState;
