import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplaySection } from 'src/Redux/UserPreferences/Selectors';
import { userPreferencesActions } from 'src/Redux/UserPreferences/Slice';
import { DISPLAYED_SECTION } from 'src/Redux/UserPreferences/Types';
import Menu from './Menu.component';
import { Props } from './Menu.types';

const MemoMenu = React.memo(Menu);

const EnhancedMenu = (props: Props) => {
  const displayedSection = useSelector(getDisplaySection);
  const dispatch = useDispatch();
  const setDisplayedSection = useCallback(
    (displayedSection: DISPLAYED_SECTION) => {
      dispatch(userPreferencesActions.setDisplaySection(displayedSection));
    },
    [dispatch]
  );
  return (
    <MemoMenu
      title={props.title}
      menu={props.menu}
      logo={props.logo}
      opened={props.opened}
      fade={props.fade}
      expand={props.expand}
      setDisplayedSection={setDisplayedSection}
      displayedSection={displayedSection}
    />
  );
};

export default EnhancedMenu;
