import { useLiveQuery } from 'dexie-react-hooks';
import { db } from 'src/Services/DB';

export const useFetchLastExchange = (contactId: string) => {
  return useLiveQuery(
    async () =>
      (await db.actions.where('targetId').equals(contactId).sortBy('startDate')).reverse()[0],
    [contactId]
  );
};
