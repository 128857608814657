import { PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Eye,
  Pencil,
  Check,
  ListCheck,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useUpdateAction } from 'src/Hooks/Actions';
import { EnumEntityActionType, EnumEntityActionStatus } from 'src/Services/API';
import { ROUTES } from 'src/Services/Routing';
import { Props } from './ActionMenuContent.types';

const ActionMenuContent = ({ action, target, onItemClick }: Props) => {
  const actionUpdater = useUpdateAction();
  const history = useHistory();
  const location = useLocation();
  const now = new Date();

  const actionEditDependingOnType = useCallback((): string => {
    if (
      action?.type === EnumEntityActionType.ACTION_CANDIDATE_EXCHANGE ||
      action?.type === EnumEntityActionType.ACTION_CONTACT_EXCHANGE
    )
      return ROUTES.EXCHANGE_EDIT;
    else if (action?.type === EnumEntityActionType.ACTION_SECURITY_EVENT)
      return ROUTES.SECURITY_EVENT_VIEW(action.actionId ?? '');
    else if (action?.type === EnumEntityActionType.ACTION_PRODUCTION_IMPACT)
      return ROUTES.PRODUCTION_IMPACT_EDIT;
    else return '/';
  }, [action?.actionId, action?.type]);
  return (
    <>
      <PopupMenu.Item
        icon={action?.type === EnumEntityActionType.ACTION_SECURITY_EVENT ? <Eye /> : <Pencil />}
        text={
          action?.type === EnumEntityActionType.ACTION_SECURITY_EVENT
            ? "consulter l'action"
            : "modifier l'action"
        }
        onClick={() => {
          history.push({
            pathname: actionEditDependingOnType(),
            state: {
              background: location,
              modalParameters: { target, contentId: action?.localId },
            },
          });
          onItemClick?.();
        }}
      />
      <PopupMenu.Item
        icon={<Check />}
        text="fermer l'action"
        onClick={() => {
          action &&
            actionUpdater.update({
              ...action,
              startDate: action.startDate <= now ? action.startDate : now,
              endDate: now,
              status: EnumEntityActionStatus.FINISHED,
            });
          onItemClick?.();
        }}
      />
      <PopupMenu.Item
        icon={<ListCheck />}
        text="historique des actions"
        onClick={() => {
          history.push({
            pathname: '/history/exchanges',
            state: { modalParameters: { target }, background: location },
          });
          onItemClick?.();
        }}
      />
    </>
  );
};

export default ActionMenuContent;
