import React from 'react';

import { Props } from './SelectContact.types';
import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';

const SelectContact = ({ contacts, selectedContact, onContactSelect, disabled }: Props) => (
  <ComboBox
    id="selectContact"
    placeholder="sélectionnez"
    useNativeOptionFiltering
    value={selectedContact}
    searchResults={contacts}
    keyValueExtractor={({ contactId, name, firstName }) => ({
      key: contactId ?? 'unknown',
      value: [firstName, name].filter(x => x).join(' '),
    })}
    onChange={onContactSelect}
    minLengthToSearch={0}
    disabled={disabled}
  />
);

export default SelectContact;
