import React, { useEffect, useState } from 'react';

import { Props } from './CommunicationMatrixEditionModal.types';
import { useUpdateContactInCommunicationMatrix } from 'src/Hooks/CommunicationMatrix';
import { queryStatus, QueryStatus, toFetchStatus } from 'src/Services/ReactQuery';
import CommunicationMatrixFormModal from 'src/Components/CommunicationMatrixFormModal';
import { useFetchCommunicationMatrixContact } from 'src/Hooks/CommunicationMatrix/useFetchCommunicationMatrixContact';
import { useHistory } from 'react-router-dom';
import { useFetchCompanyContact } from 'src/Hooks/Contacts';

const CommunicationMatrixEditionModal = ({ modalParameters, open }: Props) => {
  const history = useHistory();
  const target = modalParameters?.target;
  const [preFetchStatus, setPreFetchStatus] = useState<QueryStatus | undefined>();

  // Redirect user to app root if he navigates directly to modal url on accident
  useEffect(() => {
    if (target === undefined) {
      history?.push('/');
    }
  }, [target, history]);

  const entry = useFetchCommunicationMatrixContact(target?.entryLocalId ?? 0);
  const contact = useFetchCompanyContact(entry?.contactId ?? '');

  const { update, mutation } = useUpdateContactInCommunicationMatrix();

  return (
    <CommunicationMatrixFormModal
      title="modifier un interlocuteur dans la matrice de communication"
      open={open ?? true}
      fetchStatus={toFetchStatus(preFetchStatus ?? mutation)}
      entry={{ contactName: contact?.name, contactFirstName: contact?.firstName, ...entry }}
      onValidate={async entry => {
        setPreFetchStatus(queryStatus.pending);
        setPreFetchStatus(await update(entry, target?.entryLocalId ?? 0));
      }}
      contactSelectDisabled={true}
    />
  );
};

export default CommunicationMatrixEditionModal;
