import React from 'react';

import { Props } from './Loader.types';
import styles from './Loader.module.scss';

function getDynamicStyle({ heightInRem }: Props) {
  return {
    containerStyle: {
      width: `${2 * heightInRem}rem`,
      height: `${heightInRem}rem`,
      padding: `${0.33 * heightInRem}rem`,
      borderRadius: `${heightInRem}rem`,
    },
    bounceStyle: {
      width: `${0.42 * heightInRem}rem`,
      height: `${0.42 * heightInRem}rem`,
    },
  };
}

const Loader = (props: Props) => {
  const { containerStyle, bounceStyle } = getDynamicStyle(props);
  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.bounce1} style={bounceStyle} />
      <div className={styles.bounce2} style={bounceStyle} />
      <div className={styles.bounce3} style={bounceStyle} />
    </div>
  );
};

export default Loader;
