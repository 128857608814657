import styles from './AgendaSection.module.scss';
import WorkplaceHeader from 'src/Components/WorkplaceHeader';
import AgendaWeekSection from './AgendaWeekSection';
import moment from 'moment';

const AgendaSection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <WorkplaceHeader
          title="tâches en attente"
          comment="Organisez vos tâches hebdomadaires prévues suivant les interlocuteurs du compte"
        />
      </div>

      <div className={styles.weekSections}>
        <AgendaWeekSection week={moment()} title="cette semaine" defaultOpen />
        <AgendaWeekSection week={moment().add(7, 'days')} title="semaine prochaine" />
      </div>
    </div>
  );
};

export default AgendaSection;
