import { PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Flux,
  Pencil,
  ListCheck,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';

import { useHistory, useLocation } from 'react-router-dom';
import { useRemoveCommunicationMatrixContact } from 'src/Hooks/CommunicationMatrix';
import { ROUTES } from 'src/Services/Routing';
import { Props } from './CommunicationMatrixMenuContent.types';

const CommunicationMatrixMenuContent = ({ target, onItemClick }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { remove } = useRemoveCommunicationMatrixContact();
  return (
    <>
      <PopupMenu.Item
        icon={<Flux />}
        text="nouvel échange"
        onClick={() => {
          history.push({
            pathname: ROUTES.EXCHANGE_NEW,
            state: {
              modalParameters: {
                target,
              },
              background: location,
            },
          });
          onItemClick?.();
        }}
      />
      <PopupMenu.Item
        icon={<Pencil />}
        text="modifier l'interlocuteur"
        onClick={() => {
          history.push({
            pathname: ROUTES.COMMUNICATION_MATRIX_EDIT,
            state: { background: location, modalParameters: { target } },
          });
          onItemClick?.();
        }}
      />
      <PopupMenu.Item
        icon={<ListCheck />}
        text="historique des actions"
        onClick={() => {
          history.push({
            pathname: '/history/exchanges',
            state: { modalParameters: { target }, background: location },
          });
          onItemClick?.();
        }}
      />
      <PopupMenu.Item
        icon={<Trashcan />}
        text="supprimer l'interlocuteur"
        onClick={() => {
          remove(target.id);
          onItemClick?.();
        }}
      />
    </>
  );
};

export default CommunicationMatrixMenuContent;
