import React from 'react';

import { Props } from './SelectClaimCategory.types';
import { DropDown } from '@randstad-lean-mobile-factory/react-components-core';
import { ClaimCategory } from 'src/Services/API';

const SelectClaimCategory = ({
  claimCategories,
  selectedClaimCategory,
  onClaimCategorySelect,
}: Props) => (
  <DropDown
    canBeReset={false}
    placeholder="sélectionnez"
    items={claimCategories}
    selectedItem={selectedClaimCategory}
    onSelectItem={onClaimCategorySelect}
    keyValueExtractor={(searchResult: ClaimCategory) => ({
      key: searchResult.id ?? '',
      value: searchResult.label ?? '',
    })}
  />
);

export default SelectClaimCategory;
