import React from 'react';

import { Props } from './SelectCandidate.types';
import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';

const SelectCandidate = ({
  candidates,
  selectedCandidate,
  onCandidateSelect,
  blackListCandidateIds,
}: Props) => {
  return (
    <ComboBox
      id="selectCandidate"
      placeholder="aucun candidat sélectionné"
      value={selectedCandidate}
      searchResults={
        blackListCandidateIds === undefined
          ? candidates
          : candidates.filter(
              candidate => !blackListCandidateIds?.includes(candidate.candidateId ?? '')
            )
      }
      useNativeOptionFiltering
      keyValueExtractor={({ candidateId, name, firstName }) => ({
        key: candidateId ?? 'unknown',
        value: [firstName, name].filter(x => x).join(' '),
      })}
      onChange={onCandidateSelect}
      minLengthToSearch={0}
    />
  );
};

export default SelectCandidate;
