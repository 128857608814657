import React, { useRef, useState } from 'react';

import { Props } from './ParametersPopup.types';
import styles from './ParametersPopup.module.scss';
import { Button, ParametersPopup as PP } from '@randstad-lean-mobile-factory/react-components-core';
import { CircleRandstad, Gear } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';
import { PopupActions } from 'reactjs-popup/dist/types';
import classnames from 'classnames';
import { ROUTES } from 'src/Services/Routing';

const ParametersPopup = (props: Props) => {
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);
  const popup = useRef<PopupActions>(null);
  const location = useLocation();

  return (
    <PP
      trigger={
        <div>
          <Button.Tertiary
            className={classnames(styles.gearButton, { [styles.openGearButton]: isOpened })}
          >
            <Gear className={isOpened ? styles.gearIcon : undefined} />
          </Button.Tertiary>
        </div>
      }
      position="right bottom"
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      ref={popup}
      {...props}
    >
      <div
        className={styles.menuItem}
        onClick={() => {
          popup?.current?.close();
          history.push({ pathname: ROUTES.PERIMETER_SELECT, state: { background: location } });
        }}
      >
        <CircleRandstad />
        <div className={styles.spacer} />
        choix de vos unités
      </div>
      <div className={styles.version}>{`version :  ${process.env.REACT_APP_VERSION}`}</div>
    </PP>
  );
};

export default ParametersPopup;
