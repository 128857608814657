import React, { useState } from 'react';

import styles from './FiltersMenu.module.scss';
import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { Filters, Grid, List } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { getCardDisplayMode, getSortBy } from 'src/Redux/UserPreferences/Selectors';
import { DISPLAY_MODE, SORT_BY } from 'src/Redux/UserPreferences/Types';
import FiltersMenuContent from './FiltersMenuContent/FiltersMenuContent.component';

export const displayModes = [
  {
    mode: DISPLAY_MODE.GRID,
    name: 'cartes',
    icon: <Grid className={styles.menuIcon} />,
  },
  {
    mode: DISPLAY_MODE.LIST,
    name: 'liste',
    icon: <List className={styles.menuIcon} />,
  },
];

export const sortingChoices = [
  {
    mode: SORT_BY.ALPHABETICAL,
    name: 'ordre alphabétique',
  },
  {
    mode: SORT_BY.QUALIFICATION,
    name: 'qualification',
  },
];

const FiltersMenu = () => {
  const displayMode = useSelector(getCardDisplayMode);
  const sortBy = useSelector(getSortBy);

  const [localSortBy, setLocalSortBy] = useState(sortBy);
  const [localDisplayMode, setLocalDisplayMode] = useState(displayMode);

  const [isOpened, setIsOpened] = useState(false);
  return (
    <PopupMenu
      position="left top"
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      contentStyle={{ width: '15.25rem' }}
      trigger={
        <div>
          <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpened })}>
            <Filters />
          </Button.Tertiary>
        </div>
      }
      selectedIds={[
        sortingChoices.find(choice => choice.mode === localSortBy)?.name ?? '',
        displayModes.find(dpm => dpm.mode === localDisplayMode)?.name ?? '',
      ]}
    >
      <FiltersMenuContent
        localDisplayMode={localDisplayMode}
        localSortBy={localSortBy}
        setLocalDisplayMode={setLocalDisplayMode}
        setLocalSortBy={setLocalSortBy}
      />
    </PopupMenu>
  );
};

export default FiltersMenu;
