import { useFetchCommunicationMatrixWithProgression } from 'src/Hooks/CommunicationMatrix';
import { Props } from './MobileCommunicationMatrix.types';
import styles from './MobileCommunicationMatrix.module.scss';
import MobileCommunicationCard from './MobileCommunicationCard/MobileCommunicationCard.component';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { PlusCircle } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'src/Services/Routing';
import { useFetchWeekActions } from 'src/Hooks/Actions';
import { MOBILE_TASK_DISPLAYED_SECTION } from '../MainPage/MobileTaskSection/MobileTaskSection.types';
import MobileWeeklyTaskCard from './MobileWeeklyTaskCard/MobileWeeklyTaskCard.component';
import { useSection } from 'src/Hooks/Section/useSection';

const MobileCommunicationMatrix = ({ week, toDoDisplayedSection }: Props) => {
  const communicationMatrix = useFetchCommunicationMatrixWithProgression(week);
  const actions = useFetchWeekActions(week);
  const history = useHistory();
  const location = useLocation();
  const section = useSection();

  return (
    <div
      id="mobileCommunicationMatrixContainer"
      className={styles.container}
      onScroll={() => {
        section?.setScrollTop(
          document.getElementById('mobileCommunicationMatrixContainer')?.scrollTop ?? 0
        );
      }}
    >
      {toDoDisplayedSection === MOBILE_TASK_DISPLAYED_SECTION.COMMUNICATION ? (
        <>
          {communicationMatrix.map(entry => (
            <MobileCommunicationCard contact={entry} />
          ))}
        </>
      ) : (
        <>
          {actions.map(entry => (
            <MobileWeeklyTaskCard action={entry} />
          ))}
        </>
      )}

      {toDoDisplayedSection === MOBILE_TASK_DISPLAYED_SECTION.COMMUNICATION && (
        <Button.Secondary
          leftIcon={<PlusCircle />}
          text="ajouter un interlocuteur"
          className={styles.button}
          onClick={() =>
            history.push({
              pathname: ROUTES.COMMUNICATION_MATRIX_INSERT,
              state: { background: location },
            })
          }
        />
      )}
    </div>
  );
};

export default MobileCommunicationMatrix;
