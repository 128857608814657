import React, { useEffect } from 'react';

import { Props } from './AbsenceCreationModal.types';
import { useHistory } from 'react-router-dom';
import AbsenceFormModal from 'src/Components/AbsenceFormModal';

const AbsenceCreationModal = ({ modalParameters, onValidate, fetchStatus, open }: Props) => {
  const history = useHistory();

  const target = modalParameters?.target;

  // Redirect user to app root if he navigates directly to modal url on accident
  useEffect(() => {
    if (target === undefined) {
      history?.push('/');
    }
  }, [target, history]);

  return (
    <AbsenceFormModal
      title="saisie d'une absence pour"
      open={open ?? true}
      fetchStatus={fetchStatus}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      target={target!}
      onValidate={onValidate}
    />
  );
};

export default AbsenceCreationModal;
