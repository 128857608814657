import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { CandidatesService, UpdateSubscriptionEnum } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '..';
import { usePerimeter } from '../Perimeter';
import ReactGA from 'react-ga4';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { useBackgroundFetchAllCandidates } from '.';
import { db } from 'src/Services/DB';
import { useFetchCompanyServices } from '../Companies';
import moment from 'moment';

interface UpdateSubscriptionParams {
  actionType: UpdateSubscriptionEnum;
  motivationId: string;
  senderId: string;
  isRepositionable: boolean;
  isScheduledExit: boolean;
  motivationComment?: string;
  cgcId?: string;
  comment?: string;
  service?: string;
  firstContractDate?: string;
  targetGroup?: string;
  poolExitDate?: Date;
}

const useUpdateCandidateSubscription = (
  candidateId: string,
  poolExitDate: Date,
  extraOptions?: UseMutationOptions<unknown, AxiosError, UpdateSubscriptionParams, unknown>
) => {
  const queryClient = useQueryClient();
  const { brandCode, agencyId, companyId } = usePerimeter();
  const servicesQuery = useFetchCompanyServices();
  return useMutation(
    [MutationKeys.UPDATE_CANDIDATE_SUBSCRIPTION, candidateId, agencyId, brandCode],
    async (params: Omit<UpdateSubscriptionParams, 'service'> & { serviceId: string }) => {
      const allServices = servicesQuery.data;
      return CandidatesService.candidatesControllerUpdateCandidateSubscription({
        id: candidateId,
        body: {
          agencyId,
          brandCode,
          companyId: companyId ?? '',
          poolExitDate,
          service:
            allServices?.find(ser => ser.serviceId === params.serviceId)?.name ?? params.serviceId,
          ...params,
        },
      });
    },
    {
      onSuccess: async () => {
        if (moment(poolExitDate).format('L') === moment().format('L')) {
          db.candidates.where('candidateId').equals(candidateId).delete();
          queryClient.setQueryData(
            [QueryKeys.FETCH_ALL_CANDIDATES],
            (oldData: ReturnType<typeof useBackgroundFetchAllCandidates>['data']) => {
              return {
                count: (oldData?.count ?? 0) - 1,
                candidates:
                  oldData?.candidates.filter(candidate => candidate.candidateId !== candidateId) ??
                  [],
              };
            }
          );
        } else {
          await queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_ALL_CANDIDATES] });
        }

        ReactGA.event('Sortie de pool', {
          brandCode,
          agency: agencyId,
        });
      },
      onError: error => {
        if (error?.response?.status === 400) {
          toast.error('Le candidat est déjà sorti du pool');
        } else if (error?.response?.status === 513) {
          toast.error(
            "Le candidat ne peut pas être sorti de pool car il n'appartient pas à cette agence"
          );
        } else if (error?.response?.status === 512) {
          toast.error('Le candidat ne peut pas être sorti de pool car il est en contrat');
        } else if (error?.response?.status === 514) {
          toast.error('Une erreur est survenue lors de la suppression des pré-affectations');
        } else {
          toast.error('Une erreur a empéché la sortie de pool');
        }
      },
      ...extraOptions,
    }
  );
};

export default useUpdateCandidateSubscription;
