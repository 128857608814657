import React, { useEffect, useState } from 'react';
import { useFetchCompanyServices } from 'src/Hooks/Companies';
import { toFetchStatus } from 'src/Services/ReactQuery';
import SelectService from './SelectService.component';
import { Props } from './SelectService.types';

const MemoSelectService = React.memo(SelectService);

const EnhancedSelectService = (props: Props) => {
  const servicesQuery = useFetchCompanyServices();
  const fetchStatus = toFetchStatus(servicesQuery);
  const [services, setServices] = useState(servicesQuery.data ?? []);
  useEffect(() => setServices(servicesQuery.data ?? []), [servicesQuery.data]);

  return <MemoSelectService services={services} fetchStatus={fetchStatus} {...props} />;
};

export default EnhancedSelectService;
