import React, { useEffect, useState } from 'react';
import Callback from './Callback.component';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCompanies,
  getCurrentAgency,
  getCurrentBrand,
  getFetchStatus,
} from 'src/Redux/Perimeter/Selectors';
import { useAuth0 } from 'src/Providers/Auth0';
import { fetchPerimeter } from 'src/Redux/Perimeter/Thunks';
import history from 'src/Utils/history';
import { FETCH_STATUS } from 'src/Redux/Types';
import { isValidActivePerimeter } from 'src/Services/Perimeter';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'src/Services/Routing';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/RootReducer';

const EnhancedCallback = () => {
  const auth = useAuth0();
  const fetchStatus = useSelector(getFetchStatus);
  const dispatch = useDispatch<ThunkDispatch<RootState, void, AnyAction>>();
  // we need this inner state because fetchStatus is fulfilled when the component mounts
  const [isFetchingPerimeter, setIsFetchingPerimeter] = useState(false);

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);
  const companies = useSelector(getCompanies);

  const location = useLocation();

  useEffect(() => {
    const isAuthenticated = auth?.isAuthenticated ?? false;
    if (!isAuthenticated) {
      return;
    } else if (isAuthenticated && !isFetchingPerimeter) {
      dispatch(fetchPerimeter());
      setIsFetchingPerimeter(true);
    } else if (
      isAuthenticated &&
      fetchStatus === FETCH_STATUS.FULFILLED &&
      !isValidActivePerimeter({ currentBrand, currentAgency, companies })
    ) {
      history.push({ pathname: ROUTES.PERIMETER_SELECT, state: { background: location } });
    } else if (isAuthenticated && fetchStatus === FETCH_STATUS.FULFILLED) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus, fetchPerimeter, auth, currentBrand, currentAgency, companies, location]);

  return <Callback fetchStatus={fetchStatus} />;
};

export default EnhancedCallback;
