import React from 'react';
import { useFetchAbsenceReasons } from 'src/Hooks/Absence';
import SelectAbsenceReason from './SelectAbsenceReason.component';
import { Props } from './SelectAbsenceReason.types';

const MemoSelectAbsenceReason = React.memo(SelectAbsenceReason);

const EnhancedSelectAbsenceReason = (props: Props) => {
  const absenceReasons = useFetchAbsenceReasons().map(x => ({
    id: x.id ?? '',
    label: x.label ?? '',
  }));

  return <MemoSelectAbsenceReason reasons={absenceReasons} {...props} />;
};

export default EnhancedSelectAbsenceReason;
