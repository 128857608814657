import React from 'react';
import classnames from 'classnames';

import { Props } from './SmallLink.types';
import styles from './SmallLink.module.scss';

const SmallLink = ({ leftIcon, title, onClick, className }: Props) => {
  return (
    <div className={classnames(styles.container, className)} onClick={onClick}>
      {leftIcon && <div className={styles.icon}>{leftIcon}</div>}
      {title}
    </div>
  );
};

export default SmallLink;
