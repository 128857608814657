import React from 'react';
import SideBar from './SideBar.component';

const MemoSideBar = React.memo(SideBar);

const EnhancedSideBar = () => {
  return <MemoSideBar />;
};

export default EnhancedSideBar;
