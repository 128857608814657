import React from 'react';

import styles from './HistoryCardLoader.module.scss';
import ContentLoader from 'react-content-loader';

const HistoryCardLoader = () => (
  <div className={styles.container}>
    <ContentLoader height="5.75rem" width="100%" uniqueKey="criteriaButton">
      <rect x="2%" y="10" rx="4" ry="4" width="50%" height="20" />
      <rect x="2%" y="30" rx="4" ry="4" width="30%" height="10" />
      <rect x="2%" y="50" rx="4" ry="4" width="30%" height="10" />
    </ContentLoader>
  </div>
);

export default HistoryCardLoader;
