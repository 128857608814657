import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDisplaySection } from 'src/Redux/UserPreferences/Selectors';

const SectionContext = createContext<{
  scrollTop: number;
  setScrollTop: (arg0: number) => void;
  titleKeyframe: number;
} | null>(null);

const MAX_ANIMATION_SCROLL = 50;

export const SectionProvider = ({ children }: { children: ReactNode }) => {
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [titleKeyframe, setTitleKeyframe] = useState(0);
  const displayedSection = useSelector(getDisplaySection);
  useEffect(() => {
    setScrollTop(0);
  }, [displayedSection]);
  useEffect(() => {
    setTitleKeyframe(Math.min(scrollTop ?? 0, MAX_ANIMATION_SCROLL) / MAX_ANIMATION_SCROLL);
  }, [scrollTop]);
  return (
    <SectionContext.Provider
      value={{
        scrollTop: scrollTop,
        setScrollTop: value => setScrollTop(value),
        titleKeyframe: titleKeyframe,
      }}
    >
      {children}
    </SectionContext.Provider>
  );
};

export const useSection = () => {
  return useContext(SectionContext);
};
