import React from 'react';
import { logo } from 'src/Assets';
import Login from 'src/Components/Login';
import { useAuth0 } from 'src/Providers/Auth0';

const MemoLogin = React.memo(Login);

const EnhancedLogin = () => {
  const auth = useAuth0();

  return (
    <MemoLogin
      disabled={auth?.loading ?? true}
      onLogin={() => auth?.loginWithRedirect({})}
      icon={logo}
      appName="tour de production"
      description="votre outil au cœur de l'action"
      titleStyle={{ color: '#ffffff' }}
      style={{ backgroundColor: '#ffb511', color: '#0f1941' }}
    />
  );
};

export default EnhancedLogin;
