import React from 'react';

import { Props } from './MenuItem.types';
import styles from './MenuItem.module.scss';
import classnames from 'classnames';
import { animated, useSpring } from 'react-spring';

const MenuItem = (props: Props) => {
  const fade = useSpring({
    opacity: props.minified ? 0 : 1,
  });

  return (
    <div className={styles.container}>
      <div className={props.selected ? styles.whiteBorder : styles.transparentBorder} />
      <div
        className={classnames(styles.item, {
          [styles.selected]: props.selected,
          [styles.childrenSelected]: props.childrenSelected,
          [styles.hasNoChildren]: !props.hasChildren,
        })}
      >
        <animated.div
          className={styles.mainContainer}
          onClick={() => {
            props.onClick?.();
          }}
        >
          {props.hasChildren ? (
            <animated.div
              className={classnames(styles.text, {
                [styles.textWithChildren]: props.hasChildren,
              })}
              style={fade}
            >
              {props.label}
              <div className={styles.rightIcon}>{props.rightIcon}</div>
            </animated.div>
          ) : (
            <>
              {props.leftIcon}
              <animated.div
                className={classnames(styles.text, {
                  [styles.textWithChildren]: props.hasChildren,
                })}
                style={fade}
              >
                {props.label}
                <div className={styles.rightIcon}>{props.rightIcon}</div>
              </animated.div>
            </>
          )}
        </animated.div>
      </div>
      {props.selected && <div className={styles.triangle}></div>}
    </div>
  );
};
export default MenuItem;
