import React, { useCallback } from 'react';
import { useUpdateAction } from 'src/Hooks/Actions';
import { EntityAction } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';
import ProductionImpactEditionModal from './ProductionImpactEditionModal.component';
import { OwnProps } from './ProductionImpactEditionModal.types';
import { Local } from '../../Services/DB';

const MemoProductionImpactEditionModal = React.memo(ProductionImpactEditionModal);

const EnhancedProductionImpactEditionModal = (props: OwnProps) => {
  const updater = useUpdateAction();
  const fetchStatus = toFetchStatus(updater.mutation);
  const onValidate = useCallback(
    (productionImpact: Local<EntityAction>) => {
      updater.update(productionImpact);
    },
    [updater]
  );

  return (
    <MemoProductionImpactEditionModal
      fetchStatus={fetchStatus}
      onValidate={onValidate}
      {...props}
    />
  );
};

export default EnhancedProductionImpactEditionModal;
