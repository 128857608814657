import React from 'react';

import { OwnProps } from './Menu.types';
import styles from './Menu.module.scss';
import MenuItem from '../../../Components/MenuItem';
import { animated, useTransition } from 'react-spring';

const SubMenuIcon = ({ icon, minified }: { icon: JSX.Element; minified: boolean }) => {
  const transitions = useTransition(minified, null, {
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  return (
    <div>
      {transitions.map(({ item, key, props }) =>
        item ? (
          <animated.div key={key} style={props}>
            <div className={styles.whiteDash} />
          </animated.div>
        ) : (
          <animated.div key={key} style={props}>
            {icon}
          </animated.div>
        )
      )}
    </div>
  );
};

const Menu = (props: OwnProps) => {
  return (
    <animated.div style={props.expand}>
      <div className={styles.sideBar}>
        <div className={styles.titleWithLogo}>
          {props.logo && (
            <div className={styles.logo}>
              <img src={props.logo} alt="logo" />
            </div>
          )}
          <animated.div className={styles.verticalSeparator} style={props.fade} />
          <animated.div className={styles.title} style={props.fade}>
            {props.title}
          </animated.div>
        </div>

        <div className={styles.menu}>
          {props.menu.map(menuItem => (
            <div key={menuItem.title} className={styles.menuContent}>
              <MenuItem
                leftIcon={
                  menuItem.subMenu ? (
                    <SubMenuIcon icon={menuItem.icon} minified={!props.opened} />
                  ) : (
                    <>{menuItem.icon}</>
                  )
                }
                label={menuItem.title}
                onClick={() => {
                  if (menuItem.displayedSection) {
                    props.setDisplayedSection(menuItem.displayedSection);
                  }
                }}
                hasChildren={menuItem.subMenu !== undefined}
                childrenSelected={menuItem.subMenu
                  ?.map(menuItem => menuItem.displayedSection === props.displayedSection)
                  .includes(true)}
                selected={
                  menuItem.subMenu === undefined &&
                  menuItem.displayedSection === props.displayedSection
                }
                minified={!props.opened}
              />
              {menuItem.subMenu && (
                <>
                  <div className={styles.beforeSubMenuSeparator}>
                    <SubMenuIcon icon={<></>} minified={!props.opened} />
                  </div>
                  {menuItem.subMenu.map(menuItem => (
                    <div key={menuItem.title}>
                      <MenuItem
                        leftIcon={menuItem.icon}
                        label={menuItem.title}
                        isSubMenu={true}
                        onClick={() => {
                          if (menuItem.displayedSection !== undefined) {
                            props.setDisplayedSection(menuItem.displayedSection);
                          }
                        }}
                        selected={menuItem.displayedSection === props.displayedSection}
                        minified={!props.opened}
                      />
                    </div>
                  ))}
                  <div className={styles.afterSubMenuSeparator}>
                    <SubMenuIcon icon={<></>} minified={!props.opened} />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </animated.div>
  );
};

export default Menu;
