import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import {
  Animation,
  Button,
  DatePickerInput,
  FetchButton,
  Modal,
  TextArea,
  TimePicker,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { HeaderFinAction } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory } from 'react-router-dom';
import PlanificationVisibilityToggle from 'src/Components/PlanificationVisibilityToggle';
import { EnumEntityActionStatus, EnumEntityActionType } from 'src/Services/API';
import { ROLE } from 'src/Redux/Types';

import { Props } from './ExchangeFormModal.types';
import styles from './ExchangeFormModal.module.scss';
import { toTimeInput } from 'src/Utils/date';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';

const ExchangeFormModal = ({
  target,
  fetchStatus,
  onValidate,
  title,
  exchange,
  placeholder,
}: Props) => {
  const [report, setReport] = useState(exchange?.report ?? '');
  const [showPlanification, setPlanificationVisibility] = useState(false);
  const [plannedDate, setPlannedDate] = useState<Date | undefined>(undefined);
  const [plannedTime, setPlannedTime] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });

  const reset = useCallback(() => {
    setReport(exchange?.report ?? '');
    setStartDate(exchange?.startDate ?? new Date());
    setPlanificationVisibility(
      exchange?.status === EnumEntityActionStatus.IN_PROGRESS && exchange?.endDate !== undefined
    );
    setPlannedDate(exchange?.endDate);
    if (exchange?.endDate) {
      setPlannedTime(toTimeInput(exchange?.endDate));
    }
  }, [exchange]);

  useEffect(reset, [reset, exchange?.report, exchange?.endDate?.toISOString()]);

  const history = useHistory();
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);

  const [hours, minutes] = plannedTime.split(':').map(value => parseInt(value, 10));

  return (
    <Modal
      title={title}
      subtitle={target?.name}
      onClose={() => {
        reset();
        onClose();
      }}
      icon={
        <div className={styles.modalIcon}>
          <HeaderFinAction />
        </div>
      }
      open
      footerActionsLeft={[
        <Button.Tertiary onClick={reset}>
          {isMobile ? 'réinitialiser' : 'tout réinitialiser'}
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        isMobile ? (
          <Button.Tertiary onClick={onClose}>annuler</Button.Tertiary>
        ) : (
          <Button.Secondary onClick={onClose}>annuler</Button.Secondary>
        ),
        <FetchButton
          title="valider"
          disabled={
            !report ||
            (showPlanification &&
              (!plannedDate ||
                !plannedTime ||
                moment(plannedDate).hours(hours).minutes(minutes).isBefore(moment())))
          }
          errorTitle="réessayer"
          fetchStatus={fetchStatus}
          onClick={() =>
            onValidate({
              report,
              startDate,
              endDate:
                plannedDate && showPlanification
                  ? moment(plannedDate).hours(hours).minutes(minutes).toDate()
                  : undefined,
              // target is defined otherwise we would have redirected to "/"
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              targetId: target!.id,
              type:
                target?.role === ROLE.CANDIDATE
                  ? EnumEntityActionType.ACTION_CANDIDATE_EXCHANGE
                  : EnumEntityActionType.ACTION_CONTACT_EXCHANGE,
              localId: exchange?.localId,
            })
          }
          onSuccess={onClose}
        />,
      ]}
    >
      <div className={styles.reportHeader}>
        CR de votre échange
        <div className={styles.reportLength}>{report.length}/950 caractères</div>
      </div>
      <TextArea
        className={styles.textArea}
        placeholder={placeholder}
        value={report}
        maxLength={950}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setReport(event.target.value)}
      />
      {exchange && (
        <div className={styles.datesContainer}>
          <WithLightTitle title="date de début">
            {moment(exchange?.startDate).format('[le] DD/MM/YYYY [à] HH:mm')}
          </WithLightTitle>
          {exchange.status === EnumEntityActionStatus.FINISHED && (
            <WithLightTitle title="date de fin">
              {moment(exchange?.endDate).format('[le] DD/MM/YYYY [à] HH:mm')}
            </WithLightTitle>
          )}
        </div>
      )}
      {exchange?.status !== EnumEntityActionStatus.FINISHED && (
        <>
          {exchange === undefined && (
            <PlanificationVisibilityToggle
              className={styles.planificationButton}
              showPlanification={showPlanification}
              onClick={() => {
                if (showPlanification) {
                  setPlannedDate(undefined);
                  setPlannedTime('');
                } else {
                  const plannedDateTime = moment(startDate);
                  if (moment().diff(startDate, 'minutes') === 0) {
                    plannedDateTime.add(1, 'minutes');
                  }

                  setPlannedDate(plannedDateTime.toDate());
                  setPlannedTime(plannedDateTime.format('HH:mm'));
                }
                setPlanificationVisibility(!showPlanification);
              }}
            />
          )}
          <Animation.Unroll visible={showPlanification}>
            <div className={styles.planification}>
              <WithLightTitle title="date d'échéance">
                <DatePickerInput onSelectDate={setPlannedDate} date={plannedDate} />
              </WithLightTitle>
              <WithLightTitle title="heure">
                <TimePicker
                  min={
                    moment(plannedDate).isSame(moment(), 'day')
                      ? moment().add(1, 'minute').format('HH:mm')
                      : undefined
                  }
                  max={moment(plannedDate).isSame(moment(), 'day') ? '23:59' : undefined}
                  value={plannedTime}
                  onChange={event => {
                    setPlannedTime((event.target as HTMLInputElement).value);
                  }}
                />
              </WithLightTitle>
            </div>
          </Animation.Unroll>
        </>
      )}
    </Modal>
  );
};

export default ExchangeFormModal;
