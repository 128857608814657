import { useResetPerimeter } from 'src/Hooks/Perimeter';
import React, { useCallback, useRef } from 'react';
import { useAuth0 } from 'src/Providers/Auth0';
import {
  Button,
  LetterBadge,
  PopupActions,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'src/Services/Routing';
import styles from './MobileParameters.module.scss';
import { MobileParameterIcon } from 'src/Assets';

const MobileParameters = () => {
  const auth = useAuth0();
  const firstName = auth?.user?.given_name;
  const familyName = auth?.user?.family_name;
  const pellet = `${firstName?.[0]}${familyName?.[0]}`;
  const resetPerimeter = useResetPerimeter();
  const history = useHistory();
  const popup = useRef<PopupActions>(null);
  const location = useLocation();
  const onLogout = useCallback(() => {
    resetPerimeter();
    auth?.logout({ returnTo: `${process.env.PUBLIC_URL}/login` });
  }, [auth, resetPerimeter]);

  return (
    <div className={styles.container}>
      <div className={styles.topPart}>
        <div className={styles.title}>paramètres</div>
        {pellet && <LetterBadge text={pellet} className={styles.letterBadgeContainer} />}
        <div>
          {(firstName || familyName) && (
            <p className={styles.text}>
              {firstName} {familyName}
            </p>
          )}

          {auth?.user?.email && <p className={styles.subText}>{auth?.user?.email}</p>}
        </div>
        <div
          className={styles.menuItem}
          onClick={() => {
            popup?.current?.close();
            history.push({ pathname: ROUTES.PERIMETER_SELECT, state: { background: location } });
          }}
        >
          <MobileParameterIcon />
          <div className={styles.spacer} />
          choix de vos unités
        </div>
      </div>
      <div className={styles.bottomPart}>
        <div className={styles.version}>{`version :  ${process.env.REACT_APP_VERSION}`}</div>
        <div className={styles.buttonMargin}>
          <Button.Secondary
            onClick={onLogout}
            text={'déconnexion'}
            className={styles.logoutButton}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileParameters;
