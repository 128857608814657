export const historyTabStyle = {
  textTransform: 'lowercase',
  fontSize: '1rem',
  lineHeight: '1.25rem',
  color: '#2175d9',
  paddingTop: '0.5rem',
  height: '2.5rem',
  minHeight: '2.5rem',
};

export const historyTabsStyles = {
  width: '100%',
  height: '2.5rem',
  minHeight: '2.5rem',
  '& button:not(:first-of-type)': {
    '&::after': {
      content: `''`,
      background: '#c7d1dc',
      opacity: 0.7,
      position: 'absolute',
      bottom: '1rem',
      left: 0,
      height: '0.938rem',
      width: '1px',
    },
  },
  '& button.Mui-selected': { color: '#0f1941' },
  '& span.MuiTabs-indicator': {
    backgroundColor: '#ffb511',
    height: '2px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    backgroundClip: 'content-box',
  },
};
