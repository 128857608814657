import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CompaniesService } from 'src/Services/API';
import { db } from 'src/Services/DB';
import { usePerimeter } from '../Perimeter';
import { CommunicationMatrixEntry, CommunicationMatrixEntryLight } from './types';

export const useBackgroundFetchCommunicationMatrix = (
  extraOptions?: UseQueryOptions<unknown, unknown, CommunicationMatrixEntryLight[]>
) => {
  const { brandCode, agencyId, companyId } = usePerimeter();
  useQuery<unknown, unknown, CommunicationMatrixEntryLight[]>(
    ['fetchCommunicationMatrix', companyId, agencyId],
    async () => {
      const communicationMatrix = (await CompaniesService.companiesControllerGetCommunicationMatrix(
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: companyId!,
          brandCode,
          agencyId,
        }
      )) as unknown as CommunicationMatrixEntry[];

      await db.transaction('rw', db.communicationMatrix, async () => {
        const updates = communicationMatrix.map(async entry => {
          const updatedEntries = await db.communicationMatrix
            .where('entryId')
            .equals(entry.entryId ?? '')
            .modify(entry);

          if (updatedEntries === 0) {
            await db.communicationMatrix.add(entry);
          }
        });

        await Promise.all(updates);
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 300000,
      enabled: agencyId !== undefined && companyId !== undefined,
      ...extraOptions,
    }
  );
};
