import React from 'react';

import { OwnProps } from './SelectSecurityEventType.types';
import { DropDown } from '@randstad-lean-mobile-factory/react-components-core';

export const OTHER_REASON = 'autre';

const SelectSecurityEventType = ({ selection, onSelectionChange, types, className }: OwnProps) => {
  return (
    <DropDown
      className={className}
      placeholder={'sélectionnez'}
      selectedItem={selection}
      items={types}
      keyValueExtractor={(searchResult: string) => ({
        key: searchResult,
        value: searchResult,
      })}
      onSelectItem={(value: string) => onSelectionChange(value)}
    />
  );
};

export default SelectSecurityEventType;
