import { useLiveQuery } from 'dexie-react-hooks';
import { EntityAction, EnumEntityActionType } from 'src/Services/API';
import { db, Local } from 'src/Services/DB';
import { useBackgroundFetchCompanyActions } from '.';

export const useFetchTargetActions = ({
  targetId,
  type,
}: {
  targetId: string;
  type: EnumEntityActionType;
}) => {
  useBackgroundFetchCompanyActions();
  return useLiveQuery(
    () => db.actions.where({ type, targetId }).reverse().sortBy('startDate'),
    [],
    [] as Local<EntityAction, never>[]
  );
};
