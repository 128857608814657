import { useLiveQuery } from 'dexie-react-hooks';
import moment from 'moment';
import { ROLE } from 'src/Redux/Types';
import { EntityAction, EnumEntityActionStatus, EnumEntityActionType } from 'src/Services/API';
import { db, Local } from 'src/Services/DB';
import { useBackgroundFetchCompanyActions } from '.';
import { useFetchAllCandidates } from '../Candidates';
import { useFetchCompanyContacts } from '../Contacts';

export const useFetchWeekActions = (_week: moment.Moment) => {
  const week = _week.clone();
  useBackgroundFetchCompanyActions();
  const contactsInfo = useFetchCompanyContacts();
  const candidatesInfo = useFetchAllCandidates() ?? [];
  const weekActions = useLiveQuery(
    () =>
      db.actions
        .where('endDate')
        .between(week.startOf('week').toDate(), week.endOf('week').toDate())
        .sortBy('endDate'),
    [],
    [] as Local<EntityAction>[]
  );
  return weekActions
    .filter(
      action =>
        action.status === EnumEntityActionStatus.IN_PROGRESS &&
        // Filter pool exited TT actions
        ([
          EnumEntityActionType.ACTION_CANDIDATE_EXCHANGE,
          EnumEntityActionType.ACTION_SECURITY_EVENT,
        ].includes(action.type)
          ? !!candidatesInfo.find(candidate => candidate.candidateId === action.targetId)
          : !!contactsInfo.find(contact => contact.contactId === action.targetId))
    )
    .map(action => {
      const targetType = [
        EnumEntityActionType.ACTION_CANDIDATE_EXCHANGE,
        EnumEntityActionType.ACTION_SECURITY_EVENT,
      ].includes(action.type)
        ? ROLE.CANDIDATE
        : ROLE.CONTACT;
      const actionTarget =
        targetType === ROLE.CANDIDATE
          ? candidatesInfo.find(candidate => candidate.candidateId === action.targetId)
          : contactsInfo.find(contact => contact.contactId === action.targetId);
      return {
        ...action,
        targetName: [actionTarget?.firstName, actionTarget?.name].join(' '),
      };
    });
};
