import Dexie from 'dexie';
import { Absence } from 'src/Hooks/Absence';
import { CommunicationMatrixEntryLight } from 'src/Hooks/CommunicationMatrix/types';
import { Exchange } from 'src/Hooks/Exchanges';
import { ProductionImpact } from 'src/Hooks/ProductionImpact';
import { SecurityEvent } from 'src/Hooks/SecurityEvents';
import {
  AbsenceReason,
  CandidateInPool,
  ClaimCategory,
  ClaimEntity,
  CompanyContact,
  EntityAction,
} from '../API';

// An utility type that makes an object's remote id optional and adds a local id field
export type Local<T, K extends keyof T | never = never> = Omit<T, K> &
  Partial<Pick<T, K>> & { localId?: number };

class TdpDB extends Dexie {
  contacts: Dexie.Table<Local<CompanyContact>, number>;
  candidates: Dexie.Table<Local<CandidateInPool, 'candidateId'>, number>;
  claims: Dexie.Table<Local<ClaimEntity>, number>;
  claimCategories: Dexie.Table<ClaimCategory, string>;
  securityEventTypes: Dexie.Table<{ id: string }, string>;
  exchanges: Dexie.Table<Local<Exchange>, number>;
  absences: Dexie.Table<Local<Absence>, number>;
  securityEvents: Dexie.Table<Local<SecurityEvent>, number>;
  absenceReasons: Dexie.Table<Local<AbsenceReason>, number>;
  productionImpact: Dexie.Table<Local<ProductionImpact>, number>;
  communicationMatrix: Dexie.Table<Local<CommunicationMatrixEntryLight>, number>;
  actions: Dexie.Table<Local<EntityAction>, number>;

  constructor() {
    super('TdpLocal');
    this.version(1).stores({
      contacts: '++localId, contactId',
      candidates: '++localId, candidateId',
      claimCategories: 'id',
      securityEventTypes: 'id',
      exchanges: '++localId',
      absences: '++localId',
      securityEvents: '++localId',
      absenceReasons: '++localId',
      claims: '++localId',
    });
    this.version(2).stores({
      productionImpact: '++localId',
    });
    this.version(3).stores({
      absences: '++localId, endDate, absenceId, [candidateId+companyId]',
    });
    this.version(4).stores({
      communicationMatrix: '++localId, [contactId+companyId]',
    });
    this.version(5).stores({
      actions: '++localId, &actionId, targetId, [targetId+type+startDate]',
    });
    this.version(6).upgrade(trans =>
      trans
        .table('communicationMatrix')
        .toCollection()
        .modify(entry => {
          entry.version = 0;
          entry.markedForDeletion = false;
        })
    );
    this.version(7).stores({
      communicationMatrix: '++localId, [contactId+companyId], &entryId',
    });
    this.version(8).stores({
      actions: '++localId, &actionId, targetId, [targetId+type+startDate], [type+targetId]',
    });
    this.version(9).stores({
      actions:
        '++localId, &actionId, targetId, [targetId+type+startDate], [type+targetId], endDate',
    });
    this.version(10).stores({
      claims: '++localId, claimId,  candidate.id, companyId, [candidate.id+companyId], opening',
    });
    this.version(11).stores({
      claims:
        '++localId, claimId,  candidate.id, customerContact.id , companyId, [candidate.id+companyId], [customerContact.id+companyId], opening',
    });
    // The following line is needed if your typescript
    // is compiled using babel instead of tsc:
    this.contacts = this.table('contacts');
    this.candidates = this.table('candidates');
    this.claimCategories = this.table('claimCategories');
    this.claims = this.table('claims');
    this.securityEventTypes = this.table('securityEventTypes');
    this.exchanges = this.table('exchanges');
    this.absences = this.table('absences');
    this.securityEvents = this.table('securityEvents');
    this.absenceReasons = this.table('absenceReasons');
    this.productionImpact = this.table('productionImpact');
    this.communicationMatrix = this.table('communicationMatrix');
    this.actions = this.table('actions');
  }
}

export const db = new TdpDB();
