import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useAuth0 } from 'src/Providers/Auth0';
import { getCurrentCompanyId } from 'src/Redux/Companies/Selectors';
import {
  getAnalyticsUserInfo,
  getCurrentAgency,
  getCurrentBrandCode,
} from 'src/Redux/Perimeter/Selectors';
import { ExportService, ExportType } from 'src/Services/API';
import ReactGA from 'react-ga4';

export const useExportToDrive = () => {
  const auth = useAuth0();
  const agencyId = useSelector(getCurrentAgency);
  const companyId = useSelector(getCurrentCompanyId) ?? '';
  const brandCode = useSelector(getCurrentBrandCode);
  const { zoneId, regionId } = useSelector(getAnalyticsUserInfo);
  return useMutation(
    ['exportToDrive'],
    async (params: { types: ExportType[]; startingDate: Date; endingDate: Date }) => {
      if (auth?.user?.email) {
        const result = await ExportService.exportControllerGetExportData({
          body: {
            userMail: auth.user.email,
            types: params.types,
            startingDate: params.startingDate,
            endingDate: params.endingDate,
            companyId,
            agencyId,
            brandCode,
          },
        });
        ReactGA.event('Export', { brandCode, agencyId, zoneId, regionId });
        return result;
      }
    },
    {
      onSuccess: () => {
        toast.success(
          "votre demande a bien été enregistrée, vous recevrez votre export par mail d'ici quelques minutes",
          { autoClose: 5000 }
        );
      },
    }
  );
};
