import React, { createContext, useContext, useState } from 'react';
import { Detector } from 'react-detect-offline';

const ConnectivityContext = createContext<boolean>(true);

export const ConnectivityProvider = ({ children }: { children: React.ReactElement }) => {
  const [isOnline, setIsOnline] = useState(true);
  return (
    <>
      <Detector
        render={() => null}
        onChange={online => {
          setIsOnline(online);
        }}
      />
      <ConnectivityContext.Provider value={isOnline}>{children}</ConnectivityContext.Provider>
    </>
  );
};

export const useConnectivity = () => {
  return useContext(ConnectivityContext);
};
