import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Props } from './SecurityEventEditionModal.types';
import SecurityEventFormModal from '../SecurityEventFormModal';
import { useFetchAction } from 'src/Hooks/Actions';

const SecurityEventEditionModal = ({ modalParameters, onValidate, open, fetchStatus }: Props) => {
  const target = modalParameters?.target;
  const localId = modalParameters?.contentId;

  const history = useHistory();
  // Redirect user to app root if he navigates directly to modal url on accident
  useEffect(() => {
    if (target === undefined) {
      history?.push('/');
    }
  }, [target, history]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const securityEvent = useFetchAction(localId!);

  return (
    <SecurityEventFormModal
      open={open ?? true}
      securityEvent={securityEvent}
      title="modification d'un événement de sécurité pour"
      target={target}
      onValidate={onValidate}
      fetchStatus={fetchStatus}
    />
  );
};

export default SecurityEventEditionModal;
