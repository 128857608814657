import { createAction, updateAction } from 'src/Services/Actions';
import { EntityAction } from 'src/Services/API';
import { db } from 'src/Services/DB';

export const actionMutationsConfig = {
  'actions/create': {
    mutationFn: createAction,
    onSuccess: async (action: EntityAction, { localId }: Parameters<typeof createAction>[0]) => {
      await db.actions.update(localId, action);
    },
    onError: async (_: unknown, { localId }: Parameters<typeof createAction>[0]) => {
      await db.actions.delete(localId);
    },
    // Without a retry, mutations done while offline will fail instead of being paused
    retry: 1,
  },
  'actions/update': {
    mutationFn: updateAction,
    onSuccess: async (action: EntityAction, { localId }: Parameters<typeof createAction>[0]) => {
      await db.actions.update(localId, action);
    },
    // Without a retry, mutations done while offline will fail instead of being paused
    retry: 1,
  },
};
