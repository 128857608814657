import React from 'react';

import { Props } from './PlanificationVisibilityToggle.types';
import styles from './PlanificationVisibilityToggle.module.scss';
import { CircleMoins, PlusCircle } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import SmallLink from '../SmallLink';

const PlanificationVisibilityToggle = ({ onClick, showPlanification, className }: Props) => {
  return (
    <SmallLink
      className={className}
      leftIcon={
        showPlanification ? (
          <CircleMoins className={styles.planificationIcon} />
        ) : (
          <PlusCircle className={styles.planificationIcon} />
        )
      }
      title={showPlanification ? 'supprimer la planification' : 'ajouter une planification'}
      onClick={onClick}
    />
  );
};

export default PlanificationVisibilityToggle;
