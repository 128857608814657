import {
  Button,
  DatePickerInput,
  FetchButton,
  Modal,
  TextArea,
  ToggleSwitch,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { CutFinger } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CandidateOnMission,
  ClaimCategory,
  CompanyContact,
  OSMRepositoriesModelClaimCategoryRepositoryDtoClaimReasonDto as ClaimReason,
  TargetType,
} from 'src/Services/API';
import SelectCandidate from '../Selects/SelectCandidate';
import SelectClaimCategory from '../Selects/SelectClaimCategory';
import SelectClaimReason from '../Selects/SelectClaimReason';
import SelectContact from '../Selects/SelectContact';
import styles from './ClaimEditionModal.module.scss';

import { Props } from './ClaimEditionModal.types';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';

const ClaimEditionModal = ({ modalParameters, fetchStatus, onValidate, open, claim }: Props) => {
  const history = useHistory();

  const [comment, setComment] = useState('');
  const [candidate, setCandidate] = useState<CandidateOnMission | null>(null);
  const [contact, setContact] = useState<CompanyContact | null>(null);
  const [createdAt, setCreatedAt] = useState<Date | undefined>(new Date());
  const [closed, setClosed] = useState(false);
  const [category, setCategory] = useState<ClaimCategory | undefined>(undefined);
  const [reason, setReason] = useState<ClaimReason | undefined>(undefined);

  const target = modalParameters?.target;
  const localId = modalParameters?.contentId;

  if (!localId) history?.push('/');

  const reset = useCallback(() => {
    setComment(claim?.comment ?? '');
    setCandidate({
      candidateId: claim?.candidate?.id,
      name: claim?.candidate?.name,
      firstName: claim?.candidate?.firstName,
    });
    setContact({
      contactId: claim?.customerContact?.id,
      name: claim?.customerContact?.name,
      firstName: claim?.customerContact?.firstName,
    });
    setCreatedAt(claim?.opening);
    setClosed(!!claim?.closing);
    setCategory({
      id: claim?.category?.id,
      label: claim?.category?.label,
      isCandidateCategory: claim?.type === TargetType.TT,
      isContactCategory: claim?.type !== TargetType.TT,
    });
    setReason({ id: claim?.reason?.id, label: claim?.reason?.label });
  }, [claim]);

  useEffect(reset, [reset, claim]);

  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);

  // Redirect user to app root if he navigates directly to modal url on accident
  useEffect(() => {
    if (target === undefined) {
      history?.push('/');
    }
  }, [target, history]);
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  return (
    <Modal
      icon={<CutFinger />}
      title={`déclarer une réclamation ${claim?.type === TargetType.TT ? 'intérimaire' : 'client'}`}
      open={open ?? true}
      onClose={() => {
        reset();
        onClose();
      }}
      footerActionsLeft={[
        <Button.Tertiary onClick={reset}>
          {isMobile ? 'réinitialiser' : 'tout réinitialiser'}
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        isMobile ? (
          <Button.Tertiary onClick={onClose}>annuler</Button.Tertiary>
        ) : (
          <Button.Secondary onClick={onClose}>annuler</Button.Secondary>
        ),
        <FetchButton
          title="valider"
          disabled={!category || !reason || !createdAt}
          errorTitle="réessayer"
          fetchStatus={fetchStatus}
          onClick={() => {
            onValidate({
              type: claim?.type,
              opening: moment(createdAt).toDate(),
              closing: closed ? new Date() : undefined,
              category,
              reason: reason,
              customerContact:
                claim?.type !== TargetType.TT
                  ? target
                  : {
                      ...contact,
                      civility: contact?.civility?.label,
                    },
              candidate: claim?.type === TargetType.TT ? target : candidate ?? undefined,
              comment,
              localId,
            });
          }}
          onSuccess={onClose}
        />,
      ]}
    >
      {claim?.type !== TargetType.TT ? (
        <WithLightTitle title="interlocuteur">
          <div className={styles.headerText}>{`${target?.name} - ${target?.position}`}</div>
        </WithLightTitle>
      ) : (
        <WithLightTitle title="intérimaire">
          <div
            className={styles.headerText}
          >{`${target?.name} - ${target?.qualification?.name}`}</div>
        </WithLightTitle>
      )}
      <WithLightTitle
        title={`${claim?.type !== TargetType.TT ? 'intérimaire' : 'interlocuteur'} (optionnel)`}
        className={styles.searchField}
      >
        {claim && (
          <>
            {claim?.type !== TargetType.TT ? (
              <SelectCandidate
                selectedCandidate={candidate}
                onCandidateSelect={selectedCandidate => {
                  setCandidate(selectedCandidate);
                }}
              />
            ) : (
              <SelectContact
                selectedContact={contact}
                onContactSelect={selectedContact => {
                  setContact(selectedContact);
                }}
                canBeReset
                defaultValue="nom d'une personne..."
              />
            )}
          </>
        )}
      </WithLightTitle>
      <WithLightTitle title="catégorie de réclamation" className={styles.searchField}>
        <SelectClaimCategory
          candidateCategories={claim?.type === TargetType.TT}
          contactCategories={claim?.type !== TargetType.TT}
          selectedClaimCategory={category}
          onClaimCategorySelect={selectedClaimCategory => {
            setCategory(selectedClaimCategory);
            setReason(undefined);
          }}
        />
      </WithLightTitle>
      <WithLightTitle title="cause de la réclamation" className={styles.searchField}>
        <SelectClaimReason
          selectedClaimCategory={category}
          selectedClaimReason={reason}
          onClaimReasonSelect={selectedClaimReason => {
            setReason(selectedClaimReason);
          }}
          disabled={category === undefined}
        />
      </WithLightTitle>
      <WithLightTitle title="commentaire" className={styles.searchField}>
        <TextArea
          className={styles.textArea}
          value={comment}
          maxLength={950}
          placeholder="détails sur la réclamation"
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setComment(event.target.value);
          }}
        />
      </WithLightTitle>
      <WithLightTitle title="date d'ouverture">
        <DatePickerInput onSelectDate={setCreatedAt} date={createdAt} />
      </WithLightTitle>

      <div className={styles.closedClaim}>
        <div className={styles.closedClaimedText}>marquer la réclamation comme fermée</div>
        <ToggleSwitch
          checked={closed}
          onCheckStatusChange={closed => {
            setClosed(closed);
          }}
        />
      </div>
    </Modal>
  );
};

export default ClaimEditionModal;
