import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompaniesState } from './Types';

const initialState: CompaniesState = {};

export const { reducer: companiesReducer, actions: companiesActions } = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCurrentCompany: (state, action: PayloadAction<CompaniesState['currentCompany']>) => {
      state.currentCompany = action.payload;
    },
  },
});
