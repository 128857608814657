import { ActionsService, EnumCreateActionBodyType } from 'src/Services/API';
import { db } from 'src/Services/DB';
import { Target } from 'src/Services/Routing';

export class SecurityEvent {
  target?: Target;
  type?: string;
  report?: string;
  startDate?: Date;
  endDate?: Date;
}

export interface SecurityEventExtended extends SecurityEvent {
  brandCode: string;
  agencyId: string;
  companyId: string;
}

export const createSecurityEvent = async ({
  localId,
  brandCode,
  agencyId,
  companyId,
}: SecurityEventExtended & { localId: number }) => {
  const securityevent = await db.securityEvents.get(localId);
  if (securityevent === undefined) {
    throw new Error(`Absence ${localId} does not exist in local DB`);
  }
  ActionsService.actionsControllerPostCreateAction({
    body: {
      companyId,
      brandCode: brandCode ?? '',
      agencyId: agencyId ?? '',
      targetId: securityevent?.target?.id ?? '',
      startDate: securityevent.startDate ?? new Date(),
      type: EnumCreateActionBodyType.ACTION_SECURITY_EVENT,
      subType: securityevent.type ?? '',
      report: securityevent.report ?? '',
      endDate: securityevent.endDate ?? new Date(),
    },
  });
};

export const securityEventMutationsConfig = {
  'securityevents/create': {
    mutationFn: createSecurityEvent,
    onSuccess: async (_: unknown, { localId }: Parameters<typeof createSecurityEvent>[0]) => {
      await db.securityEvents.delete(localId);
    },
    // Without a retry, mutations done while offline will fail instead of being paused
    retry: 1,
  },
};
