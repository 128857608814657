import { QueryClient, useQueries, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import {
  absenceMutationsConfigs,
  useBackgroundCleanOldAbsences,
  useBackgroundFetchAbsenceReasons,
  useBackgroundFetchAbsences,
} from './Absence';
import { actionMutationsConfig, useBackgroundFetchCompanyActions } from './Actions';
import { useBackgroundFetchAllCandidates } from './Candidates';
import {
  claimMutationsConfig,
  useBackgroundCleanOldClaims,
  useBackgroundFetchClaimCategories,
  useBackgroundFetchClaims,
} from './Claims';
import {
  useBackgroundFetchCommunicationMatrix,
  communicationMatrixMutationsConfig,
} from './CommunicationMatrix';
import { useBackgroundFetchCompanyContacts } from './Contacts';
import { exchangeMutationsConfig } from './Exchanges';
import { productionImpactMutationsConfig } from './ProductionImpact';
import { securityEventMutationsConfig } from './SecurityEvents';

export const useBackgroundFetch = () => {
  useBackgroundFetchCompanyContacts();
  useBackgroundFetchAllCandidates();
  useBackgroundFetchClaimCategories();
  useBackgroundFetchAbsenceReasons();
  useBackgroundCleanOldAbsences();
  useBackgroundFetchAbsences();
  useBackgroundFetchCompanyActions();
  useBackgroundFetchCommunicationMatrix();
  useBackgroundFetchClaims();
  useBackgroundCleanOldClaims();
};

/** Must contains all mutation configs to make mutation hydration work.
 *
 * When hydrating a mutation, @tanstack/react-query relies on the mutation's key to
 * find the associated function.
 * If no function is associated with the mutation, its status is set to failed.
 *
 * See https://@tanstack/react-query.tanstack.com/guides/mutations#persist-mutations for example code
 */
export const mutationConfigs = (queryClient: QueryClient) => {
  return {
    ...absenceMutationsConfigs(queryClient),
    ...exchangeMutationsConfig,
    ...actionMutationsConfig,
    ...securityEventMutationsConfig,
    ...claimMutationsConfig,
    ...productionImpactMutationsConfig,
    ...communicationMatrixMutationsConfig(queryClient),
  };
};

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;

export function useQueriesTyped<TQueries extends readonly UseQueryOptions[]>(
  queries: [...TQueries]
): {
  [ArrayElement in keyof TQueries]: UseQueryResult<
    TQueries[ArrayElement] extends { select: infer TSelect }
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        TSelect extends (data: any) => any
        ? ReturnType<TSelect>
        : never
      : Awaited<
          ReturnType<NonNullable<Extract<TQueries[ArrayElement], UseQueryOptions>['queryFn']>>
        >
  >;
} {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQueries({ queries: queries as UseQueryOptions<unknown, unknown, unknown>[] }) as any;
}

export enum QueryKeys {
  FETCH_ABSENCES = 'fetchAbsences',
  FETCH_CLAIMS = 'fetchClaims',
  FETCH_CANDIDATE_CLAIMS = 'fetchCandidateClaims',
  FETCH_CLAIM_CATEGORIES = 'fetchClaimCategories',
  FETCH_COMPANY_ACTIONS = 'fetchCompanyActions',
  CLEAN_OLD_CLAIMS = 'cleanOldClaims',
  FETCH_COMPANY_SERVICES = 'fetchCompanyServices',
  FETCH_CGC = 'fetchCGC',
  FETCH_POOL_MOTIVATOIONS = 'fetchPoolMotivations',
  FETCH_POOL_SENDERS = 'fetchPoolSenders',
  FETCH_ALL_CANDIDATES = 'fetchAllCandidates',
  FETCH_CONTACT_CLAIMS = 'fetchContactClaims',
  FETCH_CANDIDATE_ABSENCES = 'fetchCandidateAbsences',
  FETCH_COMMUNICATION_MATRIX = 'fetchCommunicationMatrix',
  FETCH_POOL_EXIT_DATA = 'fetchPoolExitData',
}

export enum MutationKeys {
  UPDATE_CANDIDATE_SUBSCRIPTION = 'updateCandidateSubscription',
  ABSENCE_CREATION = 'absences/create',
  ABSENCE_DELETION = 'absences/delete',
  ABSENCE_UPDATE = 'absence/update',
  ACTION_CREATION = 'action/creation',
  ACTION_UPDATE = 'action/update',
  CLAIMS_CREATION = 'claims/create',
  CLAIMS_UPDATE = 'claims/update',
  COMMUNICATION_MATRIX_INSERT = 'communicationmatrix/insert',
  COMMUNICATION_MATRIX_REMOVE = 'communicationmatrix/remove',
  COMMUNICATION_MATRIX_UPDATE = 'communicationmatrix/update',
  DELETE_POOL_EXIT = 'deletePoolExit',
}
