import React from 'react';
import styles from './SelectPoolMotivation.module.scss';
import { DropDown } from '@randstad-lean-mobile-factory/react-components-core';
import useFetchPoolMotivations from 'src/Hooks/Repositories/useFetchPoolMotivations';
import { Props } from './SelectPoolMotivation.types';
import { IdLabel } from 'src/Services/API';

const SelectPoolMotivation = ({ selectedMotivation, setSelectedMotivation }: Props) => {
  const { data: motivations } = useFetchPoolMotivations();

  return (
    <DropDown
      key="selectMotivation"
      placeholder="choisir un motif"
      items={motivations ?? []}
      selectedItem={selectedMotivation}
      onSelectItem={setSelectedMotivation}
      keyValueExtractor={(searchResult: IdLabel) => ({
        key: searchResult.id.toString(),
        value: searchResult.label,
      })}
      childrenClassName={styles.dropDownChildren}
    />
  );
};

export default SelectPoolMotivation;
