import { QueryClient } from '@tanstack/react-query';
import { AddCommunicationMatrixEntryBody } from 'src/Services/API';
import { ConflictError, NotFoundError } from 'src/Services/API/types';
import {
  insertContactInCommunicationMatrix,
  removeContactInCommunicationMatrix,
  updateContactInCommunicationMatrix,
} from 'src/Services/CommunicationMatrix';
import { db } from 'src/Services/DB';
import { CommunicationMatrixEntryLight } from './types';
import { QueryKeys } from '..';

function isCommunicationMatrixEntryLight(object: unknown): object is CommunicationMatrixEntryLight {
  return (
    typeof object === 'object' &&
    object !== null &&
    'contactId' in object &&
    'frequencyUnit' in object &&
    'frequency' in object &&
    'version' in object &&
    'entryId' in object
  );
}

export const communicationMatrixMutationsConfig = (queryClient: QueryClient) => {
  return {
    'communicationmatrix/insert': {
      mutationFn: insertContactInCommunicationMatrix,
      onSuccess: async (
        communicationMatrixEntry: AddCommunicationMatrixEntryBody,
        { localId }: Parameters<typeof insertContactInCommunicationMatrix>[0]
      ) => {
        await db.communicationMatrix.update(localId, communicationMatrixEntry);
      },
      onError: async (
        _: AddCommunicationMatrixEntryBody,
        { localId }: Parameters<typeof insertContactInCommunicationMatrix>[0]
      ) => {
        await db.communicationMatrix.delete(localId);
      },
      // Without a retry, mutations done while offline will fail instead of being paused
      retry: 1,
    },
    'communicationmatrix/remove': {
      mutationFn: removeContactInCommunicationMatrix,
      onSuccess: async (
        _: unknown,
        { contactId }: Parameters<typeof removeContactInCommunicationMatrix>[0]
      ) => {
        await db.communicationMatrix.where({ contactId }).delete();
      },
      onError: async (
        error: unknown,
        { contactId }: Parameters<typeof removeContactInCommunicationMatrix>[0]
      ) => {
        // entry is not up to date
        // invalidate cache to refetch communication matrix
        if (error instanceof ConflictError) {
          queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_COMMUNICATION_MATRIX] });
        }

        // entry has already been deleted
        // so delete it for real in the app
        if (error instanceof NotFoundError) {
          await db.communicationMatrix.where({ contactId }).delete();
        }

        await db.communicationMatrix.where({ contactId }).modify({ markedForDeletion: false });
      },
      // Without a retry, mutations done while offline will fail instead of being paused
      retry: 1,
    },
    'communicationmatrix/update': {
      mutationFn: updateContactInCommunicationMatrix,
      onSuccess: async (
        communicationMatrixEntry: AddCommunicationMatrixEntryBody,
        { localId }: Parameters<typeof updateContactInCommunicationMatrix>[0]
      ) => {
        await db.communicationMatrix.update(localId, communicationMatrixEntry);
      },
      onError: async (
        error: unknown,
        { localId }: Parameters<typeof updateContactInCommunicationMatrix>[0]
      ) => {
        if (error instanceof ConflictError) {
          if (isCommunicationMatrixEntryLight(error.data)) {
            await db.communicationMatrix.update(localId, error.data);
          }
        }
      },
      // Without a retry, mutations done while offline will fail instead of being paused
      retry: 1,
    },
  };
};
