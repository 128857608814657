import React, { useRef, useState } from 'react';

import { Props } from './CommunicationMatrixMenu.types';
import styles from './CommunicationMatrixMenu.module.scss';
import {
  Button,
  Modal,
  PopupActions,
  PopupMenu,
  PopupMenuContext,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ThreeDots } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';

import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';
import CommunicationMatrixMenuContent from './CommunicationMatrixMenuContent/CommunicationMatrixMenuContent.component';

const CommunicationMatrixMenu = ({ target }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const mobileModalRef = useRef<PopupActions>(null);
  const deviceWidth = window.innerWidth;
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });

  return (
    <div className={styles.container}>
      {isMobile ? (
        <Modal
          title={<></>}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          ref={mobileModalRef}
          contentStyle={{ width: deviceWidth - 32 }}
          hideCloseAction
          footerActionsRight={[
            <Button.Tertiary
              className={styles.mobileButton}
              onClick={() => {
                mobileModalRef.current?.close();
              }}
            >
              annuler
            </Button.Tertiary>,
          ]}
          trigger={
            <Button.Tertiary
              className={classnames(
                styles.button,
                { [styles.openButton]: isOpen },
                { [styles.mobileTrigger]: isMobile }
              )}
            >
              <ThreeDots className={styles.menuIcon} />
            </Button.Tertiary>
          }
        >
          <PopupMenuContext.Provider value={() => ({ actions: null })}>
            <div className={styles.globalModalContainer}>
              <div className={styles.modalContent}>
                <CommunicationMatrixMenuContent
                  target={target}
                  onItemClick={() => {
                    mobileModalRef.current?.close();
                  }}
                />
              </div>
            </div>
          </PopupMenuContext.Provider>
        </Modal>
      ) : (
        <PopupMenu
          position={'left top'}
          width={285}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          trigger={
            <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpen })}>
              <ThreeDots className={styles.menuIcon} />
            </Button.Tertiary>
          }
        >
          <CommunicationMatrixMenuContent target={target} />
        </PopupMenu>
      )}
    </div>
  );
};

export default CommunicationMatrixMenu;
