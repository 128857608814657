import React, { useCallback } from 'react';
import { useUpdateClaim } from 'src/Hooks/Claims';
import { useFetchClaim } from 'src/Hooks/Claims/useFetchClaim';
import { ClaimEntity } from 'src/Services/API/index';
import { Local } from 'src/Services/DB/index';
import { toFetchStatus } from 'src/Services/ReactQuery';
import ClaimEditionModal from './ClaimEditionModal.component';
import { OwnProps } from './ClaimEditionModal.types';

const MemoClaimEditionModal = React.memo(ClaimEditionModal);

const EnhancedClaimEditionModal = (props: OwnProps) => {
  const updater = useUpdateClaim();
  const fetchStatus = toFetchStatus(updater.mutation);
  const onValidate = useCallback(
    async (claim: Local<ClaimEntity>) => {
      await updater.update(claim);
    },
    [updater]
  );
  const localId = props.modalParameters?.contentId;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const claim = useFetchClaim(localId!);
  return (
    <MemoClaimEditionModal
      fetchStatus={fetchStatus}
      modalParameters={props.modalParameters}
      onValidate={onValidate}
      claim={claim}
      {...props}
    />
  );
};

export default EnhancedClaimEditionModal;
