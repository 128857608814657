import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../index';
import { CandidatesService } from '../../Services/API';

const useDeleteScheduledPoolExit = () => {
  const query = useQueryClient();
  return useMutation(
    [MutationKeys.DELETE_POOL_EXIT],
    async (params: { id: string }) => {
      return await CandidatesService.candidatesControllerDeleteScheduledPoolExit({
        body: { candidateId: params.id },
      });
    },
    {
      onSuccess: async () => {
        await query.invalidateQueries([QueryKeys.FETCH_ALL_CANDIDATES]);
      },
    }
  );
};

export default useDeleteScheduledPoolExit;
