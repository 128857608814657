import React from 'react';
import { useFetchClaimCategories } from 'src/Hooks/Claims';
import SelectClaimCategory from './SelectClaimCategory.component';
import { OwnProps } from './SelectClaimCategory.types';

const MemoSelectClaimCategory = React.memo(SelectClaimCategory);

const EnhancedSelectClaimCategory = (ownProps: OwnProps) => {
  const claimCategories = useFetchClaimCategories().filter(
    category =>
      (ownProps.candidateCategories && category.isCandidateCategory) ||
      (ownProps.contactCategories && category.isContactCategory)
  );
  return <MemoSelectClaimCategory claimCategories={claimCategories} {...ownProps} />;
};

export default EnhancedSelectClaimCategory;
