import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import { useState, useEffect } from 'react';
import { FETCH_STATUS } from 'src/Redux/Types';
import { CompanyService } from 'src/Services/API';
import { ChildProps } from './SelectService.types';

const SelectService = ({ selected, onChange, services, fetchStatus }: ChildProps) => {
  const [selectedService, setSelectedService] = useState<CompanyService | undefined>(undefined);
  useEffect(() => {
    if (fetchStatus === FETCH_STATUS.FULFILLED && services.length > 0) {
      setSelectedService(services.find(service => service.serviceId === selected) ?? undefined);
    }
  }, [fetchStatus, services, selected]);

  return (
    <ComboBox
      id="selectService"
      value={selectedService ?? null}
      placeholder="sélectionnez"
      searchResults={services}
      keyValueExtractor={(searchResult: CompanyService) => ({
        key: searchResult.serviceId ?? '',
        value: searchResult.name ?? '',
      })}
      useNativeOptionFiltering
      fetchStatus={fetchStatus}
      onChange={service => onChange(service?.serviceId)}
    />
  );
};

export default SelectService;
