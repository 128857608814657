import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';
import styles from './MobileAppsScreen.module.scss';
import { RandstadMobileAppsGrid } from '@randstad-lean-mobile-factory/react-components-core';
import { useSelector } from 'react-redux';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
const MobileAppsScreen = () => {
  const { isProd } = useIsProductionEnvironment();
  const brandCode = useSelector(getCurrentBrand);
  return (
    <div className={styles.container}>
      <div className={styles.title}>applications</div>
      <RandstadMobileAppsGrid isProd={isProd} brandCode={brandCode?.brandCodeApiHeader} />
    </div>
  );
};

export default MobileAppsScreen;
