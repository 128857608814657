import { useLiveQuery } from 'dexie-react-hooks';
import { UseQueryOptions } from '@tanstack/react-query';
import { db } from 'src/Services/DB';
import { CommunicationMatrixEntryLight } from './types';
import { useBackgroundFetchCommunicationMatrix } from './useBackgroundFetchCommunicationMatrix';

export const useFetchCommunicationMatrixContact = (
  entryLocalId: number,
  extraOptions?: UseQueryOptions<unknown, unknown, CommunicationMatrixEntryLight[]>
) => {
  useBackgroundFetchCommunicationMatrix(extraOptions);
  const communicationMatrixContact = useLiveQuery(() =>
    db.communicationMatrix.where({ localId: entryLocalId }).first()
  );

  return communicationMatrixContact;
};
