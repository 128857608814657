import { EnumCommunicationMatrixEntryFrequencyUnit } from '../API';

export const frequencyUnitEnumMap = {
  WEEKLY: 'semaine',
  MONTHLY: 'mois',
  PER_SEMESTER: 'semestre',
  YEARLY: 'an',
};

export function frequencyUnitEnumToKeyValue(
  frequencyUnit: EnumCommunicationMatrixEntryFrequencyUnit
): { key: string; value: string } {
  return {
    key: frequencyUnit,
    value: frequencyUnitEnumMap[frequencyUnit],
  };
}
