import React from 'react';

import styles from './ShareToGDrive.module.scss';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { Share } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationState } from '../../Services/Routing';

const ShareToGDrive = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  return (
    <Button.Tertiary
      className={styles.button}
      onClick={() =>
        history.push({
          pathname: '/export',
          state: {
            background: location,
          },
        })
      }
    >
      <Share />
    </Button.Tertiary>
  );
};

export default ShareToGDrive;
