import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import UserPreferencesState, { DISPLAYED_SECTION, DISPLAY_MODE, SORT_BY } from './Types';

export const initialState: UserPreferencesState = {
  displaySection: DISPLAYED_SECTION.INTERIM,
  cardDisplayMode: DISPLAY_MODE.LIST,
  sortBy: SORT_BY.ALPHABETICAL,
  onMissionOnlyFilter: false,
};

const { reducer, actions } = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    setDisplaySection: (state, action: PayloadAction<DISPLAYED_SECTION>) => {
      state.displaySection = action.payload;
    },
    setCardDisplayMode: (state, action: PayloadAction<DISPLAY_MODE>) => {
      state.cardDisplayMode = action.payload;
    },
    setSortBy: (state, action: PayloadAction<SORT_BY>) => {
      state.sortBy = action.payload;
    },
    setOnMissionOnlyFilter: (state, action: PayloadAction<boolean>) => {
      state.onMissionOnlyFilter = action.payload;
    },
  },
});

export const userPreferencesReducer = reducer;
export const userPreferencesActions = actions;
