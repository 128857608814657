import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { SynchroState } from './Types';

const initialState: SynchroState = {
  lastAbsenceSyncDate: moment().subtract(3, 'month').toISOString(),
  lastActionSyncDate: moment().subtract(3, 'month').toISOString(),
  lastClaimSyncDate: moment().subtract(3, 'month').toISOString(),
};

export const { reducer: synchroReducer, actions: synchroActions } = createSlice({
  name: 'synchro',
  initialState,
  reducers: {
    setLastAbsenceSyncDate: (state, action: PayloadAction<SynchroState['lastAbsenceSyncDate']>) => {
      state.lastAbsenceSyncDate = action.payload;
    },
    setLastActionSyncDate: (state, action: PayloadAction<SynchroState['lastActionSyncDate']>) => {
      state.lastActionSyncDate = action.payload;
    },
    setLastClaimSyncDate: (state, action: PayloadAction<SynchroState['lastClaimSyncDate']>) => {
      state.lastClaimSyncDate = action.payload;
    },
    resetLastSyncDate: () => initialState,
  },
});
