import React from 'react';
import { ServiceWorkerProvider } from 'src/Hooks/ServiceWorker/useServiceWorker';
import { Auth0Provider } from './Auth0';
import { ConnectivityProvider } from './Connectivity';
import { ReactQueryProvider } from './ReactQuery';
import { ReduxProvider } from './Redux';

const Providers = ({ children }: { children: React.ReactElement }) => (
  <Auth0Provider>
    <ServiceWorkerProvider>
      <ReduxProvider>
        <ReactQueryProvider>
          <ConnectivityProvider>{children}</ConnectivityProvider>
        </ReactQueryProvider>
      </ReduxProvider>
    </ServiceWorkerProvider>
  </Auth0Provider>
);

export default Providers;
