import React from 'react';
import SelectCompany from './SelectCompany.component';

import { OwnProps } from './SelectCompany.types';
import { useFetchCompanies } from 'src/Hooks/Companies';
import { toFetchStatus } from 'src/Services/ReactQuery';

const MemoSelectCompany = React.memo(SelectCompany);

const EnhancedSelectCompany = (ownProps: OwnProps) => {
  const companies = useFetchCompanies();
  const companiesFetchStatus = toFetchStatus(companies);

  const _searchCompanies = (searchValue: string) => {
    companies.mutate({
      keyword: searchValue,
      brandCode: ownProps.brandCode,
      agencies: ownProps.agencies,
    });
  };

  return (
    <MemoSelectCompany
      companies={companies.data ?? []}
      fetchStatus={companiesFetchStatus}
      searchCompanies={_searchCompanies}
      {...ownProps}
    />
  );
};

export default EnhancedSelectCompany;
