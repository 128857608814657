import moment from 'moment';
import { ExportType } from 'src/Services/API';
import * as z from 'zod';

export const ExportModalSchema = z
  .object({
    exportTypes: z.array(z.nativeEnum(ExportType)),
    startDate: z.date(),
    endDate: z.date(),
  })
  .refine(
    ({ exportTypes }) => {
      return !!exportTypes && exportTypes.length > 0;
    },
    {
      message: "Vous devez sélectionner au moins un type d'export",
      path: ['exportTypes'],
    }
  )
  .refine(
    ({ startDate, endDate }) => {
      return startDate && endDate && startDate <= endDate;
    },
    {
      message: 'Les dates saisies ne sont pas valides',
      path: ['startDate'],
    }
  )
  .refine(
    ({ startDate, endDate }) => {
      const duration = moment(endDate).diff(moment(startDate), 'months');
      return duration <= 18;
    },
    {
      message: 'Vous ne pouvez pas exporter sur plus de 18 mois',
      path: ['startDate'],
    }
  );
