import { useState } from 'react';
import WorkplaceHeader from 'src/Components/WorkplaceHeader/WorkplaceHeader.component';
import { DISPLAYED_SECTION } from 'src/Redux/UserPreferences/Types';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { menu } from '../SideBar/SideBar.component';
import { displayedComponent } from '../DisplayedSection/DisplayedSection.component';
import styles from './MobileListSection.module.scss';
import { basicTabStyles, basicTabsStyles } from '../MobileTaskSection/MobileTaskSection.styles';
import MobileFiltersMenu from 'src/Containers/MobileFiltersMenu/MobileFiltersMenu.component';
import { useSection } from 'src/Hooks/Section/useSection';
const TITLE = 'listes';

const MobileListSection = () => {
  const [listSection, setListSection] = useState(DISPLAYED_SECTION.INTERIM);
  const section = useSection();
  return (
    <div className={styles.container}>
      <WorkplaceHeader
        title={TITLE}
        rightNode={<MobileFiltersMenu displayedSection={listSection} />}
        doAnimation={true}
      />
      <Tabs
        id="listTabs"
        value={listSection}
        onChange={(_, newValue) => {
          if (newValue !== listSection) {
            setListSection(newValue);
            section?.setScrollTop(0);
          }
        }}
        sx={{ ...basicTabsStyles, marginTop: '0.5rem' }}
      >
        {(menu.find(item => item.title === TITLE)?.subMenu ?? []).map(tab => (
          <Tab key={tab.title} label={tab.title} sx={basicTabStyles} />
        ))}
      </Tabs>
      <div className={styles.separator} />
      {displayedComponent[listSection]}
    </div>
  );
};

export default MobileListSection;
