import moment from 'moment';
import ColorCircle from 'src/Components/ColorCircle';
import {
  COMMUNICATION_MATRIX_ORANGE,
  COMMUNICATION_MATRIX_DARK_GREY,
} from 'src/Containers/AgendaSection/AgendaWeekSection/AgendaWeekSection.component';
import { Props } from './MobileWeeklyTaskCard.types';
import styles from './MobileWeeklyTaskCard.module.scss';
import { Eye } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import SmallLink from 'src/Components/SmallLink';
import { EnumEntityActionTargetType, EnumEntityActionType } from 'src/Services/API';
import { ROUTES } from 'src/Services/Routing';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import ActionMenu from 'src/Containers/AgendaSection/ActionMenu/ActionMenu.component';
import { ROLE } from 'src/Redux/Types';

const MobileWeeklyTaskCard = ({ action }: Props) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <div
      className={classnames(styles.cardContainer, {
        [styles.contactCardContainer]: action.targetType !== EnumEntityActionTargetType.TT,
      })}
    >
      <ColorCircle
        color={
          moment(action.endDate).isBefore(moment())
            ? COMMUNICATION_MATRIX_ORANGE
            : COMMUNICATION_MATRIX_DARK_GREY
        }
      />
      <div className={styles.cardInformationContainer}>
        {action.targetName}
        <p className={styles.report}> {action.report}</p>
        {action.type !== EnumEntityActionType.ACTION_OTHER && (
          <SmallLink
            leftIcon={<Eye />}
            title={'voir détails'}
            className={styles.lastCRContainer}
            onClick={() =>
              history.push({
                pathname: ROUTES.ACTION_VIEW({
                  actionId: action.actionId ?? '',
                  actionType: action.type,
                }),
                state: { background: location },
              })
            }
          />
        )}
        <p className={styles.date}>{`date limite ${moment(action.endDate).format(
          'DD/MM/YYYY à HH:mm'
        )}`}</p>
        {moment(action.endDate).isBefore(moment()) ? (
          <p className={styles.lateBadge}>en retard</p>
        ) : (
          <p className={styles.todoBadge}>à faire</p>
        )}
      </div>
      <ActionMenu
        action={action}
        target={{
          role: ROLE.CONTACT,
          id: action.targetId ?? '',
          name: action.targetName ?? '',
          entryLocalId: action.localId,
        }}
      />
    </div>
  );
};
export default MobileWeeklyTaskCard;
