import { Props } from './CommunicationMatrixInsertionModal.types';

import CommunicationMatrixFormModal from 'src/Components/CommunicationMatrixFormModal';
import { toFetchStatus } from 'src/Services/ReactQuery';
import { useInsertContactInCommunicationMatrix } from 'src/Hooks/CommunicationMatrix';

const CommunicationMatrixInsertionModal = ({ open }: Props) => {
  const { create, mutation } = useInsertContactInCommunicationMatrix();

  return (
    <CommunicationMatrixFormModal
      title="ajouter un interlocuteur à la matrice de communication"
      open={open ?? true}
      fetchStatus={toFetchStatus(mutation)}
      onValidate={entry => create({ ...entry, version: 0 })}
    />
  );
};

export default CommunicationMatrixInsertionModal;
