import React, { useState } from 'react';

import { Props } from './AbsenceActions.types';
import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Pencil,
  Trashcan,
  ThreeDots,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './AbsenceActions.module.scss';
import classnames from 'classnames';
import { LocationState, ROUTES } from 'src/Services/Routing';

const AbsenceActions = ({ candidate, absenceId, absenceLocalId, onDeleteSelected }: Props) => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [isOpened, setIsOpened] = useState(false);
  return (
    <PopupMenu
      position={'left top'}
      width={170}
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      trigger={
        <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpened })}>
          <ThreeDots className={styles.menuIcon} />
        </Button.Tertiary>
      }
    >
      <PopupMenu.Item
        icon={<Pencil />}
        text="modifier"
        onClick={() =>
          history.push({
            pathname: ROUTES.ABSENCE_EDIT,
            state: {
              modalParameters: {
                target: candidate,
                contentId: absenceLocalId,
              },
              background: location.state.background,
            },
          })
        }
      />
      <PopupMenu.Item
        icon={<Trashcan />}
        text="supprimer"
        onClick={() => absenceId && onDeleteSelected(absenceId)}
      />
    </PopupMenu>
  );
};

export default AbsenceActions;
