import { FETCH_STATUS } from 'src/Redux/Types';

export interface QueryStatus {
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  isPaused?: boolean;
}

export const queryStatus = {
  pending: { isSuccess: false, isError: false, isLoading: true },
  success: { isSuccess: true, isError: false, isLoading: false },
};

export function toFetchStatus(query: QueryStatus) {
  if (query.isSuccess) {
    return FETCH_STATUS.FULFILLED;
  }
  if (query.isPaused) {
    // while offline, all mutations are paused: we want to act as if it succeeded
    return FETCH_STATUS.FULFILLED;
  }
  if (query.isLoading) {
    return FETCH_STATUS.PENDING;
  }
  if (query.isError) {
    return FETCH_STATUS.REJECTED;
  }
  return FETCH_STATUS.FULFILLED;
}
