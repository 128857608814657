import React, { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useBackgroundFetch } from 'src/Hooks';
import MainPage from './MainPage.component';

const MemoMainPage = React.memo(MainPage);

const EnhancedMainPage = () => {
  const queryClient = useQueryClient();
  useBackgroundFetch();
  useEffect(() => {
    // We resume any mutation that was made while offline
    queryClient.resumePausedMutations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <MemoMainPage />;
};

export default EnhancedMainPage;
