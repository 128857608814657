import { ROLE } from 'src/Redux/Types';
import { Location } from 'history';
import { actionTypeToRoute } from '../Actions';
import { CandidateStatus, EnumEntityActionType } from '../API';

export interface SingleRouteMap {
  route: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
  backgroundIsOptional?: boolean;
}

export interface Target {
  role: ROLE;
  id: string;
  name: string;
  qualification?: {
    name?: string;
    id?: string;
  };
  service?: {
    name?: string;
    id?: string;
  };
  contact?: {
    name?: string;
    id?: string;
  };
  position?: string;
  entryLocalId?: number;
  candidateStatus?: CandidateStatus;
  firstContractDate?: Date;
  targetGroup?: string;
  contractId?: string;
  isScheduledExit?: boolean;
}

export interface LocationState {
  modalParameters?: {
    target?: Target;
    contentId?: number;
  };
  background: Location<unknown>;
}

export const ROUTES = {
  ACTION_VIEW: ({ actionId, actionType }: { actionId: string; actionType: EnumEntityActionType }) =>
    `${actionTypeToRoute[actionType]}/${actionId}/view`,
  EXCHANGE_EDIT: `${actionTypeToRoute[EnumEntityActionType.ACTION_CONTACT_EXCHANGE]}/edit`,
  EXCHANGE_NEW: `${actionTypeToRoute[EnumEntityActionType.ACTION_CONTACT_EXCHANGE]}/new`,
  EXCHANGE_VIEW: (actionId: string) =>
    ROUTES.ACTION_VIEW({ actionId, actionType: EnumEntityActionType.ACTION_CONTACT_EXCHANGE }),
  CLAIM_EDIT: '/claim/edit',
  CLAIM_NEW: '/claim/new',
  SECURITY_EVENT_NEW: `${actionTypeToRoute[EnumEntityActionType.ACTION_SECURITY_EVENT]}/new`,
  SECURITY_EVENT_EDIT: `${actionTypeToRoute[EnumEntityActionType.ACTION_SECURITY_EVENT]}/edit`,
  SECURITY_EVENT_VIEW: (actionId: string) =>
    ROUTES.ACTION_VIEW({ actionId, actionType: EnumEntityActionType.ACTION_SECURITY_EVENT }),
  PERIMETER_SELECT: '/perimeterselect',
  ABSENCE_EDIT: '/absence/edit',
  ABSENCE_NEW: '/absence/new',
  PRODUCTION_IMPACT_EDIT: `${
    actionTypeToRoute[EnumEntityActionType.ACTION_PRODUCTION_IMPACT]
  }/edit`,
  PRODUCTION_IMPACT_NEW: `${actionTypeToRoute[EnumEntityActionType.ACTION_PRODUCTION_IMPACT]}/new`,
  PRODUCTION_IMPACT_VIEW: (actionId: string) =>
    ROUTES.ACTION_VIEW({ actionId, actionType: EnumEntityActionType.ACTION_PRODUCTION_IMPACT }),
  HISTORY: '/history/:historySection',
  COMMUNICATION_MATRIX_INSERT: '/communicationmatrix/insert',
  COMMUNICATION_MATRIX_EDIT: '/communicationmatrix/edit',
  POOL_EXIT: '/pool_exit',
  DELETE_POOL_EXIT: '/delete_pool_exit',
};
