import { useFetchLastExchange } from 'src/Hooks/CommunicationMatrix';
import { Props } from './MobileCommunicationCard.types';
import { useHistory, useLocation } from 'react-router-dom';
import ColorCircle from 'src/Components/ColorCircle';
import styles from './MobileCommunicationCard.module.scss';
import {
  COMMUNICATION_MATRIX_GREEN,
  COMMUNICATION_MATRIX_GREY,
} from 'src/Containers/AgendaSection/AgendaWeekSection/AgendaWeekSection.component';
import { frequencyUnitEnumMap } from 'src/Services/Transformers/communicationMatrix';
import { EnumCommunicationMatrixEntryFrequencyUnit } from 'src/Services/API';
import moment from 'moment';
import { Eye, WhiteCheck } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import SmallLink from 'src/Components/SmallLink';
import { ROUTES } from 'src/Services/Routing';
import CommunicationMatrixMenu from 'src/Containers/AgendaSection/CommunicationMatrixMenu/CommunicationMatrixMenu.component';
import { ROLE } from 'src/Redux/Types';
import { useMemo } from 'react';

const MobileCommunicationCard = ({ contact }: Props) => {
  const lastExchange = useFetchLastExchange(contact.contactId);
  const history = useHistory();
  const location = useLocation();
  const progression = useMemo(
    () =>
      (contact.frequencyUnit === EnumCommunicationMatrixEntryFrequencyUnit.YEARLY
        ? contact.yearProgression
        : contact.frequencyUnit === EnumCommunicationMatrixEntryFrequencyUnit.PER_SEMESTER
        ? contact.semesterProgression
        : contact.frequencyUnit === EnumCommunicationMatrixEntryFrequencyUnit.MONTHLY
        ? contact.monthProgression
        : contact.weekProgression) ?? 0,
    [contact]
  );

  return (
    <div className={styles.cardContainer}>
      <ColorCircle
        color={
          progression >= contact.frequency ? COMMUNICATION_MATRIX_GREEN : COMMUNICATION_MATRIX_GREY
        }
      />
      <div className={styles.cardInformationContainer}>
        <span>{`${contact?.contactFirstName} ${contact?.contactLastName}`}</span>
        <p className={styles.position}>{contact?.contactPosition}</p>
        <p className={styles.frequency}>
          {`${contact.frequency} fois/${frequencyUnitEnumMap[contact.frequencyUnit]}`}
        </p>
        <p className={styles.frequency}>
          {`dernière visite : ${
            lastExchange?.startDate &&
            moment(lastExchange?.startDate).format('DD/MM/YYYY [à] HH:mm')
          }`}
        </p>
        <div className={styles.badges}>
          {lastExchange && (
            <SmallLink
              leftIcon={<Eye />}
              className={styles.lastCRContainer}
              title={'dernier CR'}
              onClick={() =>
                history.push({
                  pathname: ROUTES.EXCHANGE_VIEW(lastExchange.actionId ?? ''),
                  state: { background: location },
                })
              }
            />
          )}
          {progression < contact.frequency ? (
            <p className={styles.progressionCell}>
              {`${progression} visite${progression > 1 ? 's' : ''} / ${contact.frequency} prévue${
                contact.frequency > 1 ? 's' : ''
              }`}
            </p>
          ) : (
            <p className={styles.greenCheck}>
              <WhiteCheck />
            </p>
          )}
        </div>
      </div>
      <CommunicationMatrixMenu
        target={{
          role: ROLE.CONTACT,
          id: contact.contactId,
          name: [contact.contactFirstName, contact.contactLastName].join(' '),
          entryLocalId: contact.localId,
        }}
      />
    </div>
  );
};
export default MobileCommunicationCard;
