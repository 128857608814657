import React from 'react';
import FiltersMenu from 'src/Components/FiltersMenu/FiltersMenu.component';
import Grid from 'src/Components/Grid';
import List from 'src/Components/List';
import { Element } from 'react-scroll';

import styles from './CompanyServices.module.scss';
import AlphabetPicker from 'src/Components/AlphabetPicker';
import { Props } from './CompanyServices.types';
import WorkplaceHeader from 'src/Components/WorkplaceHeader';
import { useIsFetching } from '@tanstack/react-query';
import Loader from 'src/Components/Loader';
import { DISPLAY_MODE } from 'src/Redux/UserPreferences/Types';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { useSection } from 'src/Hooks/Section/useSection';

const CompanyServices = ({ contacts, cardDisplayMode }: Props) => {
  const ItemRenderer = cardDisplayMode === DISPLAY_MODE.GRID ? Grid : List;
  const selectedCompany = useSelector(getCurrentCompany);

  const isFetchingContacts = useIsFetching(['fetchCompanyCandidates']) > 0;
  const isFetchingCandidates = useIsFetching(['fetchCompanyContacts']) > 0;
  const isFetching = isFetchingCandidates || isFetchingContacts;
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  const titleAdditionalText = selectedCompany
    ? `chez ${selectedCompany?.companyName} - ${selectedCompany?.companyAddress?.postalCode} (${selectedCompany?.siret})`
    : '';
  const section = useSection();

  return (
    <div className={classnames(styles.container, { [styles.mobileContainer]: isMobile })}>
      {!isMobile && (
        <div className={styles.headerSection}>
          <WorkplaceHeader
            title={`trouver par services ${titleAdditionalText}`}
            comment="Trouver un interlocuteur ou un intérimaire suivant son service d'affectation"
            rightNode={<FiltersMenu />}
          />
        </div>
      )}

      {contacts.length === 0 && isFetching ? (
        <Loader heightInRem={4} />
      ) : (
        <div className={classnames(styles.contactBook, { [styles.mobileBook]: isMobile })}>
          <div className={styles.contactBookInnerContent}>
            <Element
              id="listScrollableContainer"
              name="listScrollableContainer"
              className={styles.contactList}
              onScroll={() => {
                section?.setScrollTop(
                  document.getElementById('listScrollableContainer')?.scrollTop ?? 0
                );
              }}
            >
              {contacts && <ItemRenderer items={contacts} />}
            </Element>
            <div
              className={classnames(styles.alphabetPickerContainer, {
                [styles.mobileAlphabetPickerContainer]: isMobile,
              })}
            >
              <AlphabetPicker />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyServices;
