import React from 'react';

import styles from './ExchangePreviewModal.module.scss';
import { Button, Modal, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { HeaderFinAction } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useParams } from 'react-router-dom';
import { useFetchActionWithActionId } from 'src/Hooks/Actions';
import { useFetchCompanyContact } from 'src/Hooks/Contacts';
import moment from 'moment';
import { useFetchCompanyCandidate } from 'src/Hooks/Candidates';
import { EnumEntityActionType } from 'src/Services/API';

const ExchangePreviewModal = () => {
  const history = useHistory();
  const { actionId } = useParams<{ actionId: string }>();
  const exchange = useFetchActionWithActionId(actionId ?? '');
  const targetContact = useFetchCompanyContact(exchange?.targetId ?? '');
  const targetCandidate = useFetchCompanyCandidate(exchange?.targetId ?? '');

  return (
    <Modal
      title={`échange avec l'${
        exchange?.type === EnumEntityActionType.ACTION_CANDIDATE_EXCHANGE
          ? 'intérimaire'
          : 'interlocuteur'
      }`}
      subtitle={
        exchange?.type === EnumEntityActionType.ACTION_CANDIDATE_EXCHANGE
          ? [targetCandidate?.firstName, targetCandidate?.name].join(' ')
          : [targetContact?.firstName, targetContact?.name].join(' ')
      }
      onClose={() => history.goBack()}
      icon={
        <div className={styles.modalIcon}>
          <HeaderFinAction />
        </div>
      }
      open
      footerActionsRight={[
        <Button.Primary onClick={() => history.goBack()}>fermer</Button.Primary>,
      ]}
    >
      <div className={styles.container}>
        <WithLightTitle title={`CR du ${moment(exchange?.creationDate).format('DD/MM/YYYY')}`}>
          {exchange?.report}
        </WithLightTitle>
        {exchange?.endDate && (
          <WithLightTitle title="planification">
            {moment(exchange.endDate).format('[le] DD/MM/YYYY [à] HH:mm')}
          </WithLightTitle>
        )}
      </div>
    </Modal>
  );
};

export default ExchangePreviewModal;
