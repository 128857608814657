import React from 'react';

import styles from './UnderContructionSection.module.scss';
import { UnderConstruction } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';
import classnames from 'classnames';

const UnderContructionSection = () => {
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <UnderConstruction className={classnames({ [styles.mobileImage]: isMobile })} />
      </div>
      <div className={styles.title}>Page en construction.</div>
      <div className={styles.title}>Bientôt sur vos écrans…</div>
    </div>
  );
};

export default UnderContructionSection;
