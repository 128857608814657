import { CandidatesService, ContactsService, CreateClaimBody, TargetType } from '../API';
import { db } from '../DB';

export const createClaim = async ({
  localId,
  brandCode,
  agencyId,
  companyId,
}: {
  localId: number;
  brandCode: string;
  agencyId: string;
  companyId: string;
}) => {
  const claim = await db.claims.get(localId);
  if (claim === undefined) {
    throw new Error(`Claim ${localId} does not exist in local DB`);
  }
  const { category, reason, ...rest } = claim;
  delete rest.localId;

  const body: CreateClaimBody = {
    brandCode,
    agencyId,
    companyId,
    opening: claim.opening,
    closing: claim.closing,
    categoryId: category?.id ?? '',
    reasonId: reason?.id ?? '',
    candidateId: claim.candidate?.id,
    customerContactId: claim.customerContact?.id,
    comment: claim.comment,
  };

  return await (claim.type === TargetType.TT
    ? CandidatesService.candidatesControllerCreateCandidateClaim({
        id: claim.candidate?.id ?? '',
        body,
      })
    : ContactsService.contactsControllerCreateContactClaim({
        id: claim.customerContact?.id ?? '',
        body,
      }));
};

export const updateClaim = async ({
  localId,
  brandCode,
  agencyId,
  companyId,
}: {
  localId: number;
  brandCode: string;
  agencyId: string;
  companyId: string;
}) => {
  const claim = await db.claims.get(localId);

  if (claim === undefined) {
    throw new Error(`Claim ${localId} does not exist in local DB`);
  }

  const { category, reason, ...rest } = claim;
  delete rest.localId;

  const params = {
    id: claim.candidate?.id ?? claim.customerContact?.id ?? '',
    body: {
      ...rest,
      brandCode,
      agencyId,
      companyId,
      categoryId: category?.id ?? '',
      reasonId: reason?.id ?? '',
      claimId: claim.claimId ?? '',
    },
  };

  return await (claim.type === TargetType.TT
    ? CandidatesService.candidatesControllerUpdateCandidateClaim(params)
    : ContactsService.contactsControllerUpdateContactClaim(params));
};
