import { useLiveQuery } from 'dexie-react-hooks';
import { UseQueryOptions } from '@tanstack/react-query';
import { db } from 'src/Services/DB';
import { CommunicationMatrixEntryLight } from './types';
import { useBackgroundFetchCommunicationMatrix } from './useBackgroundFetchCommunicationMatrix';

export const useFetchCommunicationMatrixContacts = (
  extraOptions?: UseQueryOptions<unknown, unknown, CommunicationMatrixEntryLight[]>
) => {
  useBackgroundFetchCommunicationMatrix(extraOptions);
  const communicationMatrix = useLiveQuery(() => db.communicationMatrix.toArray()) ?? [];

  return communicationMatrix.map(entry => entry.contactId);
};
