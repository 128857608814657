import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';
import nullify from 'src/Utils/nullify';

import { Props } from './SelectCompany.types';

const SelectCompany = ({
  companies,
  selectedCompany,
  fetchStatus,
  setSelectedCompany,
  searchCompanies,
}: Props) => (
  <ComboBox
    id="selectCompany"
    value={selectedCompany ?? null}
    searchResults={companies}
    keyValueExtractor={({ companyId, companyName, siret, companyAddress }) => ({
      key: companyId ?? 'unknown',
      value: `${companyName} (${siret})`,
      subValue: `${companyAddress?.postalCode} - ${companyAddress?.city}`,
    })}
    placeholder="Aucun compte séléctionné"
    onSearch={searchCompanies}
    onChange={nullify(setSelectedCompany)}
    fetchStatus={fetchStatus}
    minLengthToSearch={0}
    withSubLabel
  />
);

export default SelectCompany;
