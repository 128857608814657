import React from 'react';
import CompanyContacts from './CompanyContacts.component';
import ListItemActions from 'src/Containers/ListItemActions';
import { ROLE } from 'src/Redux/Types';
import { useFetchCompanyContacts } from 'src/Hooks/Contacts';
import { useSelector } from 'react-redux';
import { getCardDisplayMode } from 'src/Redux/UserPreferences/Selectors';

const MemoCompanyContacts = React.memo(CompanyContacts);

const EnhancedCompanyContacts = () => {
  const rawContacts = useFetchCompanyContacts();
  const cardDisplayMode = useSelector(getCardDisplayMode);
  const contacts = rawContacts.map(contact => ({
    contactDetail: {
      title: `${contact.name} ${contact.firstName}` ?? '',
      subtitle:
        contact.services?.find(service => service.name !== undefined && service.name !== '')
          ?.name ?? '',
      description: contact.position ?? '',
      headerRight: (
        <ListItemActions
          target={{
            role: ROLE.CONTACT,
            id: contact.contactId ?? '',
            name: `${contact.name} ${contact.firstName}`,
            position: contact.position,
          }}
        />
      ),
    },
    contactRole: ROLE.CONTACT,
  }));
  return <MemoCompanyContacts contacts={contacts} cardDisplayMode={cardDisplayMode} />;
};

export default EnhancedCompanyContacts;
