import React from 'react';

import { Props } from './Callback.types';
import styles from './Callback.module.scss';
import Loader from 'src/Components/Loader';
import { FETCH_STATUS } from 'src/Redux/Types';
import { redCloud } from 'src/Assets';

const Callback = ({ fetchStatus }: Props) => (
  <div className={styles.container}>
    <div className={styles.modal}>
      {fetchStatus === FETCH_STATUS.REJECTED ? (
        <>
          <p className={styles.text}>
            Impossible de récupérer
            <br /> votre périmètre !
          </p>
          <img src={redCloud} alt="perimeter-error" />
        </>
      ) : (
        <>
          <p className={styles.text}>
            Récupération de votre
            <br /> périmètre en cours
          </p>
          <Loader heightInRem={4} />
        </>
      )}
    </div>
  </div>
);

export default Callback;
