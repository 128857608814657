import React, { useCallback } from 'react';
import ExchangeEditionModal from './ExchangeEditionModal.component';
import { OwnProps } from './ExchangeEditionModal.types';
import { toFetchStatus } from 'src/Services/ReactQuery';
import { useUpdateAction } from 'src/Hooks/Actions';
import { EntityAction } from 'src/Services/API';
import { Local } from '../../Services/DB';

const MemoExchangeEditionModal = React.memo(ExchangeEditionModal);

const EnhancedExchangeEditionModal = (props: OwnProps) => {
  const updater = useUpdateAction();
  const fetchStatus = toFetchStatus(updater.mutation);
  const onValidate = useCallback(
    (exchange: Local<EntityAction>) => {
      updater.update(exchange);
    },
    [updater]
  );
  return (
    <MemoExchangeEditionModal
      fetchStatus={fetchStatus}
      modalParameters={props.modalParameters}
      onValidate={onValidate}
    />
  );
};

export default EnhancedExchangeEditionModal;
