import React, { useCallback, useEffect, useState } from 'react';
import SelectClaimReason from './SelectClaimReason.component';
import { OwnProps } from './SelectClaimReason.types';

const MemoSelectClaimReason = React.memo(SelectClaimReason);

const EnhancedSelectClaimReason = (ownProps: OwnProps) => {
  const [reasons, setReasons] = useState(ownProps.selectedClaimCategory?.reasons);
  useEffect(() => {
    setReasons(ownProps.selectedClaimCategory?.reasons);
  }, [ownProps.selectedClaimCategory]);
  const getClaimReasons = useCallback(
    (searchValue: string) => {
      setReasons(
        ownProps.selectedClaimCategory?.reasons?.filter(reason =>
          reason.label?.toUpperCase().includes(searchValue.toUpperCase())
        )
      );
    },
    [ownProps.selectedClaimCategory?.reasons]
  );
  return (
    <MemoSelectClaimReason claimReasons={reasons} getClaimReasons={getClaimReasons} {...ownProps} />
  );
};

export default EnhancedSelectClaimReason;
