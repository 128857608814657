import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { Props } from './ProductionImpactCreationModal.types';
import styles from './ProductionImpactCreationModal.module.scss';
import { useHistory } from 'react-router-dom';
import {
  Button,
  DatePickerInput,
  FetchButton,
  Modal,
  TextArea,
  TextInput,
  WithLightTitle,
  Animation,
} from '@randstad-lean-mobile-factory/react-components-core';
import SelectProductionImpactType from '../Selects/SelectProductionImpactType';
import {
  OtherProductionImpactType,
  ProductionImpactType,
} from '../Selects/SelectProductionImpactType/SelectProductionImpactType.types';
import moment from 'moment';
import { singleTextInputUnroll } from 'src/Services/Animations';
import { CroppedTarget } from 'src/Assets';
import { EnumEntityActionStatus, EnumEntityActionType } from 'src/Services/API';

const ProductionImpactCreationModal = ({
  modalParameters,
  open,
  fetchStatus,
  onValidate,
}: Props) => {
  const [productionImpactType, setProductionImpactType] = useState<
    ProductionImpactType | undefined
  >(undefined);
  const [customProductionImpact, setCustomProductionImpact] = useState('');
  const [impactDate, setImpactDate] = useState(moment());
  const [commentary, setCommentary] = useState('');

  const reset = useCallback(() => {
    setProductionImpactType(undefined);
    setCustomProductionImpact('');
    setImpactDate(moment());
    setCommentary('');
  }, []);

  const target = modalParameters?.target;

  const history = useHistory();
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (target === undefined) {
      history?.push('/');
    }
  }, [target, history]);

  return (
    <Modal
      open={open ?? true}
      title="impact production"
      onClose={() => {
        onClose();
      }}
      icon={
        <div className={styles.modalIcon}>
          <CroppedTarget />
        </div>
      }
      footerActionsLeft={[<Button.Tertiary onClick={reset}>tout réinitialiser</Button.Tertiary>]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          fetchStatus={fetchStatus}
          errorTitle="réessayer"
          disabled={
            productionImpactType === undefined ||
            (productionImpactType === OtherProductionImpactType.other &&
              customProductionImpact === '') ||
            commentary === ''
          }
          onClick={() => {
            const subType =
              productionImpactType === OtherProductionImpactType.other
                ? customProductionImpact
                : productionImpactType;
            onValidate({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              targetId: target!.id,
              type: EnumEntityActionType.ACTION_PRODUCTION_IMPACT,
              report: commentary,
              subType,
              startDate: impactDate.toDate(),
              endDate: impactDate.toDate(),
              status: impactDate.isBefore(moment().endOf('day'))
                ? EnumEntityActionStatus.FINISHED
                : EnumEntityActionStatus.IN_PROGRESS,
            });
          }}
          onSuccess={onClose}
        />,
      ]}
    >
      <div className={styles.typesSelector}>
        <div className={styles.line}>
          <WithLightTitle title="type d'impact">
            <SelectProductionImpactType
              selection={productionImpactType}
              onSelectionChange={setProductionImpactType}
              className={classnames({
                [styles.searchInputContainer]:
                  productionImpactType === OtherProductionImpactType.other,
              })}
            />
            <Animation.Unroll
              visible={productionImpactType === OtherProductionImpactType.other}
              config={singleTextInputUnroll}
            >
              <TextInput
                containerClassName={styles.textInputContainer}
                value={customProductionImpact}
                onChange={event =>
                  setCustomProductionImpact((event.target as HTMLInputElement).value)
                }
              />
            </Animation.Unroll>
          </WithLightTitle>
        </div>
        <div className={styles.line}>
          <WithLightTitle title="à la date du">
            <DatePickerInput
              date={impactDate.toDate()}
              onSelectDate={date => setImpactDate(moment(date))}
            />
          </WithLightTitle>
        </div>
        <div className={styles.line}>
          <WithLightTitle title="commentaire">
            <TextArea
              className={styles.textArea}
              value={commentary}
              placeholder="apportez des précisions sur l'impact..."
              maxLength={950}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                setCommentary(event.target.value)
              }
            />
          </WithLightTitle>
        </div>
      </div>
    </Modal>
  );
};

export default ProductionImpactCreationModal;
