export enum FETCH_STATUS {
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export enum ROLE {
  CANDIDATE,
  CONTACT,
}
