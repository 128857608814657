import React from 'react';

import { Props } from './SelectClaimReason.types';
import { DropDown } from '@randstad-lean-mobile-factory/react-components-core';
import { OSMRepositoriesModelClaimCategoryRepositoryDtoClaimReasonDto } from 'src/Services/API';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';
import classNames from 'classnames';
import styles from './SelectClaimReason.module.scss';
const SelectClaimReason = ({
  claimReasons,
  selectedClaimReason,
  onClaimReasonSelect,
  disabled,
}: Props) => {
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  return (
    <DropDown
      placeholder="sélectionnez"
      childrenClassName={classNames({ [styles.mobileDropDownChildren]: isMobile })}
      disabled={disabled}
      items={claimReasons ?? []}
      selectedItem={selectedClaimReason}
      onSelectItem={onClaimReasonSelect}
      keyValueExtractor={(
        searchResult: OSMRepositoriesModelClaimCategoryRepositoryDtoClaimReasonDto
      ) => ({
        key: searchResult.id ?? '',
        value: searchResult.label ?? '',
      })}
    />
  );
};

export default SelectClaimReason;
