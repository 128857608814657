import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { IdLabel, RepositoriesService } from 'src/Services/API';
import { QueryKeys } from '..';

const useFetchPoolMotivations = (
  extraOptions?: UseQueryOptions<IdLabel[], unknown, IdLabel[], QueryKeys[]>
) => {
  return useQuery(
    [QueryKeys.FETCH_POOL_MOTIVATOIONS],
    async () => {
      return await RepositoriesService.repositoriesControllerGetPoolExitMotivations();
    },
    { refetchOnWindowFocus: false, ...extraOptions }
  );
};

export default useFetchPoolMotivations;
