import React from 'react';

import { Props } from './ProductionImpactHistory.types';
import styles from './ProductionImpactHistory.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationState, ROUTES } from 'src/Services/Routing';
import { useFetchTargetActions } from 'src/Hooks/Actions';
import { EnumEntityActionStatus, EnumEntityActionType } from 'src/Services/API';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import HistoryCard from 'src/Components/HistoryCard';
import AutoSizer from 'react-virtualized-auto-sizer';
import moment from 'moment';
import { FixedSizeList } from 'react-window';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';

const ProductionImpactHistory = ({ target }: Props) => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const productionImpacts = useFetchTargetActions({
    type: EnumEntityActionType.ACTION_PRODUCTION_IMPACT,
    targetId: target?.id ?? '',
  });
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  return (
    <div className={styles.container}>
      <Button.Primary
        className={styles.button}
        onClick={() =>
          history.push({
            pathname: ROUTES.PRODUCTION_IMPACT_NEW,
            state: {
              modalParameters: { target: target },
              background: location.state.background,
            },
          })
        }
      >
        ajouter un impact de production
      </Button.Primary>
      <div
        className={
          isMobile ? styles.mobileProductionInpactsContainer : styles.productionImpactsContainer
        }
      >
        {productionImpacts.length === 0 ? (
          <div className={styles.noResult}>Aucun échange pour cet interlocuteur</div>
        ) : (
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <FixedSizeList
                itemCount={productionImpacts.length}
                width={width}
                className={styles.list}
                height={height}
                itemSize={isMobile ? 128 : 100}
              >
                {({ index, style }) => {
                  const productionImpact = productionImpacts[index];
                  return (
                    <div style={style} className={styles.cardContainer}>
                      <HistoryCard
                        title={`date début ${moment(productionImpact.startDate).format(
                          'L'
                        )} - fin le ${moment(productionImpact.endDate).format('L')}`}
                        subtitle={productionImpact.report}
                        secondSubtitle={
                          productionImpact.status === EnumEntityActionStatus.FINISHED
                            ? 'terminé'
                            : 'en cours'
                        }
                        onClick={() =>
                          history.push({
                            pathname: ROUTES.PRODUCTION_IMPACT_EDIT,
                            state: {
                              modalParameters: {
                                target: target,
                                contentId: productionImpact.localId,
                              },
                              background: location.state.background,
                            },
                          })
                        }
                      />
                    </div>
                  );
                }}
              </FixedSizeList>
            )}
          </AutoSizer>
        )}
      </div>
    </div>
  );
};

export default ProductionImpactHistory;
