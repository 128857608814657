import { useLiveQuery } from 'dexie-react-hooks';
import moment from 'moment';
import ReactGA from 'react-ga4';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { MutationKeys, QueryKeys } from 'src/Hooks';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { getLastClaimSyncDate } from 'src/Redux/Synchro/Selectors';
import { synchroActions } from 'src/Redux/Synchro/Slice';
import { ClaimCategory, ClaimEntity, ClaimsService, CompaniesService } from 'src/Services/API';
import { createClaim, updateClaim } from 'src/Services/Claims';
import { db, Local } from 'src/Services/DB';
import { queryStatus } from 'src/Services/ReactQuery/index';
import { usePerimeter } from '../Perimeter';

export const claimMutationsConfig = {
  'claims/create': {
    mutationFn: createClaim,
    onSuccess: async (claim: ClaimEntity, { localId }: Parameters<typeof createClaim>[0]) => {
      await db.claims.update(localId, claim);
    },
    onError: async (_: unknown, { localId }: Parameters<typeof createClaim>[0]) => {
      await db.claims.delete(localId);
    },
    // Without a retry, mutations done while offline will fail instead of being paused
    retry: 1,
  },
  'claims/update': {
    mutationFn: updateClaim,
    onSuccess: async (claim: ClaimEntity, { localId }: Parameters<typeof updateClaim>[0]) => {
      await db.claims.update(localId, claim);
    },
    // Without a retry, mutations done while offline will fail instead of being paused
    retry: 1,
  },
};

export const useCreateClaim = () => {
  const { brandCode, agencyId, companyId } = usePerimeter();
  const { zoneId, regionId } = useSelector(getAnalyticsUserInfo);
  const mutation = useMutation(
    [MutationKeys.CLAIMS_CREATION],
    claimMutationsConfig['claims/create']
  );
  const queryClient = useQueryClient();

  return {
    create: async (data: ClaimEntity) => {
      const localId = await db.claims.add(data);
      ReactGA.event('Création de réclamation', {
        brandCode,
        agencyId,
        zoneId,
        regionId,
      });
      mutation.mutate({ localId, brandCode, agencyId, companyId: companyId ?? '' });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_CLAIMS] });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_CANDIDATE_CLAIMS] });
    },
    mutation,
  };
};

export const useUpdateClaim = () => {
  const { brandCode, agencyId, companyId } = usePerimeter();
  const mutation = useMutation([MutationKeys.CLAIMS_UPDATE], claimMutationsConfig['claims/update']);
  const { zoneId, regionId } = useSelector(getAnalyticsUserInfo);
  const queryClient = useQueryClient();
  return {
    update: async (data: Local<ClaimEntity>) => {
      await db.claims.where({ localId: data.localId }).modify(data);
      const claim = await db.claims.where({ localId: data.localId }).first();
      if (claim === undefined) {
        throw new Error(`Claim with localId ${data.localId} does not exist in local DB`);
      }

      const mutationCache = queryClient.getMutationCache().getAll();

      if (
        mutationCache.findIndex(
          mutation =>
            (mutation.options?.mutationKey?.includes(MutationKeys.CLAIMS_UPDATE) ||
              mutation.options?.mutationKey?.includes(MutationKeys.CLAIMS_CREATION)) &&
            mutation.options?.variables?.['localId'] === data?.localId &&
            mutation.state.status === 'loading'
        ) !== -1
      ) {
        return queryStatus.success;
      }

      ReactGA.event("Mise à jour d'une réclamation", {
        brandCode,
        agencyId,
        zoneId,
        regionId,
      });

      mutation.mutate({
        localId: claim.localId ?? 0,
        brandCode,
        agencyId,
        companyId: companyId ?? '',
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_CLAIMS] });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_CANDIDATE_CLAIMS] });
    },
    mutation,
  };
};

export const useBackgroundFetchClaimCategories = (
  extraOptions?: UseQueryOptions<unknown, unknown, ClaimCategory[]>
) => {
  const { brandCode } = usePerimeter();
  useQuery<unknown, unknown, ClaimCategory[]>(
    [QueryKeys.FETCH_CLAIM_CATEGORIES, brandCode],
    async () => {
      const claimCategories = await ClaimsService.claimsControllerGetClaimCategories({
        brandCode: brandCode ?? '',
      });

      await db.transaction('rw', db.claimCategories, async () => {
        await db.claimCategories.clear();
        await db.claimCategories.bulkPut(claimCategories);
      });

      return claimCategories;
    },
    {
      staleTime: 300000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      ...extraOptions,
    }
  );
};

export const useFetchClaimCategories = (
  extraOptions?: UseQueryOptions<unknown, unknown, ClaimCategory[]>
) => {
  useBackgroundFetchClaimCategories(extraOptions);
  const claimCategories = useLiveQuery(() => db.claimCategories.toArray()) ?? [];
  return claimCategories;
};

export const useBackgroundCleanOldClaims = () => {
  useQuery([QueryKeys.CLEAN_OLD_CLAIMS], async () => {
    await db.claims.where('opening').below(moment().subtract(3, 'month').toDate()).delete();
  });
};

export const useBackgroundFetchClaims = (
  extraOptions?: UseQueryOptions<unknown, unknown, ClaimEntity[]>
) => {
  const dispatch = useDispatch();
  const synchroStartTime = moment().toDate();
  const lastSynchro = useSelector(getLastClaimSyncDate);
  const { brandCode, companyId, agencyId } = usePerimeter();
  useQuery<unknown, unknown, ClaimEntity[]>(
    [QueryKeys.FETCH_CLAIMS, brandCode, companyId, moment(lastSynchro).format('LLL')],
    async () => {
      const claims = await CompaniesService.companiesControllerGetCompaniesClaims({
        id: companyId ?? '',
        brandCode: brandCode ?? '',
        modifiedSince: moment(lastSynchro).format('yyyy-MM-DD'),
        startingDate: moment().subtract('1', 'years').format('yyyy-MM-DD'),
        endingDate: moment().add('1', 'years').format('yyyy-MM-DD'),
        agencyId,
      });

      await db.transaction('rw', db.claims, async () => {
        await db.claims
          .where('claimId')
          .anyOf(
            claims
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              .map(claim => claim.claimId!)
          )
          .delete();
        await db.claims.bulkAdd(claims);
      });
      dispatch(synchroActions.setLastClaimSyncDate(synchroStartTime.toISOString()));

      return claims;
    },
    {
      staleTime: 300000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: agencyId !== undefined && companyId !== undefined,
      ...extraOptions,
    }
  );
};

export const useFetchCandidateClaims = (
  candidateId: string,
  companyId: string,
  extraOptions?: UseQueryOptions<unknown, unknown, Local<ClaimEntity>[]>
) => {
  return useQuery<unknown, unknown, Local<ClaimEntity>[]>(
    [QueryKeys.FETCH_CANDIDATE_CLAIMS, candidateId],
    async () => {
      const candidateClaims = await db.claims
        .where('[candidate.id+companyId]')
        .equals([candidateId, companyId])
        .toArray();
      return candidateClaims ?? [];
    },
    { ...extraOptions, enabled: candidateId !== undefined }
  );
};

export const useFetchContactClaims = (
  contactId: string,
  companyId: string,
  extraOptions?: UseQueryOptions<unknown, unknown, Local<ClaimEntity>[]>
) => {
  return useQuery<unknown, unknown, Local<ClaimEntity>[]>(
    [QueryKeys.FETCH_CONTACT_CLAIMS, contactId],
    async () => {
      const candidateClaims = await db.claims
        .where('[customerContact.id+companyId]')
        .equals([contactId, companyId])
        .toArray();
      return candidateClaims ?? [];
    },
    { ...extraOptions, enabled: contactId !== undefined }
  );
};
