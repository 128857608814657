import React, { useState } from 'react';

import { Props } from './HideableSection.types';
import styles from './HideableSection.module.scss';

import { Animation, Button } from '@randstad-lean-mobile-factory/react-components-core';
import { MinusClose, PlusOpen } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { animated, useTransition } from 'react-spring';
import classnames from 'classnames';

function ToggleIcon({ open }: { open: boolean }) {
  const transitions = useTransition(open, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  return (
    <div className={styles.subMenuIcon}>
      {transitions.map(({ item, key, props }) =>
        item ? (
          <animated.div key={key} style={props}>
            <MinusClose />
          </animated.div>
        ) : (
          <animated.div key={key} style={props}>
            <PlusOpen />
          </animated.div>
        )
      )}
    </div>
  );
}

const HideableSection = ({ title, children, defaultOpenState, dataReady }: Props) => {
  const [open, setOpen] = useState(defaultOpenState);

  return (
    <div className={styles.container}>
      <div className={styles.firstLine}>
        <div className={styles.title}>{title}</div>
        <Button.Tertiary className={styles.button} onClick={() => setOpen(!open)}>
          <ToggleIcon open={open} />
        </Button.Tertiary>
      </div>
      <div className={styles.colorBars}>
        <div className={styles.yellowBar} />
        {!open && <div className={styles.greyBar} />}
      </div>
      <div className={classnames({ [styles.unroll]: open })}>
        <Animation.Unroll visible={open} noOpacityFade immediate={!dataReady}>
          <div className={styles.hideableContainer}>{children}</div>
        </Animation.Unroll>
      </div>
    </div>
  );
};

export default HideableSection;
