import React, { useCallback } from 'react';
import { useCreateAction } from 'src/Hooks/Actions';
import { EntityAction } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';
import ProductionImpactCreationModal from './ProductionImpactCreationModal.component';
import { OwnProps } from './ProductionImpactCreationModal.types';
import { Local } from '../../Services/DB';

const MemoProductionImpactCreationModal = React.memo(ProductionImpactCreationModal);

const EnhancedProductionImpactCreationModal = (props: OwnProps) => {
  const creator = useCreateAction();
  const fetchStatus = toFetchStatus(creator.mutation);
  const onValidate = useCallback(
    (productionImpact: Local<EntityAction>) => {
      creator.create(productionImpact);
    },
    [creator]
  );

  return (
    <MemoProductionImpactCreationModal
      fetchStatus={fetchStatus}
      onValidate={onValidate}
      {...props}
    />
  );
};

export default EnhancedProductionImpactCreationModal;
