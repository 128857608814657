import { classToPlain } from 'class-transformer';
import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { companiesActions } from 'src/Redux/Companies/Slice';
import { CompaniesState } from 'src/Redux/Companies/Types';
import {
  getCompanies,
  getCurrentAgency,
  getCurrentBrand,
  getDefaultAgency,
  getDefaultBrand,
} from 'src/Redux/Perimeter/Selectors';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';
import { PerimeterState } from 'src/Redux/Perimeter/Types';
import { synchroActions } from 'src/Redux/Synchro/Slice';
import PerimeterSelectionModal from './PerimeterSelectionModal.component';
import { db } from 'src/Services/DB';
import { useResetPerimeter } from 'src/Hooks/Perimeter';

const MemoPerimeterSelectionModal = React.memo(PerimeterSelectionModal);
const EnhancedPerimeterSelectionModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // Perimeter Selection
  const selectedAgency = useSelector(getCurrentAgency);
  const selectedBrand = useSelector(getCurrentBrand);
  const selectedCompany = useSelector(getCurrentCompany);

  const brands = useSelector(getCompanies);

  const defaultAgency = useSelector(getDefaultAgency);
  const defaultBrand = useSelector(getDefaultBrand);

  const resetPerimeter = useResetPerimeter();

  const onValidate = useCallback(
    async (
      brand: PerimeterState['currentBrand'],
      agency: PerimeterState['currentAgency'],
      company: CompaniesState['currentCompany']
    ) => {
      if (brand !== selectedBrand || agency !== selectedAgency || company !== selectedCompany) {
        await db.candidates.clear();
        await db.contacts.clear();
        await db.communicationMatrix.clear();
        await db.actions.clear();
      }
      //Removing all queries from cache ensures we only refetch the active ones
      await queryClient.removeQueries();
      resetPerimeter();
      dispatch(perimeterActions.setCurrentBrand(classToPlain(brand)));
      dispatch(perimeterActions.setCurrentAgency(agency));
      dispatch(companiesActions.setCurrentCompany(classToPlain(company)));
      dispatch(synchroActions.resetLastSyncDate());
    },
    [dispatch, queryClient, selectedBrand, selectedAgency, selectedCompany, resetPerimeter]
  );

  return (
    <MemoPerimeterSelectionModal
      selectedBrand={selectedBrand ?? brands?.find(brand => brand.societyId === defaultBrand)}
      selectedAgency={selectedAgency !== '' ? selectedAgency : defaultAgency}
      selectedCompany={selectedCompany}
      onValidate={onValidate}
    />
  );
};

export default EnhancedPerimeterSelectionModal;
