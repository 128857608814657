import React, { useState } from 'react';

import styles from './SideBar.module.scss';
import { logo } from 'src/Assets';
import { animated, useSpring } from 'react-spring';
import ParametersPopup from 'src/Containers/ParametersPopup/';
import { MenuItem } from 'src/Services/Menu';
import { DISPLAYED_SECTION } from 'src/Redux/UserPreferences/Types';
import { WhiteRightArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import Menu from '../Menu';
import {
  WhiteDashboard,
  PersonSearch,
  ClientSearch,
  ServiceSearch,
  CheckInCircle,
  Synchro,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { Animation } from '@randstad-lean-mobile-factory/react-components-core';
import { useIsFetching } from '@tanstack/react-query';
import Apps from './Apps';

export const menu: MenuItem[] = [
  {
    icon: <WhiteDashboard className={styles.icon} />,
    title: 'dashboard',
    displayedSection: DISPLAYED_SECTION.DASHBOARD,
  },
  {
    icon: <></>,
    title: 'listes',
    subMenu: [
      {
        icon: <PersonSearch className={styles.icon} />,
        title: 'intérimaires',
        displayedSection: DISPLAYED_SECTION.INTERIM,
      },
      {
        icon: <ClientSearch className={styles.icon} />,
        title: 'interlocuteurs',
        displayedSection: DISPLAYED_SECTION.CLIENT,
      },
      {
        icon: <ServiceSearch className={styles.icon} />,
        title: 'services',
        displayedSection: DISPLAYED_SECTION.SERVICE,
      },
    ],
  },
  {
    icon: <CheckInCircle className={styles.icon} />,
    title: 'tâches en attente',
    displayedSection: DISPLAYED_SECTION.AGENDA,
  },
];

const SideBar = () => {
  const isFetchingContacts = useIsFetching(['fetchCompanyCandidates']) > 0;
  const isFetchingCandidates = useIsFetching(['fetchCompanyContacts']) > 0;
  const isFetching = isFetchingCandidates || isFetchingContacts;

  // width in pixel
  const openedWidth = 208;
  const closedWidth = 56;
  const [opened, setOpened] = useState(true);

  const spin = useSpring({
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  });

  const fade = useSpring({
    opacity: opened ? 1 : 0,
  });

  const expand = useSpring({
    width: opened ? `${openedWidth}px` : `${closedWidth}px`,
  });
  return (
    <div className={styles.container}>
      <Menu
        menu={menu ?? []}
        title="tour de production"
        logo={logo}
        opened={opened}
        fade={fade}
        expand={expand}
      />
      <div className={styles.bottomButtons}>
        <Animation.Fade visible={isFetching}>
          <Synchro className={styles.synchroIcon} />
        </Animation.Fade>
        <div className={styles.appsButton}>
          <Apps />
        </div>
        <div className={styles.parameterButton}>
          <ParametersPopup />
        </div>
      </div>
      <animated.div style={expand}>
        <div className={styles.retractButton} onClick={() => setOpened(!opened)}>
          <animated.div style={spin} className={styles.retractIcon}>
            <WhiteRightArrow />
          </animated.div>
          <animated.div className={styles.text} style={fade}>
            refermer
          </animated.div>
        </div>
      </animated.div>
    </div>
  );
};

export default SideBar;
