import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import HistoryCard from 'src/Components/HistoryCard';
import { useFetchCandidateClaims, useFetchContactClaims } from 'src/Hooks/Claims';
import { getCurrentCompanyId } from 'src/Redux/Companies/Selectors';
import { ROLE } from 'src/Redux/Types';
import { LocationState, ROUTES } from 'src/Services/Routing';
import styles from './ClaimsHistory.module.scss';
import { Props } from './ClaimsHistory.types';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';

const ClaimsHistory = ({ target }: Props) => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const companyId = useSelector(getCurrentCompanyId);
  const candidateClaimsQuery = useFetchCandidateClaims(target?.id ?? '', companyId ?? '');
  const contactClaimsQuery = useFetchContactClaims(target?.id ?? '', companyId ?? '');
  const claims =
    target?.role === ROLE.CANDIDATE
      ? candidateClaimsQuery.data ?? []
      : contactClaimsQuery.data ?? [];
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  return (
    <div className={styles.container}>
      <Button.Primary
        className={styles.button}
        onClick={() =>
          history.push({
            pathname: ROUTES.CLAIM_NEW,
            state: {
              modalParameters: { target },
              background: location.state.background,
            },
          })
        }
      >
        ajouter une réclamation
      </Button.Primary>
      <div className={isMobile ? styles.mobileClaimContainer : styles.exchangesContainer}>
        {!claims || claims.length === 0 ? (
          <div className={styles.noResult}>
            {`Aucune réclamation pour cet ${
              target?.role === ROLE.CANDIDATE ? 'intérimaire' : 'interlocuteur'
            }`}
          </div>
        ) : (
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <FixedSizeList
                itemCount={claims.length}
                width={width}
                className={styles.list}
                height={height}
                itemSize={isMobile ? 128 : 100}
              >
                {({ index, style }) => {
                  const claim = claims[index];
                  return (
                    <div style={style} className={styles.cardContainer}>
                      <HistoryCard
                        title={`date ${moment(claim.opening).format('L')}`}
                        subtitle={claim.category?.label}
                        secondSubtitle={claim.comment}
                        onClick={() =>
                          history.push({
                            pathname: ROUTES.CLAIM_EDIT,
                            state: {
                              modalParameters: {
                                target,
                                contentId: claim.localId,
                              },
                              background: location.state.background,
                            },
                          })
                        }
                      />
                    </div>
                  );
                }}
              </FixedSizeList>
            )}
          </AutoSizer>
        )}
      </div>
    </div>
  );
};

export default ClaimsHistory;
