import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PerimeterState } from './Types';
import { fetchPerimeter } from './Thunks';
import { FETCH_STATUS } from 'src/Redux/Types';

const initialState: PerimeterState = {
  companies: [],
  currentBrand: undefined,
  currentAgency: undefined,
  fetchStatus: FETCH_STATUS.FULFILLED,
};

export const getDefaultBrandAndAgency = (companies: PerimeterState['companies']) => {
  const defaultBrand = companies?.filter(company => company.brand?.name === 'Randstad Inhouse')[0];
  return {
    brand: defaultBrand,
    agency: defaultBrand?.agencies?.[0],
  };
};

export const { reducer: perimeterReducer, actions: perimeterActions } = createSlice({
  name: 'perimeter',
  initialState,
  reducers: {
    setCurrentBrand: (state, action: PayloadAction<PerimeterState['currentBrand']>) => {
      state.currentBrand = action.payload;
      state.currentAgency = action.payload?.agencies?.[0] ?? '';
    },
    setCurrentAgency: (state, action: PayloadAction<PerimeterState['currentAgency']>) => {
      state.currentAgency = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPerimeter.pending, state => {
      state.fetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(fetchPerimeter.fulfilled, (state, action) => {
      const newState = { ...state, ...action.payload.perimeter };
      newState.fetchStatus = FETCH_STATUS.FULFILLED;
      return newState;
    });
    builder.addCase(fetchPerimeter.rejected, state => {
      state.fetchStatus = FETCH_STATUS.REJECTED;
    });
  },
});
