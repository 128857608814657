import moment from 'moment';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getLastActionSyncDate } from 'src/Redux/Synchro/Selectors';
import { synchroActions } from 'src/Redux/Synchro/Slice';
import { CompaniesService, EntityAction } from 'src/Services/API';
import { db, Local } from 'src/Services/DB';
import { usePerimeter } from '../Perimeter';

export const useBackgroundFetchCompanyActions = (
  extraOptions?: UseQueryOptions<unknown, unknown, Local<EntityAction[]>>
) => {
  const { brandCode, companyId } = usePerimeter();
  const dispatch = useDispatch();

  const synchroStartTime = moment().toDate();
  const lastSynchro = useSelector(getLastActionSyncDate);

  useQuery<unknown, unknown, Local<EntityAction[]>>(
    ['fetchCompanyActions', brandCode, companyId, moment(lastSynchro).format('LLL')],
    async () => {
      const actions = await CompaniesService.companiesControllerGetCompaniesConsultantActions({
        id: companyId ?? '',
        brandCode: brandCode ?? '',
        modifiedSince: lastSynchro,
      });

      await db.transaction('rw', db.actions, async () => {
        await db.actions
          .where('actionId')
          .anyOf(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            actions.filter(action => action.actionId !== undefined).map(action => action.actionId!)
          )
          .delete();
        await db.actions.bulkAdd(actions);
      });
      dispatch(synchroActions.setLastActionSyncDate(synchroStartTime.toISOString()));

      return actions;
    },
    {
      enabled: companyId !== undefined,
      staleTime: 300000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      ...extraOptions,
    }
  );
};
