import React, { useCallback } from 'react';
import { Absence, useCreateAbsence } from 'src/Hooks/Absence';
import { toFetchStatus } from 'src/Services/ReactQuery';
import AbsenceCreationModal from './AbsenceCreationModal.component';
import { OwnProps } from './AbsenceCreationModal.types';

const MemoAbsenceCreationModal = React.memo(AbsenceCreationModal);

const EnhancedAbsenceCreationModal = (props: OwnProps) => {
  const creator = useCreateAbsence();
  const fetchStatus = toFetchStatus(creator.mutation);
  const onValidate = useCallback(
    (absence: Absence) => {
      creator.create(absence);
    },
    [creator]
  );

  return <MemoAbsenceCreationModal fetchStatus={fetchStatus} onValidate={onValidate} {...props} />;
};

export default EnhancedAbsenceCreationModal;
