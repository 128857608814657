import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/RootReducer';

import UserPreferencesState from './Types';

export const getUserPreferencesState = (state: RootState) => state.userPreferences;

export const getDisplaySection = createSelector(
  [getUserPreferencesState],
  (state: UserPreferencesState) => state.displaySection
);

export const getCardDisplayMode = createSelector(
  [getUserPreferencesState],
  (state: UserPreferencesState) => state.cardDisplayMode
);

export const getSortBy = createSelector(
  [getUserPreferencesState],
  (state: UserPreferencesState) => state.sortBy
);

export const getOnMissionOnlyFilter = createSelector(
  [getUserPreferencesState],
  (state: UserPreferencesState) => state.onMissionOnlyFilter
);

export default getUserPreferencesState;
