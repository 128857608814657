import React from 'react';
import UnderContructionSection from '../UnderContructionSection';
import WorkplaceHeader from '../WorkplaceHeader';

import styles from './DashboardSection.module.scss';

const DashboardSection = () => {
  return (
    <div className={styles.container}>
      <WorkplaceHeader
        title="dashboard"
        comment="Retrouver une vue synthétique de votre activité chez votre compte à l’instant « t »"
      />
      <UnderContructionSection />
    </div>
  );
};

export default DashboardSection;
