import { createMigrate } from 'redux-persist';

const persistMigrations = {
  // declare redux migrations here
  // see https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md for syntax
  // see https://gist.github.com/lafiosca/b7bbb569ae3fe5c1ce110bf71d7ee153 for proper typing
};

// the current createMigrate typings do not allow different incoming and outgoing state types
export const persistMigrate = createMigrate(persistMigrations as Record<string, never>);

export const persistVersion = 0;
