import 'moment/locale/fr';
import moment, { Duration, duration, Moment } from 'moment';

moment.locale('fr');

// time should be in the TimePicker format (ie. HH:mm)
export function toDateTimeMoment({ date, time }: { date: Date; time: string }): Moment {
  const dateTime = moment(date);

  if (time !== '') {
    const [hours, minutes] = time.split(':').map(value => parseInt(value, 10));

    return dateTime.hours(hours).minutes(minutes);
  }

  return dateTime;
}

export function toDuration(time: string) {
  if (time !== '') {
    const [hours, minutes] = time.split(':').map(value => parseInt(value, 10));

    return duration({ hours, minutes });
  }

  return duration(0);
}

export function toTimePicker(duration: Duration) {
  return `${duration.hours}:${duration.minutes}`;
}
