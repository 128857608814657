import React, { useCallback } from 'react';
import { useCreateClaim } from 'src/Hooks/Claims';
import { ClaimEntity } from 'src/Services/API';

import { toFetchStatus } from 'src/Services/ReactQuery';
import ClaimCreationModal from './ClaimCreationModal.component';
import { OwnProps } from './ClaimCreationModal.types';

const MemoClaimCreationModal = React.memo(ClaimCreationModal);

const EnhancedClaimCreationModal = (props: OwnProps) => {
  const creator = useCreateClaim();
  const fetchStatus = toFetchStatus(creator.mutation);
  const onValidate = useCallback(
    (claim: ClaimEntity) => {
      creator.create(claim);
    },
    [creator]
  );
  return (
    <MemoClaimCreationModal
      fetchStatus={fetchStatus}
      modalParameters={props.modalParameters}
      onValidate={onValidate}
    />
  );
};

export default EnhancedClaimCreationModal;
