import { LetterBadge } from '@randstad-lean-mobile-factory/react-components-core';
import styles from './HistoryModalHeader.module.scss';
import { Props } from './HistoryModalHeader.types';

const HistoryModalHeader = ({ target }: Props) => {
  return (
    <div className={styles.TTInformations}>
      <LetterBadge
        text={
          target?.name
            .split(/\s/)
            .map((word: string) => word[0])
            .join('')
            .substring(0, 2) ?? ''
        }
      />
      <div className={styles.TTInformationsText}>
        <div className={styles.TTName}>{target?.name}</div>
        <div className={styles.serviceName}>{target?.service?.name}</div>
      </div>
    </div>
  );
};

export default HistoryModalHeader;
