import React, { useCallback } from 'react';
import ExchangeCreationModal from './ExchangeCreationModal.component';
import { OwnProps } from './ExchangeCreationModal.types';
import { toFetchStatus } from 'src/Services/ReactQuery';
import { EntityAction } from 'src/Services/API';
import { useCreateAction } from 'src/Hooks/Actions';
import { Local } from '../../Services/DB';

const MemoExchangeCreationModal = React.memo(ExchangeCreationModal);

const EnhancedExchangeCreationModal = (props: OwnProps) => {
  const creator = useCreateAction();
  const fetchStatus = toFetchStatus(creator.mutation);
  const onValidate = useCallback(
    (exchange: Local<EntityAction>) => {
      creator.create(exchange);
    },
    [creator]
  );
  return (
    <MemoExchangeCreationModal
      fetchStatus={fetchStatus}
      modalParameters={props.modalParameters}
      onValidate={onValidate}
    />
  );
};

export default EnhancedExchangeCreationModal;
