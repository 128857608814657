import { useDispatch, useSelector } from 'react-redux';
import { getCurrentCompanyId } from 'src/Redux/Companies/Selectors';
import { companiesActions } from 'src/Redux/Companies/Slice';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';

export const usePerimeter = () => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);
  const companyId = useSelector(getCurrentCompanyId);

  return { brandCode, agencyId, companyId };
};

export const useResetPerimeter = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch(companiesActions.setCurrentCompany(undefined));
    dispatch(perimeterActions.setCurrentBrand(undefined));
    dispatch(perimeterActions.setCurrentAgency(undefined));
  };
};
