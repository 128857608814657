import ReactGA from 'react-ga4';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { EntityAction, EnumEntityActionType } from 'src/Services/API';
import { db, Local } from 'src/Services/DB';
import { usePerimeter } from '../Perimeter';
import { actionMutationsConfig } from './mutationConfigs';
import { MutationKeys } from '..';

export const useCreateAction = () => {
  const { brandCode, agencyId, companyId } = usePerimeter();
  const { zoneId, regionId } = useSelector(getAnalyticsUserInfo);
  const mutation = useMutation(
    [MutationKeys.ACTION_CREATION],
    actionMutationsConfig['actions/create']
  );

  return {
    create: async (data: Local<EntityAction>) => {
      const localId = await db.actions.add({
        ...data,
      });
      const eventTitle = (type: EnumEntityActionType) => {
        switch (type) {
          case EnumEntityActionType.ACTION_CANDIDATE_EXCHANGE:
            return "Création d'échange intérimaire";
          case EnumEntityActionType.ACTION_CONTACT_EXCHANGE:
            return "Création d'échange client";
          case EnumEntityActionType.ACTION_PRODUCTION_IMPACT:
            return "Création d'impact en production";
          case EnumEntityActionType.ACTION_SECURITY_EVENT:
            return "Création d'événement de sécurité";
          default:
            return "Création d'échange";
        }
      };
      ReactGA.event(eventTitle(data.type), {
        brandCode,
        agencyId,
        subType: data.subType,
        zoneId,
        regionId,
      });
      mutation.mutate({ localId, brandCode, agencyId, companyId: companyId ?? '' });
    },
    mutation,
  };
};
