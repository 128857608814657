import React from 'react';
import SelectSecurityEventType, { OTHER_REASON } from './SelectSecurityEventType.component';
import { Props } from './SelectSecurityEventType.types';

const MemoSelectSecurityEventType = React.memo(SelectSecurityEventType);

export enum SECURITY_EVENTS {
  BEHAVIOUR = 'écart comportement au travail',
  EQUIPMENT = 'écart concernant le port des EPI',
  SECURITY_VISIT = 'visite sécurité',
}

const EnhancedSelectSecurityEventType = (props: Props) => {
  const securityEvents = Object.values(SECURITY_EVENTS);
  return <MemoSelectSecurityEventType types={[...securityEvents, OTHER_REASON]} {...props} />;
};

export default EnhancedSelectSecurityEventType;
