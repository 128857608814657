import { useMutation, useQueryClient } from '@tanstack/react-query';
import { db } from 'src/Services/DB';
import { usePerimeter } from '../Perimeter';
import { communicationMatrixMutationsConfig } from './mutationConfigs';
import { CommunicationMatrixEntryLight } from './types';
import { MutationKeys } from '..';

export const useInsertContactInCommunicationMatrix = () => {
  const { brandCode, agencyId, companyId } = usePerimeter();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    [MutationKeys.COMMUNICATION_MATRIX_INSERT],
    communicationMatrixMutationsConfig(queryClient)[MutationKeys.COMMUNICATION_MATRIX_INSERT]
  );

  return {
    create: async (data: CommunicationMatrixEntryLight) => {
      const localId = await db.communicationMatrix.add(data);
      mutation.mutate({ localId, brandCode, agencyId, companyId: companyId ?? '' });
    },
    mutation,
  };
};
