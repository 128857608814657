import React, { useCallback } from 'react';
import { useResetPerimeter } from 'src/Hooks/Perimeter';
import { useAuth0 } from 'src/Providers/Auth0';

import ParametersPopup from './ParametersPopup.component';

const MemoParametersPopup = React.memo(ParametersPopup);

const EnhancedParameterPopup = () => {
  const auth = useAuth0();
  const resetPerimeter = useResetPerimeter();

  const onLogout = useCallback(() => {
    resetPerimeter();
    auth?.logout({ returnTo: `${process.env.PUBLIC_URL}/login` });
  }, [auth, resetPerimeter]);

  return (
    <MemoParametersPopup
      firstName={auth?.user?.given_name}
      lastName={auth?.user?.family_name}
      email={auth?.user?.email}
      onLogout={onLogout}
    />
  );
};

export default EnhancedParameterPopup;
