import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AllCandidatesWithCount, CandidatesService } from 'src/Services/API';
import { db } from 'src/Services/DB';
import { useLiveQuery } from 'dexie-react-hooks';
import { usePerimeter } from '../Perimeter';
import { QueryKeys } from '..';

export const useBackgroundFetchAllCandidates = (
  extraOptions?: UseQueryOptions<AllCandidatesWithCount, unknown, AllCandidatesWithCount>
) => {
  const { brandCode, companyId, agencyId } = usePerimeter();
  return useQuery<AllCandidatesWithCount, unknown, AllCandidatesWithCount>(
    [QueryKeys.FETCH_ALL_CANDIDATES, brandCode, companyId, agencyId],
    async () => {
      const candidatesWithCount = await CandidatesService.candidatesControllerGetAgencyAllCandidate(
        {
          companyId: companyId ?? '',
          brandCode: brandCode,
          agencyId: agencyId,
        }
      );
      await db.candidates.clear();
      await db.candidates.bulkPut(candidatesWithCount.candidates);
      return candidatesWithCount;
    },
    {
      enabled: companyId !== undefined,
      staleTime: 300000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      ...extraOptions,
    }
  );
};

export const useFetchCompanyCandidates = (
  extraOptions?: UseQueryOptions<AllCandidatesWithCount, unknown, AllCandidatesWithCount>
) => {
  useBackgroundFetchAllCandidates(extraOptions);
  const candidates =
    useLiveQuery(() =>
      db.candidates.filter(candidate => candidate.contractId !== undefined).toArray()
    ) ?? [];
  return candidates;
};

export const useFetchAllCandidates = (
  extraOptions?: UseQueryOptions<AllCandidatesWithCount, unknown, AllCandidatesWithCount>
) => {
  useBackgroundFetchAllCandidates(extraOptions);
  const candidates = useLiveQuery(() => db.candidates.toArray());
  return candidates;
};

export const useFetchCompanyCandidate = (
  candidateId: string,
  extraOptions?: UseQueryOptions<AllCandidatesWithCount, unknown, AllCandidatesWithCount>
) => {
  useBackgroundFetchAllCandidates(extraOptions);
  const candidate = useLiveQuery(
    () => db.candidates.where('candidateId').equals(candidateId).first(),
    [candidateId]
  );

  return candidate;
};
