import React from 'react';

import { Props } from './ColorCircle.types';
import styles from './ColorCircle.module.scss';

const ColorCircle = ({ color }: Props) => {
  return <p className={styles.container} style={{ backgroundColor: color }}></p>;
};

export default ColorCircle;
