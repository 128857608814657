import React, { useEffect, useState } from 'react';

import { Props } from './List.types';
import styles from './List.module.scss';
import ListItem from '../ListItem';
import { LetterBadge } from '@randstad-lean-mobile-factory/react-components-core';
import { ListItemWithRole } from '../ListItem/ListItem.types';
import { Element } from 'react-scroll';
import CandidateListItem from './CandidateListItem';
import CompanyContactListItem from './CompanyContactListItem';
import CandidateCard from '../Card/CandidateCard';
import CompanyContactCard from '../Card/CompanyContactCard';
import { ROLE } from 'src/Redux/Types';
import SectionHeader from '../SectionHeader';
import { useSelector } from 'react-redux';
import {
  getCardDisplayMode,
  getDisplaySection,
  getSortBy,
} from 'src/Redux/UserPreferences/Selectors';
import { DISPLAYED_SECTION, DISPLAY_MODE, SORT_BY } from 'src/Redux/UserPreferences/Types';
import { CandidateStatusEnum } from 'src/Services/API';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';

const InitialBadge = (
  fullName: string,
  badgeIcon?: React.ReactNode,
  candidateStatusId?: CandidateStatusEnum,
  isMobileCardBadge?: boolean
) => (
  <div className={styles.badge}>
    <LetterBadge
      className={classnames(
        { [styles.cdiColor]: candidateStatusId === CandidateStatusEnum.CDI },
        { [styles.smallBadge]: isMobileCardBadge }
      )}
      text={fullName
        .split(/\s/)
        .map((word: string) => word[0])
        .join('')
        .substring(0, 2)}
    />
    <div className={styles.badgeIcon}>{badgeIcon}</div>
  </div>
);

const BaseList = (props: Props) => {
  const [orderedItems, setOrderedItems] = useState<Record<string, ListItemWithRole[]>>({});
  const candidateSortBy = useSelector(getSortBy);
  const displayedSection = useSelector(getDisplaySection);
  const CandidateItemRenderer = props.itemRenderer ? CandidateCard : CandidateListItem;
  const CompanyContactItemRenderer = props.itemRenderer
    ? CompanyContactCard
    : CompanyContactListItem;
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const getCandidateSortValue = (
    candidate: ListItemWithRole,
    sortBy: SORT_BY,
    displayedSection: DISPLAYED_SECTION
  ): string => {
    if (displayedSection === DISPLAYED_SECTION.CLIENT || candidate.contactRole === ROLE.CONTACT) {
      return candidate.contactDetail.title[0];
    } else {
      switch (sortBy) {
        case SORT_BY.ALPHABETICAL:
          return candidate.contactDetail.title[0];
        case SORT_BY.QUALIFICATION:
          return candidate.contactDetail.description;
      }
    }
  };
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  const currentDisplayMode = useSelector(getCardDisplayMode);

  useEffect(() => {
    const _orderedItems: Record<string, ListItemWithRole[]> = alphabet
      .split('')
      .reduce((o, key) => ({ ...o, [key]: [] }), {});

    const sortBySection = props.items.some(x => x.contactDetail.section);

    props.items.forEach(item => {
      const sortValue = sortBySection
        ? item.contactDetail.section ?? ''
        : getCandidateSortValue(item, candidateSortBy, displayedSection);

      if (_orderedItems[sortValue] === undefined) {
        _orderedItems[sortValue] = [];
      }
      _orderedItems[sortValue].push(item);
    });

    Object.keys(_orderedItems).forEach(key => {
      _orderedItems[key] = _orderedItems[key].sort((item1, item2) =>
        item1.contactDetail.title.localeCompare(item2.contactDetail.title)
      );
    });
    setOrderedItems(_orderedItems);
  }, [props.items, alphabet, candidateSortBy, displayedSection]);

  if (props.items.length === 0) {
    return null;
  } else {
    return (
      <div className={styles.container}>
        {Object.entries(orderedItems)
          .sort((a, b) => (a[0] < b[0] ? -1 : 1))
          .map(([sectionTitle, items]) => (
            <Element name={sectionTitle} key={sectionTitle}>
              {items.length > 0 && (
                <>
                  <SectionHeader value={sectionTitle} isMobile={isMobile} />
                  <div
                    className={
                      props.subGroupStyle === undefined
                        ? isMobile
                          ? styles.mobileListRow
                          : styles.listRow
                        : props.subGroupStyle
                    }
                  >
                    {items.map(item => {
                      const SingleItemRenderer =
                        item.contactRole === ROLE.CANDIDATE
                          ? CandidateItemRenderer
                          : CompanyContactItemRenderer;
                      return (
                        <SingleItemRenderer
                          {...item.contactDetail}
                          key={item.contactDetail.title + item.contactDetail.subtitle}
                          icon={InitialBadge(
                            item.contactDetail.title,
                            item.contactDetail.badgeIcon,
                            item.contactDetail.candidateStatus?.id,
                            isMobile && currentDisplayMode === DISPLAY_MODE.GRID
                          )}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </Element>
          ))}
      </div>
    );
  }
};

const List = Object.assign(BaseList, { Item: ListItem });

export default List;
