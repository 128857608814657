import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { useAuth0 } from 'src/Providers/Auth0';
import { Props } from './PrivateRoute.types';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export default function PrivateRoute({ component: Component, ...rest }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()!;
  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
    });
  }, [loading, isAuthenticated, loginWithRedirect]);
  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated && Component ? <Component {...props} /> : null;
      }}
    />
  );
}
