import React from 'react';
import CandidateResults from './CandidateResults.component';
import { ROLE } from 'src/Redux/Types';
import styles from './CandidateResults.module.scss';
import ListItemActions from 'src/Containers/ListItemActions';
import { useFetchAllCandidates } from 'src/Hooks/Candidates';
import { useSelector } from 'react-redux';
import { getCardDisplayMode, getOnMissionOnlyFilter } from 'src/Redux/UserPreferences/Selectors';
import { BlueClock, CalendarOutlined } from 'src/Assets';
import moment from 'moment';

const MemoCandidateResults = React.memo(CandidateResults);

const EnhancedCandidateResults = () => {
  const rawCandidates = useFetchAllCandidates();
  const cardDisplayMode = useSelector(getCardDisplayMode);
  const onMissionOnly = useSelector(getOnMissionOnlyFilter);
  const filteredCandidates = onMissionOnly
    ? rawCandidates?.filter(candidate => candidate.contractId !== undefined)
    : rawCandidates;
  const candidates = filteredCandidates?.map(candidate => {
    const nonScheduleExitIcon =
      (candidate.contractRealEndDateFlexibility &&
        candidate.contractRealEndDate &&
        moment(candidate.contractRealEndDateFlexibility).isSameOrBefore(moment()) &&
        moment(candidate.contractRealEndDate).isSameOrBefore(moment())) ||
      (!candidate.contractRealEndDate && !candidate.contractRealEndDateFlexibility) ? (
        <BlueClock />
      ) : undefined;

    const candidateIcon = candidate.isScheduledExit ? (
      <CalendarOutlined className={styles.candidateSubIcon} />
    ) : (
      nonScheduleExitIcon
    );
    return {
      contactDetail: {
        title:
          candidate.name || candidate.firstName
            ? `${candidate.name ?? ''} ${candidate.firstName ?? ''}`
            : 'Aucun nom',
        subtitle: `${
          candidate.targetGroup !== undefined ? `groupe cible ${candidate.targetGroup}\n` : ''
        } ${
          candidate.firstContractStartDate !== undefined
            ? `1er contrat le ${moment(candidate.firstContractStartDate).format('DD/MM/YYYY')}`
            : ''
        } 
      ${
        candidate.isScheduledExit
          ? `sortie de pool prévue le ${moment(candidate.poolExitDate).format('DD/MM/YYYY')}`
          : ''
      }`,
        description: candidate.qualification?.label ?? '',
        candidateStatus: candidate.candidateStatus,
        badgeIcon: candidateIcon,
        headerRight: (
          <ListItemActions
            target={{
              role: ROLE.CANDIDATE,
              id: candidate.candidateId ?? '',
              name: `${candidate.name} ${candidate.firstName}`,
              qualification: {
                name: candidate.qualification?.label,
                id: candidate.qualification?.id,
              },
              service: { id: candidate.service?.serviceId, name: candidate.service?.name },
              candidateStatus: candidate.candidateStatus,
              firstContractDate: candidate.firstContractStartDate,
              targetGroup: candidate.targetGroup,
              contractId: candidate.contractId,
              isScheduledExit: candidate.isScheduledExit,
            }}
          />
        ),
      },
      contactRole: ROLE.CANDIDATE,
    };
  });
  return <MemoCandidateResults candidates={candidates} cardDisplayMode={cardDisplayMode} />;
};

export default EnhancedCandidateResults;
