import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Props } from './SecurityEventCreationModal.types';
import SecurityEventFormModal from '../SecurityEventFormModal';

const SecurityEventCreationModal = ({ modalParameters, onValidate, open, fetchStatus }: Props) => {
  const target = modalParameters?.target;

  const history = useHistory();
  // Redirect user to app root if he navigates directly to modal url on accident
  useEffect(() => {
    if (target === undefined) {
      history?.push('/');
    }
  }, [target, history]);

  return (
    <SecurityEventFormModal
      open={open ?? true}
      title="saisie d'un événement de sécurité pour"
      target={target}
      onValidate={onValidate}
      fetchStatus={fetchStatus}
    />
  );
};

export default SecurityEventCreationModal;
