import React from 'react';

import { Props } from './SecurityHistory.types';
import styles from './SecurityHistory.module.scss';
import HistoryCard from 'src/Components/HistoryCard';
import moment from 'moment';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { LocationState, ROUTES } from 'src/Services/Routing';
import { useFetchTargetActions } from 'src/Hooks/Actions';
import { EnumEntityActionType } from 'src/Services/API';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';

const SecurityHistory = ({ target }: Props) => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const securityEvents = useFetchTargetActions({
    type: EnumEntityActionType.ACTION_SECURITY_EVENT,
    targetId: target?.id ?? '',
  });
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  return (
    <div className={styles.container}>
      <Button.Primary
        className={styles.button}
        onClick={() =>
          history.push({
            pathname: ROUTES.SECURITY_EVENT_NEW,
            state: {
              modalParameters: { target },
              background: location.state.background,
            },
          })
        }
      >
        ajouter un événement de sécurité
      </Button.Primary>
      <div className={isMobile ? styles.mobileSecurityContainer : styles.securityContainer}>
        {securityEvents.length === 0 ? (
          <div className={styles.noResult}>Aucun événement de sécurité pour cet intérimaire</div>
        ) : (
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <FixedSizeList
                itemCount={securityEvents.length}
                width={width}
                className={styles.list}
                height={height}
                itemSize={isMobile ? 128 : 100}
              >
                {({ index, style }) => {
                  const securityEvent = securityEvents[index];
                  return (
                    <div style={style} className={styles.cardContainer}>
                      <HistoryCard
                        title={`date début ${moment(securityEvent.startDate).format(
                          'L'
                        )} - fin le ${moment(securityEvent.endDate).format('L')}`}
                        subtitle={securityEvent.report}
                        secondSubtitle={securityEvent.subType}
                        onClick={() =>
                          history.push({
                            pathname:
                              moment().diff(moment(securityEvent.endDate), 'days') > 7
                                ? ROUTES.SECURITY_EVENT_VIEW(securityEvent.actionId ?? '')
                                : ROUTES.SECURITY_EVENT_EDIT,
                            state: {
                              modalParameters: {
                                target,
                                contentId: securityEvent.localId,
                              },
                              background: location.state.background,
                            },
                          })
                        }
                      />
                    </div>
                  );
                }}
              </FixedSizeList>
            )}
          </AutoSizer>
        )}
      </div>
    </div>
  );
};

export default SecurityHistory;
