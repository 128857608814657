import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import HistoryModal from './HistoryModal.component';
import { OwnProps } from './HistoryModal.types';

const MemoHistoryModal = React.memo(HistoryModal);

const EnhancedHistoryModal = (props: OwnProps) => {
  const history = useHistory();
  const onClose = useCallback(() => {
    history.replace('/');
  }, [history]);
  useEffect(() => {
    function closeOnEscape(escapeEvent: KeyboardEvent) {
      if (escapeEvent.key === 'Escape') {
        onClose();
      }
    }
    document.addEventListener('keydown', closeOnEscape);
    return () => document.removeEventListener('keydown', closeOnEscape);
  });
  return <MemoHistoryModal modalParameters={props.modalParameters} onClose={onClose} />;
};

export default EnhancedHistoryModal;
