import React from 'react';

import { Props } from '../Card.types';
import styles from './MobileCard.module.scss';
import classnames from 'classnames';

const MobileCard = (props: Props) => {
  return (
    <div className={classnames(styles.container, props.descriptionClassname)}>
      <div className={styles.topPart}>
        {props.icon ?? <div />}
        <div className={styles.headerRight}> {props.headerRight}</div>
      </div>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.subtitle}>{props.subtitle}</div>
      <div className={styles.description}>{props.description}</div>
    </div>
  );
};

export default MobileCard;
