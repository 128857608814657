import React, { useCallback, useEffect, useState } from 'react';

import { Props } from './SegmentedMenu.types';
import styles from './SegmentedMenu.module.scss';
import SegmentedMenuItem from './SegmentedMenuItem';
import { useSpring } from 'react-spring';
import { animated } from 'react-spring';

function SegmentedMenu<T>({ controls, selected, onSelectionChange, keyValueExtractor }: Props<T>) {
  // Because boundingRect is undefined at first, we disable animations
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [boundingRect, setBoundingRect] = useState<DOMRect>();
  const [spanRef, setSpanRef] = useState<HTMLSpanElement>();
  const ref = useCallback((node: HTMLSpanElement) => {
    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect());
      setSpanRef(node);
    }
  }, []);
  const transition = useSpring({
    // Because boundingRect is undefined at first, we disable animations
    width: boundingRect?.width ?? 0,
    left: boundingRect?.left ?? 0,
    position: 'fixed',
    top: boundingRect?.bottom ? boundingRect?.bottom : 0,
    immediate: !shouldAnimate,
  });

  const handleResize = useCallback(
    () => setBoundingRect(spanRef?.getBoundingClientRect()),
    [spanRef]
  );

  useEffect(() => {
    if (boundingRect !== undefined) {
      setShouldAnimate(true);
    }
  }, [boundingRect]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <>
      <div className={styles.tabMenuContainer}>
        {controls.map(control => {
          return (
            <SegmentedMenuItem
              label={keyValueExtractor(control).value}
              isSelected={selected === control}
              key={keyValueExtractor(control).key}
              onSelect={() => onSelectionChange(control)}
              ref={
                keyValueExtractor(selected).key === keyValueExtractor(control).key ? ref : undefined
              }
            />
          );
        })}
      </div>
      <animated.div className={styles.yellowBar} style={transition} />
      <div className={styles.horizontalSeparator} />
    </>
  );
}

export default SegmentedMenu;
