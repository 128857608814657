import { ROLE } from 'src/Redux/Types';
import { ActionsService, EnumCreateActionBodyType } from 'src/Services/API';
import { db } from 'src/Services/DB';
import { Target } from 'src/Services/Routing';

export interface Exchange {
  target: Target;
  report: string;
  startDate: string;
  endDate?: string;
}

export const createExchange = async ({
  localId,
  brandCode,
  agencyId,
  companyId,
}: {
  localId: number;
  brandCode: string;
  agencyId: string;
  companyId: string;
}) => {
  const exchange = await db.exchanges.get(localId);
  if (exchange === undefined) {
    throw new Error(`Exchange ${localId} does not exist in local DB`);
  }

  ActionsService.actionsControllerPostCreateAction({
    body: {
      brandCode,
      companyId,
      agencyId,
      startDate: new Date(exchange.startDate),
      type:
        exchange.target.role === ROLE.CANDIDATE
          ? EnumCreateActionBodyType.ACTION_CANDIDATE_EXCHANGE
          : EnumCreateActionBodyType.ACTION_CONTACT_EXCHANGE,
      targetId: exchange.target.id,
      report: exchange.report,
      endDate: exchange.endDate ? new Date(exchange.endDate) : undefined,
    },
  });
};

export const exchangeMutationsConfig = {
  'exchanges/create': {
    mutationFn: createExchange,
    onError: async (_: unknown, { localId }: Parameters<typeof createExchange>[0]) => {
      await db.exchanges.delete(localId);
    },
    // Without a retry, mutations done while offline will fail instead of being paused
    retry: 1,
  },
};
