export const basicTabStyles = {
  textTransform: 'lowercase',
  fontSize: '1rem',
  lineHeight: '1.25rem',
  color: '#8a8a8a',
  paddingTop: '0.5rem',
  height: '2.5rem',
  minHeight: '2.5rem',
};

export const basicTabsStyles = {
  width: '100%',
  height: '2.5rem',
  minHeight: '2.5rem',
  '& button.Mui-selected': { color: '#0f1941' },
  '& span.MuiTabs-indicator': { backgroundColor: '#ffb511', height: 4 },
};
