import React, { useState, useCallback, useEffect } from 'react';

import { Props } from './AlphabetPicker.types';
import styles from './AlphabetPicker.module.scss';
import classnames from 'classnames';
import { scroller } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';

const AlphabetPicker = (props: Props) => {
  const [letterFraction, setLetterFraction] = useState(1);
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>();

  const handleResize = useCallback(() => {
    const letterHeight = parseFloat(getComputedStyle(document.documentElement).fontSize) * 1.5;

    if (containerRef) {
      const maxHeight = containerRef.clientHeight;
      if (maxHeight === undefined) {
        return;
      }
      const maxLetters = Math.min(alphabet.length, maxHeight / letterHeight);
      const bottomFrac = Math.ceil(alphabet.length / maxLetters);
      setLetterFraction(bottomFrac);
    }
  }, [alphabet.length, containerRef]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const onRefChange = useCallback(
    (node: HTMLDivElement | undefined | null) => {
      setContainerRef(node);
      if (node !== null) {
        handleResize();
      }
    },
    [handleResize]
  );

  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });

  return (
    <div className={classnames(styles.container, { [styles.mobileContainer]: isMobile })}>
      <div
        className={classnames(styles.heightLimiter, {
          [styles.mobileHeightLimiter]: isMobile,
        })}
      >
        <div
          className={classnames(styles.alphabet, { [styles.mobileAlphabet]: isMobile })}
          ref={onRefChange}
        >
          {(isMobile
            ? alphabet
            : alphabet
                .filter(function (el, index) {
                  return index % letterFraction === 0;
                })
                // the following two lines make sure the last displayed letter is z
                .slice(0, -1)
                .concat('z')
          ).map(letter => (
            <div
              className={classnames(styles.letter, {
                [styles.selected]: letter === props.selectedLetter,
                [styles.mobileLetter]: isMobile,
              })}
              onClick={() =>
                scroller.scrollTo(letter.toUpperCase(), {
                  duration: 800,
                  containerId: 'listScrollableContainer',
                  smooth: 'easeOutQuart',
                })
              }
              key={letter}
            >
              {letter}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AlphabetPicker;
