import React from 'react';

import { Props } from './CandidateCard.types';
import styles from './CandidateCard.module.scss';
import Card from '..';
import MobileCard from '../MobileCard/MobileCard.component';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';

const CandidateCard = (props: Props) => {
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  return isMobile ? (
    <MobileCard {...props} descriptionClassname={styles.candidate} />
  ) : (
    <Card {...props} descriptionClassname={styles.candidate} />
  );
};

export default CandidateCard;
