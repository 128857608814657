import React, { useCallback } from 'react';
import { useCreateAction } from 'src/Hooks/Actions';
import { EntityAction } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';
import { OwnProps } from '../ExchangeCreationModal/ExchangeCreationModal.types';
import SecurityEventCreationModal from './SecurityEventCreationModal.component';
import { Local } from '../../Services/DB';

const MemoSecurityEventCreationModal = React.memo(SecurityEventCreationModal);

export const EnhancedSecurityEventCreationEvent = (props: OwnProps) => {
  const creator = useCreateAction();
  const fetchStatus = toFetchStatus(creator.mutation);
  const onValidate = useCallback(
    (securityEvent: Local<EntityAction>) => {
      creator.create(securityEvent);
    },
    [creator]
  );

  return (
    <MemoSecurityEventCreationModal fetchStatus={fetchStatus} onValidate={onValidate} {...props} />
  );
};

export default EnhancedSecurityEventCreationEvent;
