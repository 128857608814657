import React from 'react';
import SelectPerimeter from './SelectPerimeter.component';
import { useSelector } from 'react-redux';
import { getFetchStatus, getCompanies } from 'src/Redux/Perimeter/Selectors';

import { OwnProps } from './SelectPerimeter.types';

const MemoSelectPerimeter = React.memo(SelectPerimeter);

const EnhancedSelectPerimeter = (ownProps: OwnProps) => {
  const fetchStatus = useSelector(getFetchStatus);
  const companies = useSelector(getCompanies);

  return <MemoSelectPerimeter fetchStatus={fetchStatus} perimeter={companies} {...ownProps} />;
};

export default EnhancedSelectPerimeter;
