import { LocationState } from 'src/Services/Routing';

export interface Props {
  modalParameters?: LocationState['modalParameters'];
  onClose: () => void;
}

export type OwnProps = Pick<Props, 'modalParameters'>;

export enum ACTIONS {
  ECHANGES = 'exchanges',
  RECLAMATIONS = 'claims',
  ABSENCES = 'absences',
  SECURITE = 'security',
  PRODUCTION_IMPACT = 'production_impact',
  AUTRES = 'others',
}
