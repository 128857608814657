import React from 'react';

import { Props } from './Card.types';
import styles from './Card.module.scss';
import classnames from 'classnames';

const Card = (props: Props) => {
  return (
    <div className={classnames(styles.container, props.descriptionClassname)}>
      <div className={styles.topPart}>
        <div className={styles.iconContainer}>
          {props.icon ?? <div />}
          <div className={styles.headerRight}> {props.headerRight}</div>
        </div>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.subtitle}>{props.subtitle}</div>
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.description}>{props.description}</div>
      </div>
    </div>
  );
};

export default Card;
