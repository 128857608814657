import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ExchangeFormModal from 'src/Components/ExchangeFormModal';
import { useFetchAction } from 'src/Hooks/Actions';
import { ROLE } from 'src/Redux/Types';

import { Props } from './ExchangeEditionModal.types';

const ExchangeEditionModal = ({ modalParameters, fetchStatus, onValidate }: Props) => {
  const history = useHistory();

  const target = modalParameters?.target;
  const localId = modalParameters?.contentId;
  // Redirect user to app root if he navigates directly to modal url on accident
  useEffect(() => {
    if (target === undefined || localId === undefined) {
      history?.push('/');
    }
  }, [target, localId, history]);

  const roleLabel = target?.role === ROLE.CANDIDATE ? `l'intérimaire` : `l'interlocuteur client`;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const exchange = useFetchAction(localId!);

  return (
    <ExchangeFormModal
      title={`modification d'un échange pour ${roleLabel}`}
      fetchStatus={fetchStatus}
      onValidate={onValidate}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      target={target!}
      exchange={exchange}
      placeholder="décrivez le thème, les éléments clés et les actions qui découlent de votre échange."
    />
  );
};

export default ExchangeEditionModal;
