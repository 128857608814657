import React from 'react';

import { Props } from './DisplayedSection.types';
import styles from './DisplayedSection.module.scss';
import { DISPLAYED_SECTION } from 'src/Redux/UserPreferences/Types';
import CompanyContacts from 'src/Containers/CompanyContacts';
import CandidateResults from 'src/Containers/CandidateResults';
import CompanyServices from 'src/Containers/CompanyServices';
import DashboardSection from 'src/Components/DashboardSection';
import AgendaSection from 'src/Containers/AgendaSection';
import classnames from 'classnames';
import MobileListSection from '../MobileListSection/MobileListSection.component';
import MobileTaskSection from '../MobileTaskSection/MobileTaskSection.component';
import MobileParameters from '../MobileParameters/MobileParameters.component';
import MobileAppsScreen from 'src/Containers/MobileAppsScreen/MobileAppsScreen.component';
import { SectionProvider } from 'src/Hooks/Section/useSection';

export const displayedComponent = {
  [DISPLAYED_SECTION.DASHBOARD]: <DashboardSection />,
  [DISPLAYED_SECTION.INTERIM]: <CandidateResults />,
  [DISPLAYED_SECTION.CLIENT]: <CompanyContacts />,
  [DISPLAYED_SECTION.SERVICE]: <CompanyServices />,
  [DISPLAYED_SECTION.AGENDA]: <AgendaSection />,
  [DISPLAYED_SECTION.PARAMETERS]: <MobileParameters />,
  [DISPLAYED_SECTION.APPLICATIONS]: <MobileAppsScreen />,
  [DISPLAYED_SECTION.LISTS]: <MobileListSection />,
  [DISPLAYED_SECTION.TASKS]: <MobileTaskSection />,
};

const DisplayedSection = (props: Props) => (
  <div className={classnames(styles.container, { [styles.mobileContainer]: props.isMobile })}>
    <SectionProvider>{displayedComponent[props.displayedSection]}</SectionProvider>
  </div>
);

export default DisplayedSection;
