import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EntityAction } from 'src/Services/API';
import { db, Local } from 'src/Services/DB';
import { queryStatus } from 'src/Services/ReactQuery';
import { usePerimeter } from '../Perimeter';
import { actionMutationsConfig } from './mutationConfigs';
import { MutationKeys } from '..';

export const useUpdateAction = () => {
  const { brandCode, agencyId, companyId } = usePerimeter();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    [MutationKeys.ACTION_UPDATE],
    actionMutationsConfig['actions/update']
  );

  return {
    update: async (data: Local<EntityAction>) => {
      await db.actions.where({ localId: data.localId }).modify(data);

      const action = await db.actions.where({ localId: data.localId }).first();

      if (action === undefined) {
        throw new Error(`Action with localId ${data.localId} does not exist in local DB`);
      }

      const mutationCache = queryClient.getMutationCache().getAll();

      if (
        mutationCache.findIndex(
          mutation =>
            (mutation.options?.mutationKey?.includes(MutationKeys.ACTION_UPDATE) ||
              mutation.options?.mutationKey?.includes(MutationKeys.ACTION_CREATION)) &&
            mutation.options?.variables?.['localId'] === data?.localId &&
            mutation.state.status === 'loading'
        ) !== -1
      ) {
        return queryStatus.success;
      }

      mutation.mutate({
        localId: action.localId ?? 0,
        brandCode,
        agencyId,
        companyId: companyId ?? '',
      });
    },
    mutation,
  };
};
