import React, { useCallback } from 'react';
import { useUpdateAction } from 'src/Hooks/Actions';
import { EntityAction } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';
import { OwnProps } from '../ExchangeCreationModal/ExchangeCreationModal.types';
import { Local } from '../../Services/DB';
import SecurityEventEditionModal from './SecurityEventEditionModal.component';

const MemoSecurityEventEditionModal = React.memo(SecurityEventEditionModal);

export const EnhancedSecurityEventEditionEvent = (props: OwnProps) => {
  const editor = useUpdateAction();
  const fetchStatus = toFetchStatus(editor.mutation);
  const onValidate = useCallback(
    (securityEvent: Local<EntityAction>) => {
      editor.update(securityEvent);
    },
    [editor]
  );

  return (
    <MemoSecurityEventEditionModal fetchStatus={fetchStatus} onValidate={onValidate} {...props} />
  );
};

export default EnhancedSecurityEventEditionEvent;
