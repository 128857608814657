import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { usePerimeter } from '../Perimeter';

const useInitializeAnalytics = () => {
  const { brandCode } = usePerimeter();
  useEffect(() => {
    ReactGA.set({ user_properties: { brandCode } });
  }, [brandCode]);
  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_CODE}`);
  }, []);
};

export default useInitializeAnalytics;
