import React from 'react';

import { Props } from './ExchangeHistory.types';
import styles from './ExchangeHistory.module.scss';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationState, ROUTES } from 'src/Services/Routing';
import { ROLE } from 'src/Redux/Types';
import { EnumEntityActionStatus, EnumEntityActionType } from 'src/Services/API';
import { useFetchTargetActions } from 'src/Hooks/Actions/useFetchTargetActions';
import { FixedSizeList } from 'react-window';
import HistoryCard from 'src/Components/HistoryCard';
import AutoSizer from 'react-virtualized-auto-sizer';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';

const ExchangeHistory = ({ target }: Props) => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const exchanges = useFetchTargetActions({
    type:
      target?.role === ROLE.CANDIDATE
        ? EnumEntityActionType.ACTION_CANDIDATE_EXCHANGE
        : EnumEntityActionType.ACTION_CONTACT_EXCHANGE,
    targetId: target?.id ?? '',
  });
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  return (
    <div className={styles.container}>
      <Button.Primary
        className={styles.button}
        onClick={() =>
          history.push({
            pathname: ROUTES.EXCHANGE_NEW,
            state: {
              modalParameters: { target: target },
              background: location.state.background,
            },
          })
        }
      >
        ajouter un échange
      </Button.Primary>
      <div className={isMobile ? styles.mobileExchangeContainer : styles.exchangesContainer}>
        {exchanges.length === 0 ? (
          <div className={styles.noResult}>
            {`Aucun échange pour cet ${
              target?.role === ROLE.CANDIDATE ? 'intérimaire' : 'interlocuteur'
            }`}
          </div>
        ) : (
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <FixedSizeList
                itemCount={exchanges.length}
                width={width}
                className={styles.list}
                height={height}
                itemSize={isMobile ? 128 : 100}
              >
                {({ index, style }) => {
                  const exchange = exchanges[index];
                  return (
                    <div style={style} className={styles.cardContainer}>
                      <HistoryCard
                        title={`date début ${moment(exchange.startDate).format(
                          'L'
                        )} - fin le ${moment(exchange.endDate).format('L')}`}
                        subtitle={exchange.report}
                        secondSubtitle={
                          exchange.status === EnumEntityActionStatus.FINISHED
                            ? 'terminé'
                            : 'en cours'
                        }
                        onClick={() =>
                          history.push({
                            pathname: ROUTES.EXCHANGE_EDIT,
                            state: {
                              modalParameters: { target: target, contentId: exchange.localId },
                              background: location.state.background,
                            },
                          })
                        }
                      />
                    </div>
                  );
                }}
              </FixedSizeList>
            )}
          </AutoSizer>
        )}
      </div>
    </div>
  );
};

export default ExchangeHistory;
