import React, { useCallback } from 'react';
import { Absence, useDeleteAbsence, useUpdateAbsences } from 'src/Hooks/Absence';
import { toFetchStatus } from 'src/Services/ReactQuery';
import AbsenceEditionModal from './AbsenceEditionModal.component';
import { OwnProps } from './AbsenceEditionModal.types';
import { Local } from '../../Services/DB';

const MemoAbsenceEditionModal = React.memo(AbsenceEditionModal);

const EnhancedAbsenceEditionModal = (props: OwnProps) => {
  const updater = useUpdateAbsences();
  const updateFetchStatus = toFetchStatus(updater.mutation);
  const onValidate = useCallback(
    (absence: Local<Absence>) => {
      updater.update(absence);
    },
    [updater]
  );
  const deleter = useDeleteAbsence();
  const deleteFetchStatus = toFetchStatus(deleter.mutation);
  const onDelete = useCallback(
    (absenceId: string) => {
      deleter.delete(absenceId);
    },
    [deleter]
  );

  return (
    <MemoAbsenceEditionModal
      updateFetchStatus={updateFetchStatus}
      onValidate={onValidate}
      onDelete={onDelete}
      deleteFetchStatus={deleteFetchStatus}
      {...props}
    />
  );
};

export default EnhancedAbsenceEditionModal;
