import { useLiveQuery } from 'dexie-react-hooks';
import { Moment } from 'moment';
import { db } from 'src/Services/DB';
import { useFetchCompanyContacts } from '../Contacts';
import { CommunicationMatrixEntryLight, Progression } from './types';
import { useBackgroundFetchCommunicationMatrix } from './useBackgroundFetchCommunicationMatrix';

export const useFetchCommunicationMatrixWithProgression = (fromMoment: Moment) => {
  useBackgroundFetchCommunicationMatrix();
  const communicationMatrix = useLiveQuery(() => db.communicationMatrix.toArray()) ?? [];
  const yearProgressions = useLiveQuery(async () => {
    const yearProgressions: Record<string, number> = {};
    const aYearAgo = fromMoment.clone().subtract(1, 'year');
    await db.actions
      .where('targetId')
      .anyOf(communicationMatrix.map(entry => entry.contactId))
      .and(action => aYearAgo.isBefore(action.startDate) && fromMoment.isAfter(action.startDate))
      .eachKey(key => {
        yearProgressions[key as string] = (yearProgressions[key as string] || 0) + 1;
      });
    return yearProgressions;
  }, [communicationMatrix.map(entry => entry.contactId).join(), fromMoment]);

  const semesterProgressions = useLiveQuery(async () => {
    const semesterProgressions: Record<string, number> = {};
    const aSemesterAgo = fromMoment.clone().subtract(6, 'month');
    await db.actions
      .where('targetId')
      .anyOf(communicationMatrix.map(entry => entry.contactId))
      .and(
        action => aSemesterAgo.isBefore(action.startDate) && fromMoment.isAfter(action.startDate)
      )
      .eachKey(key => {
        semesterProgressions[key as string] = (semesterProgressions[key as string] || 0) + 1;
      });
    return semesterProgressions;
  }, [communicationMatrix.map(entry => entry.contactId).join(), fromMoment]);

  const monthProgressions = useLiveQuery(async () => {
    const monthProgressions: Record<string, number> = {};
    const aMonthAgo = fromMoment.clone().subtract(1, 'month');
    await db.actions
      .where('targetId')
      .anyOf(communicationMatrix.map(entry => entry.contactId))
      .and(action => aMonthAgo.isBefore(action.startDate) && fromMoment.isAfter(action.startDate))
      .eachKey(key => {
        monthProgressions[key as string] = (monthProgressions[key as string] || 0) + 1;
      });
    return monthProgressions;
  }, [communicationMatrix.map(entry => entry.contactId).join(), fromMoment]);

  const weekProgressions = useLiveQuery(async () => {
    const weekProgressions: Record<string, number> = {};
    const aWeekAgo = fromMoment.clone().subtract(1, 'week');

    await db.actions
      .where('targetId')
      .anyOf(communicationMatrix.map(entry => entry.contactId))
      .and(action => aWeekAgo.isBefore(action.startDate) && fromMoment.isAfter(action.startDate))
      .eachKey(key => {
        weekProgressions[key as string] = (weekProgressions[key as string] || 0) + 1;
      });
    return weekProgressions;
  }, [communicationMatrix.map(entry => entry.contactId).join(), fromMoment]);

  const contactsInfo = useFetchCompanyContacts();

  const contactsIds = contactsInfo.map(contact => contact.contactId);

  const communicationMatrixWithProgression: Array<
    CommunicationMatrixEntryLight &
      Progression & {
        localId?: number;
        contactFirstName?: string;
        contactLastName?: string;
        contactPosition?: string;
      }
  > = communicationMatrix
    .filter(entry => contactsIds.includes(entry.contactId))
    .map(entry => {
      const contactInfo = contactsInfo.find(contact => contact.contactId === entry.contactId);
      return {
        ...entry,
        yearProgression: yearProgressions?.[entry.contactId] ?? 0,
        semesterProgression: semesterProgressions?.[entry.contactId] ?? 0,
        monthProgression: monthProgressions?.[entry.contactId] ?? 0,
        weekProgression: weekProgressions?.[entry.contactId] ?? 0,
        contactFirstName: contactInfo?.firstName,
        contactLastName: contactInfo?.name,
        contactPosition: contactInfo?.position,
      };
    });

  return communicationMatrixWithProgression.filter(entry => !entry.markedForDeletion);
};
