import React from 'react';

import { Props } from './SegmentedMenuItem.types';
import styles from './SegmentedMenuItem.module.scss';
import classnames from 'classnames';

const SegmentedMenuItem = React.forwardRef<HTMLSpanElement, Props>(
  ({ isSelected, label, key, onSelect }, ref) => {
    return (
      <label key={key} className={styles.tabItemContainer}>
        <span className={classnames({ [styles.selectedText]: isSelected })} ref={ref}>
          {label}
        </span>
        <input
          className={styles.input}
          type="radio"
          name="radioBtn"
          onChange={() => {
            onSelect();
          }}
        />
      </label>
    );
  }
);

export default SegmentedMenuItem;
