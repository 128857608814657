import React, { useEffect } from 'react';

import { Props } from './SelectPerimeter.types';
import styles from './SelectPerimeter.module.scss';
import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import nullify from 'src/Utils/nullify';
import { OSMConsultantModelDtoConsultantAgencyByCompany } from 'src/Services/API';

const SelectPerimeter = ({
  perimeter,
  selectedBrand,
  selectedAgency,
  setSelectedBrand,
  setSelectedAgency,
}: Props) => {
  // default brand is Randstad Inhouse
  useEffect(() => {
    if (!selectedBrand) {
      setSelectedBrand(perimeter?.find(x => x.brand?.name === 'Randstad Inhouse'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.PerimeterContainer}>
      <ComboBox
        id="selectBrand"
        value={selectedBrand ?? null}
        placeholder="Aucune marque"
        searchResults={perimeter ?? ([] as OSMConsultantModelDtoConsultantAgencyByCompany[])}
        useNativeOptionFiltering
        keyValueExtractor={({ brand, brandCodeApiHeader }) => ({
          key: `${brand?.code}${brandCodeApiHeader}`,
          value: brand?.name ?? 'Marque inconnue',
        })}
        onChange={nullify(setSelectedBrand)}
      />
      <ComboBox
        id="selectAgency"
        placeholder="Aucune agence"
        value={selectedAgency}
        searchResults={selectedBrand?.agencies ?? ([] as string[])}
        keyValueExtractor={agency => ({ key: agency, value: agency })}
        onChange={nullify(setSelectedAgency)}
        useNativeOptionFiltering
      />
    </div>
  );
};

export default SelectPerimeter;
