import { Props } from './LastExchange.types';
import { useFetchLastExchange } from 'src/Hooks/CommunicationMatrix';
import styles from './LastExchange.module.scss';
import moment from 'moment';
import SmallLink from 'src/Components/SmallLink';
import { useHistory, useLocation } from 'react-router-dom';
import { Eye } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ROUTES } from 'src/Services/Routing';

const LastExchange = ({ contactId }: Props) => {
  const lastExchange = useFetchLastExchange(contactId);
  const history = useHistory();
  const location = useLocation();
  return (
    <div className={styles.container}>
      <div>
        {lastExchange?.startDate && moment(lastExchange?.startDate).format('DD/MM/YYYY [à] HH:mm')}
      </div>
      {lastExchange && (
        <SmallLink
          leftIcon={<Eye />}
          title={'dernier CR'}
          onClick={() =>
            history.push({
              pathname: ROUTES.EXCHANGE_VIEW(lastExchange.actionId ?? ''),
              state: { background: location },
            })
          }
        />
      )}
    </div>
  );
};

export default LastExchange;
