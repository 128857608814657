import React from 'react';

import { Props } from './AbsenceEditionModal.types';
import { useHistory } from 'react-router-dom';
import AbsenceFormModal from 'src/Components/AbsenceFormModal';
import { useFetchAbsence } from '../../Hooks/Absence/useFetchAbsence';

const AbsenceEditionModal = ({
  modalParameters,
  onValidate,
  updateFetchStatus,
  onDelete,
  deleteFetchStatus,
  open,
}: Props) => {
  const history = useHistory();

  const target = modalParameters?.target;
  const localId = modalParameters?.contentId;

  // Redirect user to app root if he navigates directly to modal url on accident
  if (!localId) history?.push('/');

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const absence = useFetchAbsence(localId!);

  return (
    <AbsenceFormModal
      title="édition d'une absence pour"
      open={open ?? true}
      fetchStatus={updateFetchStatus}
      absence={absence}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      target={target!}
      onValidate={onValidate}
      isEditionModal
      onDelete={onDelete}
      deleteFetchStatus={deleteFetchStatus}
    />
  );
};

export default AbsenceEditionModal;
