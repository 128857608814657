import { useMutation, useQueryClient } from '@tanstack/react-query';
import { db } from 'src/Services/DB';
import { queryStatus } from 'src/Services/ReactQuery';
import { usePerimeter } from '../Perimeter';
import { communicationMatrixMutationsConfig } from './mutationConfigs';
import { CommunicationMatrixEntryLight } from './types';
import { MutationKeys } from '..';

export const useUpdateContactInCommunicationMatrix = () => {
  const { brandCode, agencyId, companyId } = usePerimeter();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    [MutationKeys.COMMUNICATION_MATRIX_UPDATE],
    communicationMatrixMutationsConfig(queryClient)[MutationKeys.COMMUNICATION_MATRIX_UPDATE]
  );

  return {
    update: async (data: CommunicationMatrixEntryLight, entryLocalId: number) => {
      await db.communicationMatrix.where({ localId: entryLocalId }).modify(data);
      const entry = await db.communicationMatrix.where({ localId: entryLocalId }).first();

      const mutationCache = queryClient.getMutationCache().getAll();

      if (
        mutationCache.findIndex(
          mutation =>
            (mutation.options?.mutationKey?.includes(MutationKeys.COMMUNICATION_MATRIX_UPDATE) ||
              mutation.options?.mutationKey?.includes(MutationKeys.COMMUNICATION_MATRIX_INSERT)) &&
            mutation.options?.variables?.['localId'] === entry?.localId &&
            mutation.state.status === 'loading'
        ) !== -1
      ) {
        return queryStatus.success;
      }

      if (entry !== undefined && entry.localId !== undefined) {
        mutation.mutate({
          localId: entry.localId,
          brandCode,
          agencyId,
          companyId: companyId ?? '',
        });
      }
    },
    mutation,
  };
};
