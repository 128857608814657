import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CompaniesService, CompanyContact } from 'src/Services/API';
import { db } from 'src/Services/DB';
import { useLiveQuery } from 'dexie-react-hooks';
import { usePerimeter } from '../Perimeter';

export const useBackgroundFetchCompanyContacts = (
  extraOptions?: UseQueryOptions<unknown, unknown, CompanyContact[]>
) => {
  const { brandCode, companyId } = usePerimeter();
  useQuery<unknown, unknown, CompanyContact[]>(
    ['fetchCompanyContacts', brandCode, companyId],
    async () => {
      const contacts = await CompaniesService.companiesControllerGetContacts({
        id: companyId ?? '',
        brandCode: brandCode ?? '',
      });

      await db.transaction('rw', db.contacts, async () => {
        await db.contacts.clear();
        await db.contacts.bulkPut(contacts);
      });

      return contacts;
    },
    {
      enabled: companyId !== undefined,
      staleTime: 300000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      ...extraOptions,
    }
  );
};

export const useFetchCompanyContacts = (
  extraOptions?: UseQueryOptions<unknown, unknown, CompanyContact[]>
) => {
  useBackgroundFetchCompanyContacts(extraOptions);
  const contacts = useLiveQuery(() => db.contacts.toArray()) ?? [];

  return contacts;
};

export const useFetchCompanyContact = (
  contactId: string,
  extraOptions?: UseQueryOptions<unknown, unknown, CompanyContact[]>
) => {
  useBackgroundFetchCompanyContacts(extraOptions);
  const contact = useLiveQuery(
    () => db.contacts.where('contactId').equals(contactId).first(),
    [contactId]
  );

  return contact;
};
