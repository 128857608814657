import classnames from 'classnames';
import React from 'react';

import styles from './ListItem.module.scss';
import { ListItemProps } from './ListItem.types';

const ListItem = (props: ListItemProps) => (
  <div className={classnames(styles.container, props.descriptionClassname)}>
    <div className={styles.leftContainer}>
      <div className={styles.icon}>
        <div>{props.icon}</div>
      </div>
      <div className={styles.subContainer}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.subtitle}>{props.subtitle}</div>
      </div>
    </div>
    <div className={styles.descriptionContainer}>
      <div className={styles.description}>{props.description}</div>
      {props.headerRight}
    </div>
  </div>
);

export default ListItem;
