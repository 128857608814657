import React from 'react';

import { Props } from './AgendaWeekSection.types';
import styles from './AgendaWeekSection.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useFetchCommunicationMatrixWithProgression } from 'src/Hooks/CommunicationMatrix';
import { useFetchWeekActions } from 'src/Hooks/Actions';
import moment, { Moment } from 'moment';
import HideableSection from 'src/Components/HideableSection';
import { Table } from '@randstad-lean-mobile-factory/react-components-core';
import {
  EnumCommunicationMatrixEntryFrequencyUnit,
  EnumEntityActionTargetType,
  EnumEntityActionType,
} from 'src/Services/API';
import classnames from 'classnames';
import ColorCircle from 'src/Components/ColorCircle';
import { frequencyUnitEnumMap } from 'src/Services/Transformers/communicationMatrix';
import LastExchange from '../LastExchange';
import SmallLink from 'src/Components/SmallLink';
import {
  AddCross,
  Eye,
  WhiteCheck,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ROLE } from 'src/Redux/Types';
import { ROUTES } from 'src/Services/Routing';
import CommunicationMatrixMenu from '../CommunicationMatrixMenu';
import ActionMenu from '../ActionMenu';

export const COMMUNICATION_MATRIX_GREEN = 'rgb(52, 158, 22)';
export const COMMUNICATION_MATRIX_GREY = 'rgb(215, 212, 230)';
export const COMMUNICATION_MATRIX_ORANGE = 'rgb(237, 144, 59)';
export const COMMUNICATION_MATRIX_DARK_GREY = 'rgb(138, 128, 128)';

export function weekToString(week: Moment) {
  const monday = week.clone().weekday(0).clone();
  const friday = week.clone().weekday(4).clone();

  // friday and monday in same month
  if (monday.isSame(friday, 'month')) {
    return `${monday.format('D')} - ${friday.format('D')} ${week.format('MMM YYYY')}`;
  } else {
    // monday and friday are in the same year
    if (monday.isSame(friday, 'year')) {
      return `${monday.format('D MMM')} - ${friday.format('D MMM')} ${week.format('YYYY')}`;
    } else {
      return `${monday.format('D MMM YYYY')} - ${friday.format('D MMM YYYY')}`;
    }
  }
}

const AgendaWeekSection = ({ week, title, defaultOpen }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const communicationMatrix = useFetchCommunicationMatrixWithProgression(week);
  const actions = useFetchWeekActions(week);

  return (
    <HideableSection
      defaultOpenState={defaultOpen ?? false}
      title={`${title} (${weekToString(week)})`}
      dataReady={communicationMatrix.length > 0}
    >
      <div className={styles.weekContent}>
        <div className={styles.subTitle}>matrice de communication</div>
        <Table
          headers={[
            { label: 'nom interlocuteur' },
            { label: 'fonction' },
            { label: 'fréquence visite', width: '8rem' },
            { label: 'dernière visite', width: '8rem' },
            { label: 'progression', width: '6rem' },
            { label: 'actions', width: '2rem' },
          ]}
          data={communicationMatrix.map(entry => {
            const progression =
              (entry.frequencyUnit === EnumCommunicationMatrixEntryFrequencyUnit.YEARLY
                ? entry.yearProgression
                : entry.frequencyUnit === EnumCommunicationMatrixEntryFrequencyUnit.PER_SEMESTER
                ? entry.semesterProgression
                : entry.frequencyUnit === EnumCommunicationMatrixEntryFrequencyUnit.MONTHLY
                ? entry.monthProgression
                : entry.weekProgression) ?? 0;
            return [
              <p
                className={classnames(
                  styles.comMatrixCell,
                  styles.comMatrixCellS,
                  styles.comMatrixName,
                  styles.greenishBackground
                )}
              >
                <ColorCircle
                  color={
                    progression >= entry.frequency
                      ? COMMUNICATION_MATRIX_GREEN
                      : COMMUNICATION_MATRIX_GREY
                  }
                />
                <span>{`${entry?.contactFirstName} ${entry?.contactLastName}`}</span>
              </p>,
              <p
                className={classnames(
                  styles.comMatrixCell,
                  styles.comMatrixCellXS,
                  styles.greenishBackground
                )}
              >
                {entry?.contactPosition}
              </p>,
              <p
                className={classnames(
                  styles.comMatrixCell,
                  styles.comMatrixCellXS,
                  styles.greenishBackground
                )}
              >
                {`${entry.frequency} fois/${frequencyUnitEnumMap[entry.frequencyUnit]}`}
              </p>,
              <p
                className={classnames(
                  styles.comMatrixCell,
                  styles.comMatrixCellXS,
                  styles.greenishBackground
                )}
              >
                <LastExchange contactId={entry.contactId} />
              </p>,
              progression < entry.frequency ? (
                <p
                  className={classnames(
                    styles.comMatrixCell,
                    styles.comMatrixCellXS,
                    styles.progressionCell,
                    styles.greenishBackground
                  )}
                >
                  {`${progression} visite${progression > 1 ? 's' : ''} / ${entry.frequency} prévue${
                    entry.frequency > 1 ? 's' : ''
                  }`}
                </p>
              ) : (
                <p className={styles.greenCheck}>
                  <WhiteCheck />
                </p>
              ),
              <p>
                <CommunicationMatrixMenu
                  target={{
                    role: ROLE.CONTACT,
                    id: entry.contactId,
                    name: [entry.contactFirstName, entry.contactLastName].join(' '),
                    entryLocalId: entry.localId,
                  }}
                />
              </p>,
            ];
          })}
        />
        <SmallLink
          className={styles.addContactLink}
          leftIcon={<AddCross className={styles.addContactIcon} />}
          title={'ajouter un interlocuteur à la matrice de communication'}
          onClick={() =>
            history.push({
              pathname: ROUTES.COMMUNICATION_MATRIX_INSERT,
              state: { background: location },
            })
          }
        />
        <div className={styles.tableSpacer} />
        <div className={styles.subTitle}>vos actions de la semaine à traiter</div>
        <div className={styles.actionTable}>
          <Table
            headers={[
              { label: 'nom de la personne' },
              { label: 'action à mener' },
              { label: 'date limite', width: '8rem' },
              { label: 'statut', width: '5rem' },
              { label: 'actions', width: '5rem' },
            ]}
            data={actions.map(action => [
              <p
                className={classnames({
                  [styles.candidateRow]: action.targetType === EnumEntityActionTargetType.TT,
                })}
              >
                <p
                  className={classnames(
                    styles.comMatrixCell,
                    styles.comMatrixCellS,
                    styles.comMatrixName
                  )}
                >
                  <ColorCircle
                    color={
                      moment(action.endDate).isBefore(moment())
                        ? COMMUNICATION_MATRIX_ORANGE
                        : COMMUNICATION_MATRIX_DARK_GREY
                    }
                  />
                  {action.targetName}
                </p>
              </p>,
              <p
                className={classnames({
                  [styles.candidateRow]: action.targetType === EnumEntityActionTargetType.TT,
                })}
              >
                <p
                  className={classnames(
                    styles.comMatrixCell,
                    styles.comMatrixCellXS,
                    styles.report
                  )}
                >
                  {action.report}
                  {action.type !== EnumEntityActionType.ACTION_OTHER && (
                    <SmallLink
                      leftIcon={<Eye />}
                      title={'voir détails'}
                      onClick={() =>
                        history.push({
                          pathname: ROUTES.ACTION_VIEW({
                            actionId: action.actionId ?? '',
                            actionType: action.type,
                          }),
                          state: { background: location },
                        })
                      }
                    />
                  )}
                </p>
              </p>,
              <p
                className={classnames({
                  [styles.candidateRow]: action.targetType === EnumEntityActionTargetType.TT,
                })}
              >
                <p className={classnames(styles.comMatrixCell, styles.comMatrixCellXS)}>
                  {moment(action.endDate).format('DD/MM/YYYY à HH:mm')}
                </p>
              </p>,

              moment(action.endDate).isBefore(moment()) ? (
                <p
                  className={classnames(
                    styles.comMatrixCell,
                    styles.comMatrixCellXS,
                    styles.progressionCell,
                    styles.statusCell,
                    styles.statusLateCell
                  )}
                >
                  en retard
                </p>
              ) : (
                <p
                  className={classnames(
                    styles.comMatrixCell,
                    styles.comMatrixCellXS,
                    styles.progressionCell,
                    styles.statusCell,
                    styles.statusToDoCell
                  )}
                >
                  à faire
                </p>
              ),
              <p>
                <ActionMenu
                  action={action}
                  target={{
                    role: ROLE.CONTACT,
                    id: action.targetId ?? '',
                    name: action.targetName ?? '',
                    entryLocalId: action.localId,
                  }}
                />
              </p>,
            ])}
          />
        </div>
      </div>
    </HideableSection>
  );
};

export default AgendaWeekSection;
