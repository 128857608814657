import React from 'react';

import { Props } from './Login.types';
import styles from './Login.module.scss';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';

const Login = ({ appName, description, icon, style, titleStyle, onLogin, disabled }: Props) => (
  <div className={styles.container}>
    <div className={styles.modal} style={style}>
      <img src={icon} alt={`${appName} icon`} className={styles.logo} />
      <p className={styles.title} style={titleStyle}>
        bienvenue <br /> sur {appName}
      </p>
      <p className={styles.description}>{description}</p>
      <div className={styles.buttonContainer}>
        <Button.Tertiary className={styles.button} disabled={disabled} onClick={onLogin}>
          se connecter
        </Button.Tertiary>
      </div>
    </div>
  </div>
);

export default Login;
