export enum PRODUCTION_IMPACT_TYPES {
  LINE_STOP = 'arrêt en ligne',
  CADENCE_SLOW_DOWN = 'ralentissement de la cadence',
  QUALITY_ISSUE = 'rebus non qualité / sur qualité',
  DELIVERY_DELAY = 'retard de livraison',
}

export enum OtherProductionImpactType {
  other = 'autre',
}

export type ProductionImpactType = PRODUCTION_IMPACT_TYPES | OtherProductionImpactType;

export interface Props {
  className?: string;
  selection: ProductionImpactType | undefined;
  onSelectionChange: (value?: ProductionImpactType) => void;
}
