import React from 'react';

import { Auth0Provider } from './auth0-wrapper';

const onRedirectCallback = () => {
  // Nothing to do here, redirection logic is handled by the Callback component
};

const Provider = (props: { children: React.ReactElement }) => (
  <Auth0Provider
    cacheLocation="localstorage"
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
    client_id={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
    redirect_uri={process.env.REACT_APP_AUTH0_REDIRECT_URI || ''}
    onRedirectCallback={onRedirectCallback}
  >
    {props.children}
  </Auth0Provider>
);

export default Provider;
