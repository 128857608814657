import {
  Button,
  Modal,
  PopupActions,
  PopupMenu,
  PopupMenuContext,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useCallback, useRef, useState } from 'react';
import styles from './MobileFiltersMenu.module.scss';
import classnames from 'classnames';
import {
  Filters,
  Share,
  ThreeDots,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { LocationState } from 'src/Services/Routing';
import { useHistory, useLocation } from 'react-router-dom';
import FiltersMenuContent from 'src/Components/FiltersMenu/FiltersMenuContent/FiltersMenuContent.component';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCardDisplayMode,
  getOnMissionOnlyFilter,
  getSortBy,
} from 'src/Redux/UserPreferences/Selectors';
import { Props } from './MobileFiltersMenu.types';
import { userPreferencesActions } from 'src/Redux/UserPreferences/Slice';
import { DISPLAY_MODE, SORT_BY } from 'src/Redux/UserPreferences/Types';

enum MobileFilterMenuStep {
  BASE,
  FILTERS,
}

const MobileFiltersMenu = ({ displayedSection }: Props) => {
  const displayMode = useSelector(getCardDisplayMode);
  const sortBy = useSelector(getSortBy);
  const dispatch = useDispatch();
  const onMissionOnly = useSelector(getOnMissionOnlyFilter);
  const [localSortBy, setLocalSortBy] = useState(sortBy);
  const [localDisplayMode, setLocalDisplayMode] = useState(displayMode);
  const [step, setStep] = useState(MobileFilterMenuStep.BASE);
  const [localOnMissionOnly, setLocalOnMissionOnly] = useState(onMissionOnly);
  const [isOpened, setIsOpened] = useState(false);
  const mobileModalRef = useRef<PopupActions>(null);
  const history = useHistory();
  const location = useLocation<LocationState>();
  const onDisplayModeChange = useCallback(
    (displayMode: DISPLAY_MODE) => {
      dispatch(userPreferencesActions.setCardDisplayMode(displayMode));
    },
    [dispatch]
  );
  const onMissionOnlyChange = useCallback(
    (onMissionOnly: boolean) => {
      dispatch(userPreferencesActions.setOnMissionOnlyFilter(onMissionOnly));
    },
    [dispatch]
  );
  const onSortByChange = useCallback(
    (sortBy: SORT_BY) => {
      dispatch(userPreferencesActions.setSortBy(sortBy));
    },
    [dispatch]
  );
  const deviceWidth = window.innerWidth;
  const cancelButton = (
    <Button.Tertiary
      className={styles.mobileButton}
      onClick={() => {
        mobileModalRef.current?.close();
      }}
    >
      annuler
    </Button.Tertiary>
  );
  return (
    <Modal
      onOpen={() => {
        setLocalDisplayMode(displayMode);
        setLocalSortBy(sortBy);
        setLocalOnMissionOnly(onMissionOnly);
        setIsOpened(true);
      }}
      title={<></>}
      hideCloseAction
      contentStyle={{ width: deviceWidth - 32 }}
      ref={mobileModalRef}
      footerActionsRight={
        step === MobileFilterMenuStep.BASE
          ? [cancelButton]
          : [
              cancelButton,
              <Button.Primary
                className={styles.mobileButton}
                onClick={() => {
                  onDisplayModeChange(localDisplayMode);
                  onSortByChange(localSortBy);
                  onMissionOnlyChange(localOnMissionOnly);
                  mobileModalRef.current?.close();
                }}
              >
                valider
              </Button.Primary>,
            ]
      }
      onClose={() => {
        setStep(MobileFilterMenuStep.BASE);
        setIsOpened(false);
      }}
      trigger={
        <div>
          <Button.Tertiary
            className={classnames(styles.triggerButton, { [styles.openButton]: isOpened })}
          >
            <ThreeDots className={styles.menuIcon} />
          </Button.Tertiary>
        </div>
      }
    >
      <PopupMenuContext.Provider value={() => ({ actions: null })}>
        <div className={styles.globalModalContainer}>
          <div className={styles.modalContent}>
            {step === MobileFilterMenuStep.BASE && (
              <>
                <PopupMenu.Item
                  text="trier / filtrer"
                  icon={<Filters />}
                  hasSubMenu
                  onClick={() => setStep(MobileFilterMenuStep.FILTERS)}
                />
                <PopupMenu.Item
                  text="partager"
                  icon={<Share />}
                  onClick={() => {
                    history.push({
                      pathname: '/export',
                      state: {
                        background: location,
                      },
                    });
                    mobileModalRef.current?.close();
                  }}
                />
              </>
            )}
            {step === MobileFilterMenuStep.FILTERS && (
              <FiltersMenuContent
                setLocalDisplayMode={setLocalDisplayMode}
                setLocalSortBy={setLocalSortBy}
                localDisplayMode={localDisplayMode}
                localSortBy={localSortBy}
                mobileLocalOnMissionOnly={localOnMissionOnly}
                mobileDisplayedSection={displayedSection}
                setMobileLocalOnMissionOnly={setLocalOnMissionOnly}
              />
            )}
          </div>
        </div>
      </PopupMenuContext.Provider>
    </Modal>
  );
};

export default MobileFiltersMenu;
