import React, { useEffect, useState } from 'react';

import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import { OwnProps } from './SelectAbsenceReason.types';
import { AbsenceReason } from 'src/Services/API';
import nullify from 'src/Utils/nullify';

const SelectAbsenceReason = ({ selection, onSelectionChange, reasons }: OwnProps) => {
  const [selectedReason, setSelecledReason] = useState<AbsenceReason | undefined>(undefined);
  useEffect(() => {
    if (reasons.length > 0) setSelecledReason(reasons.find(reason => reason.id === selection?.id));
  }, [reasons, selection]);
  return (
    <ComboBox
      id="selectAbsenceReason"
      value={selectedReason ?? null}
      placeholder="sélectionnez"
      searchResults={reasons}
      useNativeOptionFiltering
      keyValueExtractor={(searchResult: AbsenceReason) => ({
        key: searchResult.id ?? '',
        value: searchResult.label ?? '',
      })}
      onChange={nullify(onSelectionChange)}
    />
  );
};

export default SelectAbsenceReason;
