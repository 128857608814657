import { PopupMenu, Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import { DISPLAYED_SECTION, DISPLAY_MODE, SORT_BY } from 'src/Redux/UserPreferences/Types';
import { displayModes, sortingChoices } from '../FiltersMenu.component';
import FiltersValidateButton from '../FiltersValidateButton';
import styles from './FiltersMenuContent.module.scss';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplaySection, getOnMissionOnlyFilter } from 'src/Redux/UserPreferences/Selectors';
import { userPreferencesActions } from 'src/Redux/UserPreferences/Slice';
import { Props } from './FiltersMenuContent.types';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';
import classnames from 'classnames';
import { Check } from '@randstad-lean-mobile-factory/react-components-ui-shared';

const FiltersMenuContent = ({
  setLocalDisplayMode,
  setLocalSortBy,
  setMobileLocalOnMissionOnly,
  localDisplayMode,
  localSortBy,
  mobileDisplayedSection,
  mobileLocalOnMissionOnly,
}: Props) => {
  const dispatch = useDispatch();
  const onMissionOnly = useSelector(getOnMissionOnlyFilter);
  const [localOnMissionOnly, setLocalOnMissionOnly] = useState(onMissionOnly);
  const onDisplayModeChange = useCallback(
    (displayMode: DISPLAY_MODE) => {
      dispatch(userPreferencesActions.setCardDisplayMode(displayMode));
    },
    [dispatch]
  );
  const onMissionOnlyChange = useCallback(
    (onMissionOnly: boolean) => {
      dispatch(userPreferencesActions.setOnMissionOnlyFilter(onMissionOnly));
    },
    [dispatch]
  );
  const onSortByChange = useCallback(
    (sortBy: SORT_BY) => {
      dispatch(userPreferencesActions.setSortBy(sortBy));
    },
    [dispatch]
  );
  const displaySection = useSelector(getDisplaySection);
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  return (
    <>
      <div className={styles.title}>type d'affichage</div>
      {displayModes.map(dpm => {
        return (
          <PopupMenu.Item
            key={dpm.name}
            icon={dpm.icon}
            text={dpm.name}
            onClick={() => {
              setLocalDisplayMode(dpm.mode);
            }}
            id={dpm.name}
            keepOpenOnClick
            rightIcon={
              isMobile && dpm.mode === localDisplayMode && <Check className={styles.checkIcon} />
            }
            className={classnames({ [styles.selectedPopupItem]: dpm.mode === localDisplayMode })}
          />
        );
      })}
      {(displaySection === DISPLAYED_SECTION.INTERIM ||
        mobileDisplayedSection === DISPLAYED_SECTION.INTERIM) && (
        <>
          <div className={styles.title}>trier par</div>
          {sortingChoices.map(item => (
            <PopupMenu.Item
              key={item.name}
              text={item.name}
              onClick={() => {
                setLocalSortBy(item.mode);
              }}
              id={item.name}
              keepOpenOnClick
              rightIcon={
                isMobile && item.mode === localSortBy && <Check className={styles.checkIcon} />
              }
              className={classnames({ [styles.selectedPopupItem]: item.mode === localSortBy })}
            />
          ))}
          <div className={styles.title}>filtres</div>
          <div className={styles.checkBox}>
            <Checkbox
              checked={isMobile ? mobileLocalOnMissionOnly : localOnMissionOnly}
              onClick={() =>
                isMobile
                  ? setMobileLocalOnMissionOnly?.(!mobileLocalOnMissionOnly)
                  : setLocalOnMissionOnly(!localOnMissionOnly)
              }
            >
              <p className={styles.checkboxLabel}>TT en poste uniquement</p>
            </Checkbox>
          </div>
        </>
      )}
      {!isMobile && (
        <div className={styles.validateButtonContainer}>
          <FiltersValidateButton
            onClick={() => {
              onDisplayModeChange(localDisplayMode);
              onSortByChange(localSortBy);
              onMissionOnlyChange(localOnMissionOnly);
            }}
          />
        </div>
      )}
    </>
  );
};

export default FiltersMenuContent;
