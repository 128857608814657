import { DISPLAYED_SECTION } from 'src/Redux/UserPreferences/Types';

export const responsiveQuery = '(max-device-width: 768px)';

export function sectionFromMobileToDesktop(displaySection: DISPLAYED_SECTION): DISPLAYED_SECTION {
  switch (displaySection) {
    case DISPLAYED_SECTION.LISTS:
      return DISPLAYED_SECTION.INTERIM;
    case DISPLAYED_SECTION.APPLICATIONS:
      return DISPLAYED_SECTION.DASHBOARD;
    case DISPLAYED_SECTION.PARAMETERS:
      return DISPLAYED_SECTION.DASHBOARD;
    case DISPLAYED_SECTION.TASKS:
      return DISPLAYED_SECTION.AGENDA;
    case DISPLAYED_SECTION.INTERIM:
      return DISPLAYED_SECTION.INTERIM;
    case DISPLAYED_SECTION.CLIENT:
      return DISPLAYED_SECTION.CLIENT;
    case DISPLAYED_SECTION.SERVICE:
      return DISPLAYED_SECTION.SERVICE;
    case DISPLAYED_SECTION.AGENDA:
      return DISPLAYED_SECTION.AGENDA;
    default:
      return DISPLAYED_SECTION.DASHBOARD;
  }
}

export function sectionFromDesktopToMobile(displaySection: DISPLAYED_SECTION): DISPLAYED_SECTION {
  switch (displaySection) {
    case DISPLAYED_SECTION.INTERIM:
      return DISPLAYED_SECTION.LISTS;
    case DISPLAYED_SECTION.CLIENT:
      return DISPLAYED_SECTION.LISTS;
    case DISPLAYED_SECTION.SERVICE:
      return DISPLAYED_SECTION.LISTS;
    case DISPLAYED_SECTION.AGENDA:
      return DISPLAYED_SECTION.TASKS;
    case DISPLAYED_SECTION.LISTS:
      return DISPLAYED_SECTION.LISTS;
    case DISPLAYED_SECTION.APPLICATIONS:
      return DISPLAYED_SECTION.APPLICATIONS;
    case DISPLAYED_SECTION.PARAMETERS:
      return DISPLAYED_SECTION.PARAMETERS;
    case DISPLAYED_SECTION.TASKS:
      return DISPLAYED_SECTION.TASKS;
    default:
      return DISPLAYED_SECTION.LISTS;
  }
}
