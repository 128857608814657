import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CGC, RepositoriesService } from 'src/Services/API';
import { QueryKeys } from '..';

const useFetchCGC = (extraOptions?: UseQueryOptions<CGC[], unknown, CGC[], QueryKeys[]>) => {
  return useQuery(
    [QueryKeys.FETCH_CGC],
    async () => {
      return await RepositoriesService.repositoriesControllerGetOpenCgc();
    },
    { refetchOnWindowFocus: false, ...extraOptions }
  );
};

export default useFetchCGC;
