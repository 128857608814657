import { ActionsService, EnumCreateActionBodyType } from 'src/Services/API';
import { db } from 'src/Services/DB';
import { Target } from 'src/Services/Routing';

export class ProductionImpact {
  target?: Target;
  type?: string;
  customType?: string;
  report?: string;
  startDate?: Date;
  endDate?: Date;
}

export interface ProductionImpactExtended extends ProductionImpact {
  brandCode: string;
  agencyId: string;
  companyId: string;
}

export const createProductionImpact = async ({
  localId,
  brandCode,
  agencyId,
  companyId,
}: ProductionImpactExtended & { localId: number }) => {
  const productionImpact = await db.productionImpact.get(localId);
  if (productionImpact === undefined) {
    throw new Error(`Production Impact ${localId} does not exist in local DB`);
  }
  ActionsService.actionsControllerPostCreateAction({
    body: {
      companyId,
      targetId: productionImpact?.target?.id ?? '',
      brandCode: brandCode ?? '',
      agencyId: agencyId ?? '',
      startDate: productionImpact.startDate ?? new Date(),
      type: EnumCreateActionBodyType.ACTION_PRODUCTION_IMPACT,
      subType: productionImpact.customType,
      report: productionImpact.report ?? '',
      endDate: productionImpact.endDate ?? new Date(),
    },
  });
};

export const productionImpactMutationsConfig = {
  'productionimpacts/create': {
    mutationFn: createProductionImpact,
    onSuccess: async (_: unknown, { localId }: Parameters<typeof createProductionImpact>[0]) => {
      await db.productionImpact.delete(localId);
    },
    // Without a retry, mutations done while offline will fail instead of being paused
    retry: 1,
  },
};
