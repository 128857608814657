import React from 'react';

import { Props } from './SectionHeader.types';
import styles from './SectionHeader.module.scss';
import classnames from 'classnames';

const SectionHeader = ({ value, isMobile }: Props) => (
  <div className={classnames(styles.container, { [styles.mobileContainer]: isMobile })}>
    {value}
  </div>
);

export default SectionHeader;
