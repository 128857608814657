import React from 'react';

import { Props } from './HistoryCard.types';
import styles from './HistoryCard.module.scss';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';
import classnames from 'classnames';

const HistoryCard = ({ title, subtitle, secondSubtitle, onClick, rightElement }: Props) => {
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  return (
    <div
      className={classnames(styles.container, { [styles.mobileContainer]: isMobile })}
      onClick={onClick}
    >
      <div className={styles.leftContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subTitle}>{subtitle}</div>
        <div className={styles.subTitle}>{secondSubtitle}</div>
      </div>
      <div className={styles.rightContainer}>{rightElement}</div>
    </div>
  );
};

export default HistoryCard;
