import { CompaniesService, EnumCommunicationMatrixEntryFrequencyUnit } from '../API';
import { db } from '../DB';

export const insertContactInCommunicationMatrix = async ({
  localId,
  brandCode,
  agencyId,
  companyId,
}: {
  localId: number;
  brandCode: string;
  agencyId: string;
  companyId?: string;
}) => {
  if (companyId === undefined) {
    throw new Error(`companyId is undefined`);
  }

  const insertion = await db.communicationMatrix.get(localId);

  if (insertion === undefined) {
    throw new Error(`Communication Matrix entry ${localId} does not exist in local DB`);
  }

  return await CompaniesService.companiesControllerPostCommunicationMatrix({
    id: companyId,
    body: { brandCode, agencyId, ...insertion },
  });
};

export const updateContactInCommunicationMatrix = async ({
  localId,
  brandCode,
  agencyId,
  companyId,
}: {
  localId: number;
  brandCode: string;
  agencyId: string;
  companyId?: string;
}) => {
  if (companyId === undefined) {
    throw new Error(`companyId is undefined`);
  }

  const update = await db.communicationMatrix.get(localId);

  if (update === undefined) {
    throw new Error(`Communication Matrix entry ${localId} does not exist in local DB`);
  }

  const { frequencyUnit, entryId, ...rest } = update;

  if (entryId === undefined) {
    throw new Error(
      `Communication Matrix entry ${localId} can not be updated if entryId is undefined`
    );
  }

  return await CompaniesService.companiesControllerUpdateCommunicationMatrix({
    id: companyId,
    body: {
      brandCode,
      agencyId,
      frequencyUnit: EnumCommunicationMatrixEntryFrequencyUnit[frequencyUnit],
      entryId,
      ...rest,
    },
  });
};

export const removeContactInCommunicationMatrix = async ({
  contactId,
  brandCode,
  agencyId,
  companyId,
}: {
  contactId: string;
  brandCode: string;
  agencyId: string;
  companyId?: string;
}) => {
  if (companyId === undefined) {
    throw new Error(`companyId is undefined`);
  }

  const entry = await db.communicationMatrix.where({ contactId }).first();

  if (entry === undefined) {
    throw new Error(`${contactId} does not exist in the communication matrix`);
  }

  await db.communicationMatrix.where({ contactId }).modify({ markedForDeletion: true });

  return await CompaniesService.companiesControllerDeleteCommunicationMatrix({
    id: companyId,
    body: { brandCode, agencyId, contactId, version: entry.version },
  });
};
