/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

import { Expose, Transform, Type, plainToClass } from 'class-transformer';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class ActionsService {
  /**
   *
   */
  static actionsControllerPostCreateAction(
    params: {
      /** requestBody */
      body?: CreateActionBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityAction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EntityAction, response)), reject);
    });
  }
  /**
   *
   */
  static actionsControllerPutUpdateAction(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CreateActionBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityAction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EntityAction, response)), reject);
    });
  }
}

export class ConsultantsService {
  /**
   *
   */
  static consultantsControllerGetPerimeter(
    options: IRequestOptions = {}
  ): Promise<ConsultantPerimeter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/perimeter';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(ConsultantPerimeter, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static consultantsControllerGetSiid(
    params: {
      /**  */
      email: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConsultantIdentifier> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/siid';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(ConsultantIdentifier, response)),
        reject
      );
    });
  }
}

export class CompaniesService {
  /**
   *
   */
  static companiesControllerGetContacts(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyContact[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/contacts';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CompanyContact, response)), reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCandidatesOnMission(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
      /**  */
      agencyId: string;
      /**  */
      limit?: number;
      /**  */
      offset?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateOnMission[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/candidates';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        brandCode: params['brandCode'],
        agencyId: params['agencyId'],
        limit: params['limit'],
        offset: params['offset'],
      };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CandidateOnMission, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static companiesControllerGetCandidatesOnMissionCount(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
      /**  */
      agencyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/candidates/count';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'], agencyId: params['agencyId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerPostCommunicationMatrix(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: AddCommunicationMatrixEntryBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommunicationMatrixEntry> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/communicationMatrixEntry';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(CommunicationMatrixEntry, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static companiesControllerDeleteCommunicationMatrix(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: DeleteCommunicationMatrixEntryBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/communicationMatrixEntry';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCommunicationMatrix(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
      /**  */
      agencyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommunicationMatrixEntry[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/communicationMatrix';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'], agencyId: params['agencyId'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CommunicationMatrixEntry, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static companiesControllerUpdateCommunicationMatrix(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateCommunicationMatrixEntryBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommunicationMatrixEntry> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/communicationMatrix';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(CommunicationMatrixEntry, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanies(
    params: {
      /** requestBody */
      body?: GetCompaniesBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanySearchResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CompanySearchResult, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static companiesControllerGetCompaniesFromWalletAgenciesLight(
    params: {
      /** requestBody */
      body?: GetCompaniesFromWalletAgenciesLightBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanySearchResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/walletAgenciesLight';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CompanySearchResult, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static companiesControllerGetCompaniesConsultantActions(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
      /**  */
      modifiedSince: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityAction[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/actions';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'], modifiedSince: params['modifiedSince'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(EntityAction, response)), reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCompaniesClaims(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
      /**  */
      modifiedSince: string;
      /**  */
      agencyId: string;
      /**  */
      startingDate: string;
      /**  */
      endingDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimEntity[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/claims';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        brandCode: params['brandCode'],
        modifiedSince: params['modifiedSince'],
        agencyId: params['agencyId'],
        startingDate: params['startingDate'],
        endingDate: params['endingDate'],
      };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(ClaimEntity, response)), reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetServices(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyService[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/services';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CompanyService, response)), reject);
    });
  }
}

export class ContactsService {
  /**
   *
   */
  static contactsControllerCreateContactClaim(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CreateClaimBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contacts/{id}/claim';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contactsControllerUpdateContactClaim(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateClaimBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contacts/{id}/claim';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ClaimsService {
  /**
   *
   */
  static claimsControllerGetClaimCategories(
    params: {
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimCategory[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/claims/categories';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(ClaimCategory, response)), reject);
    });
  }
}

export class CandidatesService {
  /**
   *
   */
  static candidatesControllerCreateCandidateClaim(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CreateClaimBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/claim';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerUpdateCandidateClaim(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateClaimBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/claim';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerCreateCandidateAbsence(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CreateAbsenceBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Absence> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/absence';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(Absence, response)), reject);
    });
  }
  /**
   *
   */
  static candidatesControllerUpdateCandidateAbsence(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateAbsenceBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Absence> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/absence';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(Absence, response)), reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetAgencyAllCandidate(
    params: {
      /**  */
      companyId: string;
      /**  */
      brandCode: string;
      /**  */
      agencyId: string;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AllCandidatesWithCount> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        companyId: params['companyId'],
        brandCode: params['brandCode'],
        agencyId: params['agencyId'],
        startDate: params['startDate'],
        endDate: params['endDate'],
      };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(AllCandidatesWithCount, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerUpdateCandidateSubscription(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateSubscriptionParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/pool/subscription';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerFetchPoolExitData(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PoolExitData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/pool/scheduledExit';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PoolExitData, response)), reject);
    });
  }
  /**
   *
   */
  static candidatesControllerDeleteScheduledPoolExit(
    params: {
      /** requestBody */
      body?: DeletePoolExitBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/pool/scheduledExit';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AbsenceService {
  /**
   *
   */
  static absenceControllerGetAbsenceReasons(
    options: IRequestOptions = {}
  ): Promise<AbsenceReason[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/absence/reasons';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(AbsenceReason, response)), reject);
    });
  }
  /**
   *
   */
  static absenceControllerGetAbsences(
    params: {
      /**  */
      agencyId: string;
      /**  */
      companyId: string;
      /**  */
      since: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Absence[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/absence';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        agencyId: params['agencyId'],
        companyId: params['companyId'],
        since: params['since'],
      };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Absence, response)), reject);
    });
  }
  /**
   *
   */
  static absenceControllerDeleteCandidateAbsence(
    params: {
      /** requestBody */
      body?: DeleteAbsenceBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/absence';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RepositoriesService {
  /**
   *
   */
  static repositoriesControllerGetPoolExitMotivations(
    options: IRequestOptions = {}
  ): Promise<IdLabel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/pool/motivations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(IdLabel, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetPoolExitSenders(
    options: IRequestOptions = {}
  ): Promise<IdLabel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/pool/senders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(IdLabel, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetOpenCgc(options: IRequestOptions = {}): Promise<CGC[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/cgc';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CGC, response)), reject);
    });
  }
}

export class ExportService {
  /**
   *
   */
  static exportControllerGetExportData(
    params: {
      /** requestBody */
      body?: GetExportDataQueryParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/export/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CreateActionBody {
  /**  */
  'status'?: EnumEntityActionStatus;

  /**  */
  'targetId': string;

  /**  */
  'targetName'?: string;

  /**  */
  'report'?: string;

  /**  */
  'title'?: string;

  /**  */
  'type': EnumCreateActionBodyType;

  /**  */
  'agencyId': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  'brandCode': string;

  /**  */
  'subType'?: string;

  /**  */
  'companyId': string;

  /**  */
  'duration'?: number;

  constructor(data: undefined | any = {}) {
    this['status'] = data['status'];
    this['targetId'] = data['targetId'];
    this['targetName'] = data['targetName'];
    this['report'] = data['report'];
    this['title'] = data['title'];
    this['type'] = data['type'];
    this['agencyId'] = data['agencyId'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['brandCode'] = data['brandCode'];
    this['subType'] = data['subType'];
    this['companyId'] = data['companyId'];
    this['duration'] = data['duration'];
  }
}

export class EntityAction {
  /**  */
  'status'?: EnumEntityActionStatus;

  /**  */
  'actionId'?: string;

  /**  */
  'targetName'?: string;

  /**  */
  'targetId': string;

  /**  */
  'type': EnumEntityActionType;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  'agencyId'?: string;

  /**  */
  'report'?: string;

  /**  */
  'title'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'creationDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'lastModificationDate'?: Date;

  /**  */
  'consultantId'?: string;

  /**  */
  'consultantName'?: string;

  /**  */
  'subType'?: string;

  /**  */
  'targetType'?: EnumEntityActionTargetType;

  constructor(data: undefined | any = {}) {
    this['status'] = data['status'];
    this['actionId'] = data['actionId'];
    this['targetName'] = data['targetName'];
    this['targetId'] = data['targetId'];
    this['type'] = data['type'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['agencyId'] = data['agencyId'];
    this['report'] = data['report'];
    this['title'] = data['title'];
    this['creationDate'] = data['creationDate'];
    this['lastModificationDate'] = data['lastModificationDate'];
    this['consultantId'] = data['consultantId'];
    this['consultantName'] = data['consultantName'];
    this['subType'] = data['subType'];
    this['targetType'] = data['targetType'];
  }
}

export class OSMConsultantModelDtoBrandDto {
  /**  */
  'code'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['code'] = data['code'];
    this['name'] = data['name'];
  }
}

export class OSMConsultantModelDtoSpecialityDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMConsultantModelDtoConsultantAgencyByCompany {
  /**  */
  'societyId'?: string;

  /**  */
  'brand'?: OSMConsultantModelDtoBrandDto;

  /**  */
  'brandCodeApiHeader'?: string;

  /**  */
  'agencies'?: string[];

  /**  */
  'specialities'?: OSMConsultantModelDtoSpecialityDto[];

  constructor(data: undefined | any = {}) {
    this['societyId'] = data['societyId'];
    this['brand'] = data['brand'];
    this['brandCodeApiHeader'] = data['brandCodeApiHeader'];
    this['agencies'] = data['agencies'];
    this['specialities'] = data['specialities'];
  }
}

export class ConsultantPerimeter {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'branchId'?: string;

  /**  */
  'regionId'?: string;

  /**  */
  'zoneId'?: string;

  /**  */
  'defaultAgencyId'?: string;

  /**  */
  'defaultCompanyId'?: string;

  /**  */
  'companies'?: OSMConsultantModelDtoConsultantAgencyByCompany[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['branchId'] = data['branchId'];
    this['regionId'] = data['regionId'];
    this['zoneId'] = data['zoneId'];
    this['defaultAgencyId'] = data['defaultAgencyId'];
    this['defaultCompanyId'] = data['defaultCompanyId'];
    this['companies'] = data['companies'];
  }
}

export class ConsultantIdentifier {
  /**  */
  'societyIdentifier'?: string;

  constructor(data: undefined | any = {}) {
    this['societyIdentifier'] = data['societyIdentifier'];
  }
}

export class OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto {
  /**  */
  'id'?: number;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCompanyModelCompanyDtoCompanyContactServicesDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class CompanyContact {
  /**  */
  'contactId'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'name'?: string;

  /**  */
  'position'?: string;

  /**  */
  'statut'?: string;

  /**  */
  'mobilePhoneNumber'?: string;

  /**  */
  'phone'?: string;

  /**  */
  'email'?: string;

  /**  */
  'isActiveOnCurrentCompany'?: boolean;

  /**  */
  'mainContact'?: boolean;

  /**  */
  'myContact'?: boolean;

  /**  */
  'dontMailMe'?: boolean;

  /**  */
  'dontCallMe'?: boolean;

  /**  */
  'dontPostMe'?: boolean;

  /**  */
  'dontPlaceMe'?: boolean;

  /**  */
  'civility'?: OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto;

  /**  */
  'services'?: OSMCompanyModelCompanyDtoCompanyContactServicesDto[];

  /**  */
  'portalAccess'?: boolean;

  constructor(data: undefined | any = {}) {
    this['contactId'] = data['contactId'];
    this['firstName'] = data['firstName'];
    this['name'] = data['name'];
    this['position'] = data['position'];
    this['statut'] = data['statut'];
    this['mobilePhoneNumber'] = data['mobilePhoneNumber'];
    this['phone'] = data['phone'];
    this['email'] = data['email'];
    this['isActiveOnCurrentCompany'] = data['isActiveOnCurrentCompany'];
    this['mainContact'] = data['mainContact'];
    this['myContact'] = data['myContact'];
    this['dontMailMe'] = data['dontMailMe'];
    this['dontCallMe'] = data['dontCallMe'];
    this['dontPostMe'] = data['dontPostMe'];
    this['dontPlaceMe'] = data['dontPlaceMe'];
    this['civility'] = data['civility'];
    this['services'] = data['services'];
    this['portalAccess'] = data['portalAccess'];
  }
}

export class OSMRepositoriesModelSkillRepositoryDtoSkillDto {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMServiceModelDtoServiceBasicDto {
  /**  */
  'serviceId'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['serviceId'] = data['serviceId'];
    this['name'] = data['name'];
  }
}

export class CandidateOnMission {
  /**  */
  'candidateId'?: string;

  /**  */
  'gender'?: string;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'qualification'?: OSMRepositoriesModelSkillRepositoryDtoSkillDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'contractStartdDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'contractRealEndDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'contractExpectedEndDate'?: Date;

  /**  */
  'contractId'?: string;

  /**  */
  'missionAgencyId'?: string;

  /**  */
  'service'?: OSMServiceModelDtoServiceBasicDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'contractStartdDateFlexibility'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'contractRealEndDateFlexibility'?: Date;

  constructor(data: undefined | any = {}) {
    this['candidateId'] = data['candidateId'];
    this['gender'] = data['gender'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['qualification'] = data['qualification'];
    this['contractStartdDate'] = data['contractStartdDate'];
    this['contractRealEndDate'] = data['contractRealEndDate'];
    this['contractExpectedEndDate'] = data['contractExpectedEndDate'];
    this['contractId'] = data['contractId'];
    this['missionAgencyId'] = data['missionAgencyId'];
    this['service'] = data['service'];
    this['contractStartdDateFlexibility'] = data['contractStartdDateFlexibility'];
    this['contractRealEndDateFlexibility'] = data['contractRealEndDateFlexibility'];
  }
}

export class AddCommunicationMatrixEntryBody {
  /**  */
  'frequencyUnit': EnumCommunicationMatrixEntryFrequencyUnit;

  /**  */
  'brandCode': string;

  /**  */
  'agencyId': string;

  /**  */
  'contactId': string;

  /**  */
  'frequency': number;

  constructor(data: undefined | any = {}) {
    this['frequencyUnit'] = data['frequencyUnit'];
    this['brandCode'] = data['brandCode'];
    this['agencyId'] = data['agencyId'];
    this['contactId'] = data['contactId'];
    this['frequency'] = data['frequency'];
  }
}

export class CommunicationMatrixEntry {
  /**  */
  'agencyId': string;

  /**  */
  'companyId': string;

  /**  */
  'brandCode': string;

  /**  */
  'contactId': string;

  /**  */
  'frequency': number;

  /**  */
  'frequencyUnit': EnumCommunicationMatrixEntryFrequencyUnit;

  /**  */
  'version': number;

  /**  */
  'entryId': string;

  constructor(data: undefined | any = {}) {
    this['agencyId'] = data['agencyId'];
    this['companyId'] = data['companyId'];
    this['brandCode'] = data['brandCode'];
    this['contactId'] = data['contactId'];
    this['frequency'] = data['frequency'];
    this['frequencyUnit'] = data['frequencyUnit'];
    this['version'] = data['version'];
    this['entryId'] = data['entryId'];
  }
}

export class DeleteCommunicationMatrixEntryBody {
  /**  */
  'brandCode': string;

  /**  */
  'agencyId': string;

  /**  */
  'contactId': string;

  /**  */
  'version': number;

  constructor(data: undefined | any = {}) {
    this['brandCode'] = data['brandCode'];
    this['agencyId'] = data['agencyId'];
    this['contactId'] = data['contactId'];
    this['version'] = data['version'];
  }
}

export class UpdateCommunicationMatrixEntryBody {
  /**  */
  'frequencyUnit': EnumCommunicationMatrixEntryFrequencyUnit;

  /**  */
  'brandCode': string;

  /**  */
  'agencyId': string;

  /**  */
  'contactId': string;

  /**  */
  'frequency': number;

  /**  */
  'version': number;

  /**  */
  'entryId': string;

  constructor(data: undefined | any = {}) {
    this['frequencyUnit'] = data['frequencyUnit'];
    this['brandCode'] = data['brandCode'];
    this['agencyId'] = data['agencyId'];
    this['contactId'] = data['contactId'];
    this['frequency'] = data['frequency'];
    this['version'] = data['version'];
    this['entryId'] = data['entryId'];
  }
}

export class GetCompaniesBody {
  /**  */
  'keyword': string;

  /**  */
  'agencies': string[];

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['keyword'] = data['keyword'];
    this['agencies'] = data['agencies'];
    this['brandCode'] = data['brandCode'];
  }
}

export class OSMCompanyModelCompanyDtoCompanyAddressDto {
  /**  */
  'address1'?: string;

  /**  */
  'address2'?: string;

  /**  */
  'address3'?: string;

  /**  */
  'postalCode'?: string;

  /**  */
  'city'?: string;

  constructor(data: undefined | any = {}) {
    this['address1'] = data['address1'];
    this['address2'] = data['address2'];
    this['address3'] = data['address3'];
    this['postalCode'] = data['postalCode'];
    this['city'] = data['city'];
  }
}

export class CompanySearchResult {
  /**  */
  'companyAddress'?: OSMCompanyModelCompanyDtoCompanyAddressDto;

  /**  */
  'status'?: string;

  /**  */
  'companyId'?: string;

  /**  */
  'companyName'?: string;

  /**  */
  'siret'?: string;

  /**  */
  'solvency'?: string;

  constructor(data: undefined | any = {}) {
    this['companyAddress'] = data['companyAddress'];
    this['status'] = data['status'];
    this['companyId'] = data['companyId'];
    this['companyName'] = data['companyName'];
    this['siret'] = data['siret'];
    this['solvency'] = data['solvency'];
  }
}

export class GetCompaniesFromWalletAgenciesLightBody {
  /**  */
  'agencies': string[];

  /**  */
  'offset'?: number;

  /**  */
  'limit'?: number;

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['agencies'] = data['agencies'];
    this['offset'] = data['offset'];
    this['limit'] = data['limit'];
    this['brandCode'] = data['brandCode'];
  }
}

export class OSMCompanyModelCompanyDtoCompanyCategoryClaimsDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCompanyModelCompanyDtoClaimReasonDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCompanyModelCompanyDtoCompanyContactClaimsDto {
  /**  */
  'id'?: string;

  /**  */
  'civility'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'name'?: string;

  /**  */
  'position'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['civility'] = data['civility'];
    this['firstName'] = data['firstName'];
    this['name'] = data['name'];
    this['position'] = data['position'];
  }
}

export class OSMCompanyModelCompanyDtoCandidateDto {
  /**  */
  'id'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'name'?: string;

  /**  */
  'zipCode'?: string;

  /**  */
  'inseeCode'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['firstName'] = data['firstName'];
    this['name'] = data['name'];
    this['zipCode'] = data['zipCode'];
    this['inseeCode'] = data['inseeCode'];
  }
}

export class ClaimEntity {
  /**  */
  'type'?: TargetType;

  /**  */
  'claimId'?: string;

  /**  */
  'category'?: OSMCompanyModelCompanyDtoCompanyCategoryClaimsDto;

  /**  */
  'reason'?: OSMCompanyModelCompanyDtoClaimReasonDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'opening': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'closing'?: Date;

  /**  */
  'companyId'?: string;

  /**  */
  'agencyId'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'modificationDate'?: Date;

  /**  */
  'customerContact'?: OSMCompanyModelCompanyDtoCompanyContactClaimsDto;

  /**  */
  'candidate'?: OSMCompanyModelCompanyDtoCandidateDto;

  /**  */
  'comment'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'creationDateTime'?: Date;

  constructor(data: undefined | any = {}) {
    this['type'] = data['type'];
    this['claimId'] = data['claimId'];
    this['category'] = data['category'];
    this['reason'] = data['reason'];
    this['opening'] = data['opening'];
    this['closing'] = data['closing'];
    this['companyId'] = data['companyId'];
    this['agencyId'] = data['agencyId'];
    this['modificationDate'] = data['modificationDate'];
    this['customerContact'] = data['customerContact'];
    this['candidate'] = data['candidate'];
    this['comment'] = data['comment'];
    this['creationDateTime'] = data['creationDateTime'];
  }
}

export class CompanyService {
  /**  */
  'active': boolean;

  /**  */
  'serviceId'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['active'] = data['active'];
    this['serviceId'] = data['serviceId'];
    this['name'] = data['name'];
  }
}

export class CreateClaimBody {
  /**  */
  'brandCode': string;

  /**  */
  'agencyId': string;

  /**  */
  'companyId': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'opening': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'closing'?: Date;

  /**  */
  'categoryId': string;

  /**  */
  'reasonId': string;

  /**  */
  'candidateId'?: string;

  /**  */
  'customerContactId'?: string;

  /**  */
  'comment'?: string;

  constructor(data: undefined | any = {}) {
    this['brandCode'] = data['brandCode'];
    this['agencyId'] = data['agencyId'];
    this['companyId'] = data['companyId'];
    this['opening'] = data['opening'];
    this['closing'] = data['closing'];
    this['categoryId'] = data['categoryId'];
    this['reasonId'] = data['reasonId'];
    this['candidateId'] = data['candidateId'];
    this['customerContactId'] = data['customerContactId'];
    this['comment'] = data['comment'];
  }
}

export class UpdateClaimBody {
  /**  */
  'brandCode': string;

  /**  */
  'agencyId': string;

  /**  */
  'companyId': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'opening': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'closing'?: Date;

  /**  */
  'categoryId': string;

  /**  */
  'reasonId': string;

  /**  */
  'candidateId'?: string;

  /**  */
  'customerContactId'?: string;

  /**  */
  'comment'?: string;

  /**  */
  'claimId': string;

  constructor(data: undefined | any = {}) {
    this['brandCode'] = data['brandCode'];
    this['agencyId'] = data['agencyId'];
    this['companyId'] = data['companyId'];
    this['opening'] = data['opening'];
    this['closing'] = data['closing'];
    this['categoryId'] = data['categoryId'];
    this['reasonId'] = data['reasonId'];
    this['candidateId'] = data['candidateId'];
    this['customerContactId'] = data['customerContactId'];
    this['comment'] = data['comment'];
    this['claimId'] = data['claimId'];
  }
}

export class OSMRepositoriesModelClaimCategoryRepositoryDtoClaimReasonDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class ClaimCategory {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  /**  */
  'societyCode'?: string;

  /**  */
  'reasons'?: OSMRepositoriesModelClaimCategoryRepositoryDtoClaimReasonDto[];

  /**  */
  'isCandidateCategory': boolean;

  /**  */
  'isContactCategory': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['societyCode'] = data['societyCode'];
    this['reasons'] = data['reasons'];
    this['isCandidateCategory'] = data['isCandidateCategory'];
    this['isContactCategory'] = data['isContactCategory'];
  }
}

export class AbsenceOnMonth {
  /**  */
  'month': string;

  /**  */
  'absence': number;

  constructor(data: undefined | any = {}) {
    this['month'] = data['month'];
    this['absence'] = data['absence'];
  }
}

export class CreateAbsenceBody {
  /**  */
  'timeInterval': EnumAbsenceTimeInterval;

  /**  */
  'agencyId': string;

  /**  */
  'candidateName': string;

  /**  */
  'companyId': string;

  /**  */
  'brandCode': string;

  /**  */
  'qualificationId': string;

  /**  */
  'qualificationName': string;

  /**  */
  'serviceId': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate': Date;

  /**  */
  'effectiveAbsenceTime': AbsenceOnMonth[];

  /**  */
  'absenceReason': string;

  /**  */
  'informedByCandidate': boolean;

  /**  */
  'hasBeenJustify'?: boolean;

  /**  */
  'replacementRequired': boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'replacementStartDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'replacementEndDate'?: Date;

  /**  */
  'effectiveReplacementTime'?: AbsenceOnMonth[];

  /**  */
  'comment'?: string;

  /**  */
  'replacementCandidateId'?: string;

  constructor(data: undefined | any = {}) {
    this['timeInterval'] = data['timeInterval'];
    this['agencyId'] = data['agencyId'];
    this['candidateName'] = data['candidateName'];
    this['companyId'] = data['companyId'];
    this['brandCode'] = data['brandCode'];
    this['qualificationId'] = data['qualificationId'];
    this['qualificationName'] = data['qualificationName'];
    this['serviceId'] = data['serviceId'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['effectiveAbsenceTime'] = data['effectiveAbsenceTime'];
    this['absenceReason'] = data['absenceReason'];
    this['informedByCandidate'] = data['informedByCandidate'];
    this['hasBeenJustify'] = data['hasBeenJustify'];
    this['replacementRequired'] = data['replacementRequired'];
    this['replacementStartDate'] = data['replacementStartDate'];
    this['replacementEndDate'] = data['replacementEndDate'];
    this['effectiveReplacementTime'] = data['effectiveReplacementTime'];
    this['comment'] = data['comment'];
    this['replacementCandidateId'] = data['replacementCandidateId'];
  }
}

export class Absence {
  /**  */
  'label'?: string;

  /**  */
  'absenceId'?: string;

  /**  */
  'agencyId': string;

  /**  */
  'companyId': string;

  /**  */
  'brandCode': string;

  /**  */
  'candidateId': string;

  /**  */
  'qualificationId': string;

  /**  */
  'qualificationName': string;

  /**  */
  'serviceId': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate': Date;

  /**  */
  'timeInterval': EnumAbsenceTimeInterval;

  /**  */
  'effectiveAbsenceTime': AbsenceOnMonth[];

  /**  */
  'absenceReason': string;

  /**  */
  'informedByCandidate': boolean;

  /**  */
  'replacementRequired': boolean;

  /**  */
  'hasBeenJustify'?: boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'createdAt'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'replacementStartDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'replacementEndDate'?: Date;

  /**  */
  'effectiveReplacementTime'?: AbsenceOnMonth[];

  /**  */
  'version'?: number;

  /**  */
  'actionId'?: string;

  /**  */
  'comment'?: string;

  /**  */
  'replacementCandidateId'?: string;

  constructor(data: undefined | any = {}) {
    this['label'] = data['label'];
    this['absenceId'] = data['absenceId'];
    this['agencyId'] = data['agencyId'];
    this['companyId'] = data['companyId'];
    this['brandCode'] = data['brandCode'];
    this['candidateId'] = data['candidateId'];
    this['qualificationId'] = data['qualificationId'];
    this['qualificationName'] = data['qualificationName'];
    this['serviceId'] = data['serviceId'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['timeInterval'] = data['timeInterval'];
    this['effectiveAbsenceTime'] = data['effectiveAbsenceTime'];
    this['absenceReason'] = data['absenceReason'];
    this['informedByCandidate'] = data['informedByCandidate'];
    this['replacementRequired'] = data['replacementRequired'];
    this['hasBeenJustify'] = data['hasBeenJustify'];
    this['createdAt'] = data['createdAt'];
    this['replacementStartDate'] = data['replacementStartDate'];
    this['replacementEndDate'] = data['replacementEndDate'];
    this['effectiveReplacementTime'] = data['effectiveReplacementTime'];
    this['version'] = data['version'];
    this['actionId'] = data['actionId'];
    this['comment'] = data['comment'];
    this['replacementCandidateId'] = data['replacementCandidateId'];
  }
}

export class UpdateAbsenceBody {
  /**  */
  'timeInterval': EnumAbsenceTimeInterval;

  /**  */
  'agencyId': string;

  /**  */
  'candidateName': string;

  /**  */
  'companyId': string;

  /**  */
  'brandCode': string;

  /**  */
  'qualificationId': string;

  /**  */
  'qualificationName': string;

  /**  */
  'serviceId': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate': Date;

  /**  */
  'effectiveAbsenceTime': AbsenceOnMonth[];

  /**  */
  'absenceReason': string;

  /**  */
  'informedByCandidate': boolean;

  /**  */
  'hasBeenJustify'?: boolean;

  /**  */
  'replacementRequired': boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'replacementStartDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'replacementEndDate'?: Date;

  /**  */
  'effectiveReplacementTime'?: AbsenceOnMonth[];

  /**  */
  'comment'?: string;

  /**  */
  'replacementCandidateId'?: string;

  /**  */
  'absenceId': string;

  /**  */
  'version': number;

  /**  */
  'actionId'?: string;

  constructor(data: undefined | any = {}) {
    this['timeInterval'] = data['timeInterval'];
    this['agencyId'] = data['agencyId'];
    this['candidateName'] = data['candidateName'];
    this['companyId'] = data['companyId'];
    this['brandCode'] = data['brandCode'];
    this['qualificationId'] = data['qualificationId'];
    this['qualificationName'] = data['qualificationName'];
    this['serviceId'] = data['serviceId'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['effectiveAbsenceTime'] = data['effectiveAbsenceTime'];
    this['absenceReason'] = data['absenceReason'];
    this['informedByCandidate'] = data['informedByCandidate'];
    this['hasBeenJustify'] = data['hasBeenJustify'];
    this['replacementRequired'] = data['replacementRequired'];
    this['replacementStartDate'] = data['replacementStartDate'];
    this['replacementEndDate'] = data['replacementEndDate'];
    this['effectiveReplacementTime'] = data['effectiveReplacementTime'];
    this['comment'] = data['comment'];
    this['replacementCandidateId'] = data['replacementCandidateId'];
    this['absenceId'] = data['absenceId'];
    this['version'] = data['version'];
    this['actionId'] = data['actionId'];
  }
}

export class OSMCandidateModelSearchMultiCriteriaDtoCandidateWorkteamDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class CandidateStatus {
  /**  */
  'id'?: CandidateStatusEnum;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class CandidateInPool {
  /**  */
  'candidateId'?: string;

  /**  */
  'gender'?: string;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'qualification'?: OSMRepositoriesModelSkillRepositoryDtoSkillDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'contractStartdDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'contractRealEndDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'contractExpectedEndDate'?: Date;

  /**  */
  'contractId'?: string;

  /**  */
  'missionAgencyId'?: string;

  /**  */
  'service'?: OSMServiceModelDtoServiceBasicDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'contractStartdDateFlexibility'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'contractRealEndDateFlexibility'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'firstContractStartDate'?: Date;

  /**  */
  'targetGroup'?: string;

  /**  */
  'workteams'?: OSMCandidateModelSearchMultiCriteriaDtoCandidateWorkteamDto[];

  /**  */
  'candidateStatus'?: CandidateStatus;

  /**  */
  'isScheduledExit'?: boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'poolExitDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'poolExitOrderDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['candidateId'] = data['candidateId'];
    this['gender'] = data['gender'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['qualification'] = data['qualification'];
    this['contractStartdDate'] = data['contractStartdDate'];
    this['contractRealEndDate'] = data['contractRealEndDate'];
    this['contractExpectedEndDate'] = data['contractExpectedEndDate'];
    this['contractId'] = data['contractId'];
    this['missionAgencyId'] = data['missionAgencyId'];
    this['service'] = data['service'];
    this['contractStartdDateFlexibility'] = data['contractStartdDateFlexibility'];
    this['contractRealEndDateFlexibility'] = data['contractRealEndDateFlexibility'];
    this['firstContractStartDate'] = data['firstContractStartDate'];
    this['targetGroup'] = data['targetGroup'];
    this['workteams'] = data['workteams'];
    this['candidateStatus'] = data['candidateStatus'];
    this['isScheduledExit'] = data['isScheduledExit'];
    this['poolExitDate'] = data['poolExitDate'];
    this['poolExitOrderDate'] = data['poolExitOrderDate'];
  }
}

export class AllCandidatesWithCount {
  /**  */
  'count'?: number;

  /**  */
  'candidates': CandidateInPool[];

  constructor(data: undefined | any = {}) {
    this['count'] = data['count'];
    this['candidates'] = data['candidates'];
  }
}

export class UpdateSubscriptionParams {
  /**  */
  'actionType': UpdateSubscriptionEnum;

  /**  */
  'agencyId': string;

  /**  */
  'companyId': string;

  /**  */
  'brandCode': string;

  /**  */
  'motivationId': string;

  /**  */
  'motivationComment'?: string;

  /**  */
  'senderId': string;

  /**  */
  'isRepositionable': boolean;

  /**  */
  'cgcId'?: string;

  /**  */
  'comment'?: string;

  /**  */
  'service'?: string;

  /**  */
  'firstContractDate'?: string;

  /**  */
  'targetGroup'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'poolExitDate'?: Date;

  /**  */
  'isScheduledExit'?: boolean;

  constructor(data: undefined | any = {}) {
    this['actionType'] = data['actionType'];
    this['agencyId'] = data['agencyId'];
    this['companyId'] = data['companyId'];
    this['brandCode'] = data['brandCode'];
    this['motivationId'] = data['motivationId'];
    this['motivationComment'] = data['motivationComment'];
    this['senderId'] = data['senderId'];
    this['isRepositionable'] = data['isRepositionable'];
    this['cgcId'] = data['cgcId'];
    this['comment'] = data['comment'];
    this['service'] = data['service'];
    this['firstContractDate'] = data['firstContractDate'];
    this['targetGroup'] = data['targetGroup'];
    this['poolExitDate'] = data['poolExitDate'];
    this['isScheduledExit'] = data['isScheduledExit'];
  }
}

export class PoolExitData {
  /**  */
  'candidateId': string;

  /**  */
  'agencyId': string;

  /**  */
  'companyId': string;

  /**  */
  'brandCode': string;

  /**  */
  'motivationId': string;

  /**  */
  'motivationComment'?: string;

  /**  */
  'senderId': string;

  /**  */
  'isRepositionable': boolean;

  /**  */
  'cgcId'?: string;

  /**  */
  'comment'?: string;

  /**  */
  'service'?: string;

  /**  */
  'firstContractDate'?: string;

  /**  */
  'targetGroup'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'poolExitDate'?: Date;

  /**  */
  'isScheduledExit'?: boolean;

  constructor(data: undefined | any = {}) {
    this['candidateId'] = data['candidateId'];
    this['agencyId'] = data['agencyId'];
    this['companyId'] = data['companyId'];
    this['brandCode'] = data['brandCode'];
    this['motivationId'] = data['motivationId'];
    this['motivationComment'] = data['motivationComment'];
    this['senderId'] = data['senderId'];
    this['isRepositionable'] = data['isRepositionable'];
    this['cgcId'] = data['cgcId'];
    this['comment'] = data['comment'];
    this['service'] = data['service'];
    this['firstContractDate'] = data['firstContractDate'];
    this['targetGroup'] = data['targetGroup'];
    this['poolExitDate'] = data['poolExitDate'];
    this['isScheduledExit'] = data['isScheduledExit'];
  }
}

export class DeletePoolExitBody {
  /**  */
  'candidateId': string;

  constructor(data: undefined | any = {}) {
    this['candidateId'] = data['candidateId'];
  }
}

export class AbsenceReason {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class DeleteAbsenceBody {
  /**  */
  'absenceId': string;

  constructor(data: undefined | any = {}) {
    this['absenceId'] = data['absenceId'];
  }
}

export class IdLabel {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class CGC {
  /**  */
  'id'?: string;

  /**  */
  'societyId'?: string;

  /**  */
  'brandCode'?: string;

  /**  */
  'label'?: string;

  /**  */
  'isOpen'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['societyId'] = data['societyId'];
    this['brandCode'] = data['brandCode'];
    this['label'] = data['label'];
    this['isOpen'] = data['isOpen'];
  }
}

export class GetExportDataQueryParams {
  /**  */
  'types': ExportType[];

  /**  */
  'userMail': string;

  /**  */
  'agencyId': string;

  /**  */
  'brandCode': string;

  /**  */
  'companyId': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'startingDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endingDate': Date;

  constructor(data: undefined | any = {}) {
    this['types'] = data['types'];
    this['userMail'] = data['userMail'];
    this['agencyId'] = data['agencyId'];
    this['brandCode'] = data['brandCode'];
    this['companyId'] = data['companyId'];
    this['startingDate'] = data['startingDate'];
    this['endingDate'] = data['endingDate'];
  }
}

export enum EnumEntityActionStatus {
  'FINISHED' = 'FINISHED',
  'IN_PROGRESS' = 'IN_PROGRESS',
}
export enum EnumCreateActionBodyType {
  'ACTION_CANDIDATE_EXCHANGE' = 'ACTION_CANDIDATE_EXCHANGE',
  'ACTION_CONTACT_EXCHANGE' = 'ACTION_CONTACT_EXCHANGE',
  'ACTION_PRODUCTION_IMPACT' = 'ACTION_PRODUCTION_IMPACT',
  'ACTION_SECURITY_EVENT' = 'ACTION_SECURITY_EVENT',
  'ACTION_CANDIDATE_VISIT' = 'ACTION_CANDIDATE_VISIT',
  'ACTION_OTHER' = 'ACTION_OTHER',
}
export enum EnumEntityActionType {
  'ACTION_CANDIDATE_EXCHANGE' = 'ACTION_CANDIDATE_EXCHANGE',
  'ACTION_CONTACT_EXCHANGE' = 'ACTION_CONTACT_EXCHANGE',
  'ACTION_PRODUCTION_IMPACT' = 'ACTION_PRODUCTION_IMPACT',
  'ACTION_SECURITY_EVENT' = 'ACTION_SECURITY_EVENT',
  'ACTION_CANDIDATE_VISIT' = 'ACTION_CANDIDATE_VISIT',
  'ACTION_OTHER' = 'ACTION_OTHER',
}
export enum EnumEntityActionTargetType {
  'Compte' = 'Compte',
  'Partenaire' = 'Partenaire',
  'TT' = 'TT',
}
export enum EnumCommunicationMatrixEntryFrequencyUnit {
  'WEEKLY' = 'WEEKLY',
  'MONTHLY' = 'MONTHLY',
  'PER_SEMESTER' = 'PER_SEMESTER',
  'YEARLY' = 'YEARLY',
}
export enum TargetType {
  'Compte' = 'Compte',
  'Partenaire' = 'Partenaire',
  'TT' = 'TT',
}
export enum EnumAbsenceTimeInterval {
  'matin' = 'matin',
  'après-midi' = 'après-midi',
  'nuit' = 'nuit',
  'journée' = 'journée',
  'week-end' = 'week-end',
}
export enum CandidateStatusEnum {
  'INT' = 'INT',
  'CDI' = 'CDI',
}

export enum UpdateSubscriptionEnum {
  'Subscribe' = 'Subscribe',
  'Unsubscribe' = 'Unsubscribe',
}

export enum ExportType {
  'ABSENCES' = 'ABSENCES',
  'EXCHANGES' = 'EXCHANGES',
  'CLAIMS' = 'CLAIMS',
  'SECURITY' = 'SECURITY',
  'PRODUCTION' = 'PRODUCTION',
  'POOLEXIT' = 'POOLEXIT',
}
