import { ActionsService, EnumCreateActionBodyType, EnumEntityActionType } from '../API';
import { db } from '../DB';

export const updateAction = async ({
  localId,
  brandCode,
  agencyId,
  companyId,
}: {
  localId: number;
  brandCode: string;
  agencyId: string;
  companyId: string;
}) => {
  const action = await db.actions.get(localId);
  if (action === undefined) {
    throw new Error(`Action ${localId} does not exist in local DB`);
  }

  const { type, startDate, ...actionWithoutType } = action;
  delete actionWithoutType.localId;

  return await ActionsService.actionsControllerPutUpdateAction({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    id: action.actionId!,
    body: {
      brandCode,
      companyId,
      agencyId: agencyId,
      startDate: startDate ?? new Date(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      type: EnumCreateActionBodyType[type!],
      ...actionWithoutType,
    },
  });
};

export const createAction = async ({
  localId,
  brandCode,
  agencyId,
  companyId,
}: {
  localId: number;
  brandCode: string;
  agencyId: string;
  companyId: string;
}) => {
  const action = await db.actions.get(localId);
  if (action === undefined) {
    throw new Error(`Action ${localId} does not exist in local DB`);
  }

  const { type, startDate, ...actionWithoutType } = action;
  delete actionWithoutType.localId;

  return await ActionsService.actionsControllerPostCreateAction({
    body: {
      brandCode,
      companyId,
      agencyId: agencyId,
      startDate: startDate ?? new Date(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      type: EnumCreateActionBodyType[type!],
      ...actionWithoutType,
    },
  });
};

export const actionTypeToRoute: { [key in EnumEntityActionType]: string } = {
  [EnumEntityActionType.ACTION_CANDIDATE_EXCHANGE]: '/exchange',
  [EnumEntityActionType.ACTION_CONTACT_EXCHANGE]: '/exchange',
  [EnumEntityActionType.ACTION_PRODUCTION_IMPACT]: '/productionimpact',
  [EnumCreateActionBodyType.ACTION_SECURITY_EVENT]: '/securityevent',
  [EnumEntityActionType.ACTION_CANDIDATE_VISIT]: '/',
  [EnumCreateActionBodyType.ACTION_OTHER]: '/',
};
