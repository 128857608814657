import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CandidatesService, PoolExitData } from 'src/Services/API';
import { QueryKeys } from '..';
import { AxiosError } from 'axios';

const useFetchPoolExitData = (
  candidateId: string,
  extraOptions?: UseQueryOptions<PoolExitData, AxiosError, PoolExitData, string[]>
) => {
  return useQuery(
    [QueryKeys.FETCH_POOL_EXIT_DATA, candidateId],
    async () => {
      return await CandidatesService.candidatesControllerFetchPoolExitData({ id: candidateId });
    },
    { ...extraOptions }
  );
};

export default useFetchPoolExitData;
