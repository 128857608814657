import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../RootReducer';
import { SynchroState } from './Types';

const getSynchroState = (state: RootState) => state.synchro;

export const getLastAbsenceSyncDate = createSelector(
  [getSynchroState],
  (state: SynchroState) => state.lastAbsenceSyncDate
);

export const getLastActionSyncDate = createSelector(
  [getSynchroState],
  (state: SynchroState) => state.lastActionSyncDate
);

export const getLastClaimSyncDate = createSelector(
  [getSynchroState],
  (state: SynchroState) => state.lastClaimSyncDate
);
