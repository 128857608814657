import React, { useCallback, useEffect, useState } from 'react';
import { useFetchCompanyContacts } from 'src/Hooks/Contacts';
import SelectContact from './SelectContact.component';
import { OwnProps } from './SelectContact.types';

const MemoSelectContact = React.memo(SelectContact);

const EnhancedSelectContact = (ownProps: OwnProps) => {
  const companyContacts = useFetchCompanyContacts();

  const [contacts, setContacts] = useState(companyContacts);
  useEffect(
    () => setContacts(companyContacts),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyContacts.length]
  );

  const _getContacts = useCallback(
    (searchValue: string) => {
      setContacts(
        companyContacts
          .filter(contact =>
            [contact.firstName, contact.name]
              .filter(x => x)
              .join(' ')
              .toUpperCase()
              .includes(searchValue.toUpperCase())
          )
          .filter(contact => !ownProps.contactIdBlackList?.includes(contact.contactId ?? ''))
      );
    },
    [companyContacts, ownProps.contactIdBlackList]
  );
  return <MemoSelectContact contacts={contacts} getContacts={_getContacts} {...ownProps} />;
};

export default EnhancedSelectContact;
