import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/RootReducer';
import { PerimeterState } from './Types';

const getPerimeterState = (state: RootState) => state.perimeter;

export const getCompanies = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.companies
);

export const getCurrentBrand = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.currentBrand
);

export const getCurrentBrandCode = createSelector(
  [getCurrentBrand],
  state => state?.brandCodeApiHeader ?? '001VB'
);

export const getCurrentAgency = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.currentAgency ?? ''
);

export const getDefaultBrand = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.defaultCompanyId ?? ''
);

export const getDefaultAgency = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.defaultAgencyId ?? ''
);

export const getFetchStatus = createSelector(
  [getPerimeterState],
  (state: PerimeterState) => state.fetchStatus
);

export const getAnalyticsUserInfo = createSelector(
  [getPerimeterState],
  ({ branchId, regionId, zoneId, currentAgency: agencyId, currentBrand }: PerimeterState) => ({
    branchId,
    regionId,
    zoneId,
    agencyId,
    brand: currentBrand?.brand?.name,
  })
);
