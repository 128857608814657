import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/RootReducer';
import { CompaniesState } from './Types';

const getCompaniesState = (state: RootState) => state.companies;

export const getCurrentCompany = createSelector(
  [getCompaniesState],
  (state: CompaniesState) => state.currentCompany
);

export const getCurrentCompanyId = createSelector(
  [getCompaniesState],
  (state: CompaniesState) => state.currentCompany?.companyId
);
