import React from 'react';

import { Props } from './CompanyContactListItem.types';
import styles from './CompanyContactListItem.module.scss';
import ListItem from 'src/Components/ListItem';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';
import MobileListItem from '../MobileListItem/MobileListItem.component';

const CompanyContactListItem = (props: Props) => {
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  return isMobile ? (
    <MobileListItem {...props} descriptionClassname={styles.companyContact} />
  ) : (
    <ListItem {...props} descriptionClassname={styles.companyContact} />
  );
};

export default CompanyContactListItem;
