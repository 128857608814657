import { createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';
import { ConsultantsService, ConsultantPerimeter } from 'src/Services/API';

export const fetchPerimeter = createAsyncThunk<{
  perimeter: ConsultantPerimeter;
}>('perimeter/fetch', async () => {
  const perimeter = classToPlain(await ConsultantsService.consultantsControllerGetPerimeter());
  return {
    perimeter,
  };
});
