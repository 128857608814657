import React from 'react';

import styles from './MobileListItem.module.scss';
import { ListItemProps } from 'src/Components/ListItem/ListItem.types';
import classnames from 'classnames';

const MobileListItem = (props: ListItemProps) => (
  <div className={classnames(styles.container, props.descriptionClassname)}>
    <div className={styles.leftSide}>
      <div className={styles.badge}>
        <div>{props.icon}</div>
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.topDescription}>
          <div className={styles.title}>{props.title}</div>
          <div className={styles.subtitle}>{props.subtitle}</div>
        </div>
        <div className={styles.description}>{props.description}</div>
      </div>
    </div>

    <div className={styles.rightAction}>{props.headerRight}</div>
  </div>
);

export default MobileListItem;
