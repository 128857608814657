import { RandstadAppsPopup, Button } from '@randstad-lean-mobile-factory/react-components-core';
import { Menu } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';
import {
  getCurrentBrand,
  getCurrentAgency,
  getAnalyticsUserInfo,
} from 'src/Redux/Perimeter/Selectors';

import styles from './Apps.module.scss';

const Apps = () => {
  const { isProd } = useIsProductionEnvironment();
  const [isOpened, setIsOpened] = useState(false);
  const brand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);
  const userInfo = useSelector(getAnalyticsUserInfo);
  return (
    <RandstadAppsPopup
      isProd={isProd}
      brandCode={brand?.brand?.code}
      position="bottom right"
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      onItemClick={name => {
        ReactGA.event(`Navigation vers une autre application`, {
          name,
          brandCode: brand?.brand?.code,
          agency: currentAgency,
          regionId: userInfo.regionId,
          zoneId: userInfo.zoneId,
        });
      }}
      trigger={
        <div>
          <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpened })}>
            <Menu className={styles.buttonIcon} />
          </Button.Tertiary>
        </div>
      }
    />
  );
};

export default Apps;
