import React, { useEffect, useState } from 'react';
import { useFetchAllCandidates } from 'src/Hooks/Candidates';
import SelectCandidate from './SelectCandidate.component';
import { OwnProps } from './SelectCandidate.types';

const MemoSelectCandidate = React.memo(SelectCandidate);

const EnhancedSelectCandidate = ({ selectedCandidate, ...props }: OwnProps) => {
  const candidatesOnMission = useFetchAllCandidates();
  const [candidates, setCandidates] = useState(candidatesOnMission);
  useEffect(() => setCandidates(candidatesOnMission), [candidatesOnMission]);
  const [candidate, setCandidate] = useState(selectedCandidate);
  useEffect(() => {
    setCandidate(selectedCandidate);
  }, [selectedCandidate]);

  return (
    <MemoSelectCandidate candidates={candidates ?? []} selectedCandidate={candidate} {...props} />
  );
};

export default EnhancedSelectCandidate;
