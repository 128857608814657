import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {
  CheckmarkCircleOutline,
  Liste,
  Menu,
  Parameters,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplaySection } from 'src/Redux/UserPreferences/Selectors';
import { DISPLAYED_SECTION } from 'src/Redux/UserPreferences/Types';
import { MenuItem } from 'src/Services/Menu';
import styles from './BottomMenuBar.module.scss';
import { userPreferencesActions } from 'src/Redux/UserPreferences/Slice';
const mobileMenu: MenuItem[] = [
  {
    title: 'listes',
    displayedSection: DISPLAYED_SECTION.LISTS,
    icon: <Liste className={styles.icon} />,
  },
  {
    title: 'tâches',
    displayedSection: DISPLAYED_SECTION.TASKS,
    icon: <CheckmarkCircleOutline className={styles.icon} />,
  },
  {
    title: 'paramètres',
    displayedSection: DISPLAYED_SECTION.PARAMETERS,
    icon: <Parameters className={styles.icon} />,
  },
  {
    title: 'applications',
    displayedSection: DISPLAYED_SECTION.APPLICATIONS,
    icon: <Menu />,
  },
];

const BottomMenuBar = () => {
  const displayedSection = useSelector(getDisplaySection);
  const dispatch = useDispatch();
  return (
    <div className={styles.menuContainer}>
      <Tabs
        value={mobileMenu.findIndex(item => item.displayedSection === displayedSection)}
        onChange={(_, newValue) => {
          if (mobileMenu[newValue].displayedSection !== displayedSection) {
            dispatch(
              userPreferencesActions.setDisplaySection(
                mobileMenu[newValue].displayedSection ?? DISPLAYED_SECTION.INTERIM
              )
            );
          }
        }}
        className={styles.tabsContainer}
        sx={{
          '& div.MuiTabs-flexContainer': {
            gap: '0.75rem',
            display: 'flex',
            justifyContent: 'center',
          },
          '& button.Mui-selected': { color: 'white', '& path': { fill: 'white' } },
          '& span.MuiTabs-indicator': { backgroundColor: 'white', top: 0 },
        }}
      >
        {mobileMenu.map(tab => {
          return (
            <Tab
              key={tab.title}
              label={tab.title}
              icon={tab.icon}
              sx={{
                textTransform: 'lowercase',
                fontSize: '0.75rem',
                height: '3rem',
                minHeight: '3rem',
                minWidth: '4.5rem',
                padding: 0,
                paddingTop: '0.125rem',
                paddingBottom: '0.125rem',
                color: '#c7d1dcb3',
                '& svg.MuiTab-iconWrapper': {
                  marginBottom: '0.125rem',
                },
              }}
            />
          );
        })}
      </Tabs>
    </div>
  );
};

export default BottomMenuBar;
