import React from 'react';
import { useSelector } from 'react-redux';
import { getDisplaySection } from 'src/Redux/UserPreferences/Selectors';
import DisplayedSection from './DisplayedSection.component';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';

const MemoDisplayedSection = React.memo(DisplayedSection);

const EnhancedDisplayedSection = () => {
  const displayedSection = useSelector(getDisplaySection);
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });

  return <MemoDisplayedSection displayedSection={displayedSection} isMobile={isMobile} />;
};

export default EnhancedDisplayedSection;
