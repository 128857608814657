import { useLiveQuery } from 'dexie-react-hooks';
import moment from 'moment';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentCompanyId } from 'src/Redux/Companies/Selectors';
import { getAnalyticsUserInfo, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { getLastAbsenceSyncDate } from 'src/Redux/Synchro/Selectors';
import { synchroActions } from 'src/Redux/Synchro/Slice';
import {
  AbsenceOnMonth,
  AbsenceService,
  CandidatesService,
  EnumAbsenceTimeInterval,
} from 'src/Services/API';
import { db, Local } from 'src/Services/DB';
import { usePerimeter } from '../Perimeter';
import ReactGA from 'react-ga4';
import { queryStatus } from '../../Services/ReactQuery';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { ConflictError } from 'src/Services/API/types';
import { MutationKeys, QueryKeys } from '..';

export class Absence {
  qualificationName?: string;
  qualificationId?: string;
  companyId!: string;
  agencyId!: string;
  serviceId?: string;
  candidateId?: string;
  candidateName?: string;
  actionId?: string;
  absenceId?: string;
  hasBeenJustify?: boolean;
  startDate!: Date;
  endDate!: Date;
  timeInterval!: EnumAbsenceTimeInterval;
  effectiveAbsenceTime!: AbsenceOnMonth[];
  absenceReason!: string;
  informedByCandidate!: boolean;
  replacementRequired!: boolean;
  replacementStartDate?: Date;
  replacementEndDate?: Date;
  effectiveReplacementTime?: AbsenceOnMonth[];
  version?: number;
  comment?: string;
  replacementCandidateId?: string;
}

export interface AbsenceExtended extends Absence {
  brandCode: string;
  agencyId: string;
  companyId: string;
}

export const createAbsence = async ({
  localId,
  brandCode,
}: Pick<AbsenceExtended, 'brandCode'> & {
  localId: number;
}) => {
  const absence = await db.absences.get(localId);
  if (absence === undefined) {
    throw new Error(`Absence ${localId} does not exist in local DB`);
  }
  return await CandidatesService.candidatesControllerCreateCandidateAbsence({
    id: absence.candidateId ?? '',
    body: {
      brandCode: brandCode ?? '',
      agencyId: absence.agencyId ?? '',
      candidateName: absence.candidateName ?? '',
      companyId: absence.companyId ?? '',
      qualificationName: absence.qualificationName ?? '',
      qualificationId: absence.qualificationId ?? '',
      serviceId: absence.serviceId ?? '',
      startDate: absence.startDate,
      endDate: absence.endDate ?? new Date(),
      timeInterval: absence.timeInterval,
      effectiveAbsenceTime: absence.effectiveAbsenceTime ?? [],
      absenceReason: absence.absenceReason ?? '',
      informedByCandidate: absence.informedByCandidate ?? false,
      replacementRequired: absence.replacementRequired,
      replacementStartDate: absence.replacementStartDate,
      replacementEndDate: absence.replacementEndDate,
      effectiveReplacementTime: absence.effectiveReplacementTime,
      hasBeenJustify: absence.hasBeenJustify,
      comment: absence.comment,
      replacementCandidateId: absence.replacementCandidateId,
    },
  });
};

export const updateAbsence = async ({
  localId,
  brandCode,
}: { previousData: Local<Absence, never> | undefined } & Pick<AbsenceExtended, 'brandCode'> & {
    localId: number;
  }) => {
  const absence = await db.absences.get(localId);
  if (absence === undefined) {
    throw new Error(`Absence ${localId} does not exist in local DB`);
  }
  return await CandidatesService.candidatesControllerUpdateCandidateAbsence({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    id: absence.candidateId ?? '',
    body: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      absenceId: absence.absenceId ?? '',
      actionId: absence.actionId,
      candidateName: absence.candidateName ?? '',
      version: absence.version ?? 0,
      brandCode: brandCode ?? '',
      agencyId: absence.agencyId ?? '',
      companyId: absence.companyId ?? '',
      qualificationName: absence.qualificationName ?? '',
      qualificationId: absence.qualificationId ?? '',
      serviceId: absence.serviceId ?? '',
      startDate: absence.startDate,
      endDate: absence.endDate ?? new Date(),
      timeInterval: EnumAbsenceTimeInterval[absence.timeInterval],
      effectiveAbsenceTime: absence.effectiveAbsenceTime ?? [],
      absenceReason: absence.absenceReason ?? '',
      informedByCandidate: absence.informedByCandidate ?? false,
      replacementRequired: absence.replacementRequired,
      replacementStartDate: absence.replacementStartDate,
      replacementEndDate: absence.replacementEndDate,
      effectiveReplacementTime: absence.effectiveReplacementTime,
      hasBeenJustify: absence.hasBeenJustify,
      comment: absence.comment,
      replacementCandidateId: absence.replacementCandidateId,
    },
  });
};

export const deleteAbsence = async ({ absenceId }: { absenceId: string }) => {
  return await AbsenceService.absenceControllerDeleteCandidateAbsence({ body: { absenceId } });
};

export const useBackgroundFetchAbsenceReasons = (
  extraOptions?: UseQueryOptions<unknown, unknown, string[]>
) => {
  useQuery<unknown, unknown, string[]>(
    ['fetchAbsenceReasons'],
    async () => {
      const absenceReasons = await AbsenceService.absenceControllerGetAbsenceReasons();

      await db.transaction('rw', db.absenceReasons, async () => {
        await db.absenceReasons.clear();
        await db.absenceReasons.bulkPut(absenceReasons);
      });

      return absenceReasons;
    },
    {
      staleTime: 300000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      ...extraOptions,
    }
  );
};

export const useFetchAbsenceReasons = (
  extraOptions?: UseQueryOptions<unknown, unknown, string[]>
) => {
  useBackgroundFetchAbsenceReasons(extraOptions);
  return useLiveQuery(() => db.absenceReasons.toArray()) ?? [];
};

export const useCreateAbsence = () => {
  const queryClient = useQueryClient();
  const { brandCode, agencyId } = usePerimeter();
  const { zoneId, regionId } = useSelector(getAnalyticsUserInfo);
  const mutation = useMutation(
    [MutationKeys.ABSENCE_CREATION],
    absenceMutationsConfigs(queryClient)[MutationKeys.ABSENCE_CREATION]
  );

  return {
    create: async (data: Absence) => {
      const localId = await db.absences.add(data);
      ReactGA.event("Création d'absence", { brandCode, agencyId, zoneId, regionId });
      mutation.mutate({ localId, brandCode });
    },
    mutation,
  };
};

export const useDeleteAbsence = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    [MutationKeys.ABSENCE_DELETION],
    absenceMutationsConfigs(queryClient)['absences/delete']
  );

  return {
    delete: async (absenceId: string) => {
      const mutationCache = queryClient.getMutationCache().getAll();

      if (
        mutationCache.findIndex(
          mutation =>
            mutation.options?.mutationKey?.includes(MutationKeys.ABSENCE_DELETION) &&
            mutation.options?.variables?.['absenceId'] === absenceId &&
            mutation.state.status === 'loading'
        ) !== -1
      ) {
        return queryStatus.success;
      }
      mutation.mutate(
        { absenceId },
        {
          onSuccess: async () => {
            await db.absences.where({ absenceId }).delete();
            queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_CANDIDATE_ABSENCES] });
          },
          onError: () => {
            toast.error(
              "Une erreur est survenue à la suppression de l'absence, veuillez réessayer plus tard"
            );
          },
        }
      );
    },
    mutation,
  };
};

export const absenceMutationsConfigs = (queryClient: QueryClient) => {
  return {
    'absences/create': {
      mutationFn: createAbsence,
      onSuccess: async (absence: Absence, { localId }: Parameters<typeof createAbsence>[0]) => {
        await db.absences.update(localId, absence);
        queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_ABSENCES] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_CANDIDATE_ABSENCES] });
        if (absence.absenceReason === 'ABN') {
          queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_COMPANY_ACTIONS] });
        }
      },
      onError: async (error: unknown, { localId }: Parameters<typeof createAbsence>[0]) => {
        if (error instanceof ConflictError) {
          toast.error("L'intérimaire a déjà une absence sur ces dates");
          await db.absences.where({ localId: localId }).delete();
        } else {
          toast.error(
            "Une erreur est survenue à l'enregistrement de l'absence, veuillez réessayer plus tard"
          );
        }
      },
      // Without a retry, mutations done while offline will fail instead of being paused
      retry: 1,
    },
    'absences/update': {
      mutationFn: updateAbsence,
      onSuccess: async (absence: Absence, { localId }: Parameters<typeof updateAbsence>[0]) => {
        await db.absences.update(localId, absence);
        queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_CANDIDATE_ABSENCES] });
        if (absence.absenceReason === 'ABN') {
          queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_COMPANY_ACTIONS] });
        }
      },
      onError: async (
        error: unknown,
        { previousData, localId }: Parameters<typeof updateAbsence>[0]
      ) => {
        if (error instanceof ConflictError) {
          toast.error("L'intérimaire a déjà une absence sur ces dates");
          previousData && (await db.absences.where({ localId: localId }).modify(previousData));
        } else {
          toast.error(
            "Une erreur est survenue à l'enregistrement de l'absence, veuillez réessayer plus tard"
          );
        }
      },
      // Without a retry, mutations done while offline will fail instead of being paused
      retry: 1,
    },
    'absences/delete': {
      mutationFn: deleteAbsence,
      onSuccess: async (_: void, { absenceId }: Parameters<typeof deleteAbsence>[0]) => {
        await db.absences.where({ absenceId }).delete();
        queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_CANDIDATE_ABSENCES] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_CANDIDATE_ABSENCES] });
      },
      // Without a retry, mutations done while offline will fail instead of being paused
      retry: 1,
    },
  };
};

export const useBackgroundCleanOldAbsences = () => {
  useQuery(['cleanOldAbsences'], async () => {
    await db.absences.where('endDate').below(moment().subtract(3, 'month').toDate()).delete();
  });
};

export const useBackgroundFetchAbsences = (
  extraOptions?: UseQueryOptions<unknown, unknown, Absence[]>
) => {
  const dispatch = useDispatch();
  const synchroStartTime = moment().toDate();
  const companyId = useSelector(getCurrentCompanyId);
  const agencyId = useSelector(getCurrentAgency);
  const lastSynchro = useSelector(getLastAbsenceSyncDate);
  useQuery<unknown, unknown, Absence[]>(
    ['fetchAbsences', companyId, agencyId, moment(lastSynchro).format('LLL')],
    async () => {
      const absences = await AbsenceService.absenceControllerGetAbsences({
        companyId: companyId ?? '',
        agencyId: agencyId,
        since: lastSynchro,
      });

      const localDBAbsences = absences.map(absence => ({
        ...absence,
        timeInterval: EnumAbsenceTimeInterval[absence.timeInterval],
      }));
      await db.transaction('rw', db.absences, async () => {
        await db.absences
          .where('absenceId')
          .anyOf(
            localDBAbsences
              .filter(absence => absence.absenceId !== undefined)
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              .map(absence => absence.absenceId!)
          )
          .delete();
        await db.absences.bulkAdd(localDBAbsences);
      });
      dispatch(synchroActions.setLastAbsenceSyncDate(synchroStartTime.toISOString()));
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 300000,
      enabled: agencyId !== undefined && companyId !== undefined && lastSynchro !== undefined,
      ...extraOptions,
    }
  );
};

export const useFetchCandidateAbsences = (
  candidateId: string,
  companyId: string,
  extraOptions?: UseQueryOptions<unknown, unknown, Absence[]>
) => {
  return useQuery<unknown, unknown, Local<Absence>[]>(
    [QueryKeys.FETCH_CANDIDATE_ABSENCES, candidateId],
    async () => {
      const candidateAbsences = await db.absences
        .where('[candidateId+companyId]')
        .equals([candidateId, companyId])
        .toArray();

      return candidateAbsences ?? [];
    },
    { ...extraOptions, enabled: candidateId !== undefined }
  );
};

export const useUpdateAbsences = () => {
  const queryClient = useQueryClient();
  const { brandCode } = usePerimeter();
  const mutation = useMutation(
    [MutationKeys.ABSENCE_UPDATE],
    absenceMutationsConfigs(queryClient)['absences/update']
  );

  return {
    update: async (data: Local<Absence>) => {
      const previousData = await db.absences.where({ localId: data.localId }).first();
      await db.absences.where({ localId: data.localId }).modify(data);

      const absence = await db.absences.where({ localId: data.localId }).first();

      if (absence === undefined) {
        throw new Error(`Absence with localId ${data.localId} does not exist in local DB`);
      }

      const mutationCache = queryClient.getMutationCache().getAll();

      if (
        mutationCache.findIndex(
          mutation =>
            (mutation.options?.mutationKey?.includes(MutationKeys.ABSENCE_UPDATE) ||
              mutation.options?.mutationKey?.includes(MutationKeys.ABSENCE_CREATION)) &&
            mutation.options?.variables?.['localId'] === data?.localId &&
            mutation.state.status === 'loading'
        ) !== -1
      ) {
        return queryStatus.success;
      }

      mutation.mutate({
        previousData,
        localId: absence.localId ?? 0,
        brandCode,
      });
    },
    mutation,
  };
};
