import { useMutation, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CompaniesService } from 'src/Services/API';
import { QueryKeys } from '..';

export const useFetchCompanies = () => {
  return useMutation(
    ['fetchCompanies'],
    async (body: { keyword: string; agencies: string[]; brandCode: string }) => {
      let companies = [];
      if (body.keyword.length > 0)
        companies = await CompaniesService.companiesControllerGetCompanies({
          body,
        });
      else
        companies = await CompaniesService.companiesControllerGetCompaniesFromWalletAgenciesLight({
          body: { ...body, offset: 0, limit: 10 },
        });
      return companies;
    }
  );
};

export const useFetchDefaultCompanyForPerimeter = () => {
  return useMutation(
    ['fetchDefaultCompanyForPerimeter'],
    async ({ agencyId, brandCode }: { agencyId: string; brandCode: string }) => {
      const defaultCompaniesForPerimeter =
        await CompaniesService.companiesControllerGetCompaniesFromWalletAgenciesLight({
          body: { agencies: [agencyId], brandCode, offset: 0, limit: 1 },
        });
      return defaultCompaniesForPerimeter[0];
    }
  );
};

export const useFetchCompanyServices = () => {
  const currentBrand = useSelector(getCurrentBrand);
  const currentCompany = useSelector(getCurrentCompany);
  return useQuery([QueryKeys.FETCH_COMPANY_SERVICES, currentCompany, currentBrand], async () => {
    if (!currentBrand || !currentCompany) {
      return Promise.reject('No current brand or has an invalid current brand');
    }
    return await CompaniesService.companiesControllerGetServices({
      id: currentCompany.companyId ?? '',
      brandCode: currentBrand.brandCodeApiHeader ?? '',
    });
  });
};
